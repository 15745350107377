import * as React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';
import { ExpiredSubscriptionModal } from '../../components/ExpiredSubscriptionModal/ExpiredSubscriptionModal';
import TrackingProvider from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { useGetCustomerAddresses } from '../../queries/customer';
import {
  useMerchantCache,
  useMerchantNextSchedules,
  useMerchantSchedules,
} from '../../queries/home';
import { useRefreshLoyaltyData } from '../../queries/loyalty';
import { useBannerStore } from '../../stores/banner';
import { useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import { useUserStore } from '../../stores/user';
import { colors } from '../../utils/constants';
import { notifyLoyalty } from '../LoyaltyClub';

const Entrypoint: React.FC = () => {
  const params = useSlug();
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);
  const setAddresses = useUserStore((state) => state.setAddresses);
  const setAddress = useUserStore((state) => state.setAddress);

  const { clearCart, merchantId } = useCartStore((state) => ({
    clearCart: state.clearCart,
    merchantId: state.merchantId,
  }));
  const clearMerchantIdComplements = useComplementsStore(
    (state) => state.clearMerchantIdComplements,
  );

  const setMerchant = useMerchantStore((state) => state.setMerchant);
  const setSchedules = useMerchantStore((state) => state.setSchedules);
  const setNextSchedules = useMerchantStore((state) => state.setNextSchedules);
  const setCategoriesAndProducts = useProductsStore(
    (state) => state.setCategoriesAndProducts,
  );
  const setHighlights = useProductsStore((state) => state.setHighlights);
  const setStoreLogo = useProductsStore((state) => state.setStoreLogo);
  const setImages = useBannerStore((state) => state.setImages);

  const { data: merchantData, error: merchantError } = useMerchantCache(
    params.slug,
  );
  const { data: merchantSchedules } = useMerchantSchedules(
    merchantData?.content.merchant.id,
  );
  const { data: merchantNextSchedules } = useMerchantNextSchedules(
    merchantData?.content.merchant.id,
  );
  const { mutateAsync: refreshLoyaltyData } = useRefreshLoyaltyData();
  const { mutateAsync: getCustomerAddresses } = useGetCustomerAddresses();

  const clearCache = () => {
    const keys = ['products', 'merchant', 'banner', 'complements'];
    clearCart();
    clearMerchantIdComplements();
    keys.forEach((key) => sessionStorage.removeItem(key));
  };

  if (merchantError) {
    clearCache();
    navigate('/not-found');
  }

  React.useEffect(() => {
    const body = document.documentElement;
    const titleMerchant = document.getElementById('title-merchant');
    const iconMerchant = document.getElementById('icon-merchant');

    if (merchantData && merchantData.content.merchant.id !== merchantId) {
      clearCache();
    }

    if (body && merchantData) {
      const color =
        colors[merchantData?.content?.merchant?.color] || 'theme-red';
      body.classList.add(color);

      if (titleMerchant) {
        titleMerchant.innerHTML = merchantData?.content?.merchant?.name;
      }
      if (iconMerchant) {
        iconMerchant.setAttribute(
          'href',
          merchantData?.content?.merchant?.logo,
        );
      }

      const merchantAdsOptions = merchantData?.content.merchant?.options_global;
      merchantAdsOptions?.forEach((adsOption) => {
        try {
          switch (adsOption.key) {
            case 'GTM_TAG':
              TagManager.initialize({ gtmId: adsOption.value });
              break;
            case 'ANALYTICS_TAG':
              ReactGA.initialize(adsOption.value);
              break;
            case 'FB_PIXEL_TAG':
              ReactPixel.init(adsOption.value);
              ReactPixel.pageView();
              break;
          }
        } catch (error) {
          console.error('Error initializing AdsOption:', adsOption.key, error);
        }
      });
    }
  }, [merchantData]);

  React.useEffect(() => {
    const handleRefreshLoyalty = async () => {
      const data = await refreshLoyaltyData();
      if (
        data &&
        merchantData?.content.loyalty_programme?.status === 'ACTIVE'
      ) {
        updateLoyalty(data);
        notifyLoyalty(data?.[0]);
      }
    };

    if (user?.loyalty_programmes && user.loyalty_programmes.length > 0) {
      handleRefreshLoyalty();
    }
  }, []);

  React.useEffect(() => {
    if (user) {
      getCustomerAddresses().then((res) => {
        setAddresses(res);
        const defaultAddress = res.find((addr) => addr.default);
        if (defaultAddress) setAddress(defaultAddress);
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (merchantData?.content) {
      setMerchant(merchantData.content);
      setStoreLogo(merchantData.content.merchant.logo);
      setCategoriesAndProducts(merchantData.content.categories);
      setHighlights(merchantData.content.highlights);
      setImages(merchantData.content.advertisements);
    }
  }, [merchantData]);

  React.useEffect(() => {
    if (merchantSchedules) setSchedules(merchantSchedules);
    if (merchantNextSchedules) setNextSchedules(merchantNextSchedules);
  }, [merchantSchedules, merchantNextSchedules]);

  return (
    <TrackingProvider>
      <Helmet>
        <meta
          name="description"
          content={`${merchantData?.content.merchant.name}, ${merchantData?.content.merchant.description}`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content={merchantData?.content.merchant.name}
        />
        <meta
          property="og:description"
          content={merchantData?.content.merchant.description}
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={merchantData?.content.merchant.logo}
        />
      </Helmet>

      <Outlet />

      <ExpiredSubscriptionModal
        merchantPhone={merchantData?.content.merchant.phone}
        expirationDate={merchantData?.content.merchant.subscription.expired_at}
      />
    </TrackingProvider>
  );
};

export default Entrypoint;
