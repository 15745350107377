import {
  CustomerForm,
  ICustomerForm,
} from '../../../components/Forms/CustomerForm';
import { Skeleton } from '@onbeef/components/skeleton';
import {
  Gender,
  LoadingIcon,
  Toast,
  formatBirthday,
  maskCPF,
  maskPhone,
  parseBirthday,
} from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderBar from '../../../components/Headers/HeaderBar';
import { useSlug } from '../../../hooks/useSlug';
import { useUpdateCustomer } from '../../../queries/customer';
import { UpdateCustomerPayload } from '../../../queries/customer/types';
import { useMerchantCache } from '../../../queries/home';
import { useAuthStore } from '../../../stores/login';
import { useMerchantStore } from '../../../stores/merchant';

type ProfileInfoForm = ICustomerForm & { code: string };

export const ProfileInfo: React.FC = () => {
  const params = useSlug();
  const { isLoading: isMerchantLoading } = useMerchantCache(params.slug);

  const location = useLocation();
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const updateUser = useAuthStore((state) => state.updateUser);
  const user = useAuthStore((state) => state.user);

  const methods = useForm<ProfileInfoForm>();

  const { mutateAsync: updateCustomer, isLoading } = useUpdateCustomer();

  const onSubmit = async (data: ProfileInfoForm) => {
    try {
      if (!merchant) return;

      const payload: UpdateCustomerPayload = {
        merchant_id: merchant.id,
      };

      type CommonKey = keyof Omit<
        UpdateCustomerPayload,
        'address' | 'merchant_id'
      >;

      const getOnlyNumbers = (value?: string) =>
        value?.replace(/\D/g, '') || '';

      const checkKey = (key: CommonKey) => {
        if (key === 'taxpayer_id_number' && user?.[key]) {
          return false;
        }
        if (key === 'phone') {
          return getOnlyNumbers(user?.[key]) !== getOnlyNumbers(data[key]);
        }
        return (
          user?.hasOwnProperty(key) && data[key] && data[key] !== user[key]
        );
      };

      Object.keys(data).forEach((key) => {
        const k = key as CommonKey;

        if (checkKey(k)) {
          if (k === 'birthday') {
            payload[k] = formatBirthday(data[k] as string);
            return;
          }
          payload[k] = data[k] as string;
        }
      });

      const res = await updateCustomer(payload);
      res.message && Toast.success(res.message);
      updateUser(res.data.customer);
      if (res.data.check_phone) {
        navigate('/validate/phone', {
          state: {
            go_back: location.state.goBack || '/profile/info',
            token: res.data.token,
            sms_sent: true,
          },
        });
      }
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  React.useEffect(() => {
    if (user) {
      methods.setValue('name', user.name);
      methods.setValue('gender', user.gender as Gender);
      methods.setValue('birthday', parseBirthday(user.birthday));
      methods.setValue('email', user.email);
      methods.setValue(
        'taxpayer_id_number',
        maskCPF(user.taxpayer_id_number || ''),
      );
      methods.setValue('phone', maskPhone(user.phone));
    }
  }, [user]);

  const emailDisabled = user?.email != null && user?.email !== '';
  const cpfDisabled =
    user?.taxpayer_id_number != null && user?.taxpayer_id_number !== '';

  return (
    <div className="w-full h-full p-4 space-y-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Meus dados
        </span>
      </HeaderBar>

      <FormProvider {...methods}>
        <form
          className="flex flex-col p-2 gap-4"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Skeleton
            isLoading={isMerchantLoading}
            repeatQuantity={8}
            className="w-full h-10"
          >
            <span className="text-sm font-medium">
              Esses dados estão vinculados a sua conta. Altere ou adicione mais
              informações.
            </span>
            <CustomerForm
              withPassword={false}
              emailDisabled={emailDisabled}
              cpfDisabled={cpfDisabled}
            />
            <button
              type="submit"
              className="rounded-lg bg-primary text-contrastText px-5 py-2 w-full h-10 text-center self-center flex justify-center"
            >
              {isLoading ? <LoadingIcon /> : 'Salvar'}
            </button>
          </Skeleton>
        </form>
      </FormProvider>
    </div>
  );
};
