import React from 'react';
import { useNavigate } from 'react-router-dom';
import Coupons from '../../assets/images/coupons.png';
import Diamond from '../../assets/images/diamond.png';
import { useSlug } from '../../hooks/useSlug';
import { LoyaltyCTA } from '../../pages/LoyaltyClub';
import { useMerchantCache } from '../../queries/home';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Skeleton from '../Skeleton/Skeleton';

const Benefits: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const handleOpenClub = () => {
    if (user && user.clubs && user.clubs.length > 0) {
      navigate(`/club/`);
    } else {
      navigate('/club/preview');
    }
  };

  const search = useProductsStore((state) => state.search);

  const { clubs, coupons, loyalty_programme } = useMerchantStore((state) => ({
    clubs: state.clubs,
    coupons: state.coupons,
    loyalty_programme: state.loyalty_programme,
  }));

  const addCoupon = () => {
    navigate(`/coupon`);
  };
  const isLoyaltyEnabled = loyalty_programme != null;

  if (!coupons?.hasActive && (!clubs || clubs.length <= 0) && !isLoyaltyEnabled)
    return <></>;

  if (search) return null;

  return (
    <div className="px-2 pb-5 relative">
      <div className="space-x-1 flex w-full font-semibold mb-2">
        <Skeleton
          width={'150px'}
          height={'20px'}
          borderRadius="10px"
          isLoading={isLoading}
        >
          <div className="prose">Meus benefícios</div>
        </Skeleton>
      </div>
      <div className="flex flex-row w-full space-x-2">
        {clubs && clubs.length > 0 && (
          <Skeleton isLoading={isLoading} className="w-full h-32 rounded-lg ">
            <button
              type="button"
              disabled={isLoading}
              onClick={handleOpenClub}
              className="border-2 flex w-full flex-col  border-gray-300 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <div className="w-full flex items-center justify-center p-3">
                <img
                  src={Diamond}
                  className="w-[50px] self-center"
                  alt="Estrelas"
                />
              </div>
              <div className="text-center w-full text-[10px] p-1 font-semibold text-gray-500 border-t-2 border-gray-300">
                Participe do nosso clube
              </div>
            </button>
          </Skeleton>
        )}
        <LoyaltyCTA />
        <Skeleton isLoading={isLoading} className="w-full h-32 rounded-lg ">
          {coupons?.hasActive && (
            <button
              type="button"
              disabled={isLoading}
              onClick={addCoupon}
              className="border-2 flex w-full flex-col  border-gray-300 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <div className="w-full flex items-center justify-center p-3">
                <img
                  src={Coupons}
                  className="w-[50px] self-center"
                  alt="Estrelas"
                />
              </div>
              <div className="text-center w-full text-[10px] p-1 font-semibold text-gray-500 border-t-2 border-gray-300">
                Cupons para usar na compra
              </div>
            </button>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default Benefits;
