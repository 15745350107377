import {
  Product,
  ProductType,
  calculateSchedulingDiscount,
} from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { GetLinkOrderDetailsResponse } from '../queries/checkout-link/types';
import { ValidatedCoupon } from '../queries/coupon/types';
import {
  CutPayload,
  OrderSchedule,
  SchedulingTime,
} from '../queries/order/types';
import { getCutsQuantity } from '../utils/CutsUtils';
import {
  calculatedValue,
  getClub,
  hasClubDiscount,
} from '../utils/ProductUtils';
import { useComplementsStore } from './complements';
import { useMerchantStore } from './merchant';
import { useKitsStore } from './kits';
import { CRMBonusDetailResponse } from '../queries/crm/types';

export type ProductCartType = Product & {
  quantity: number;
  hasComplements?: boolean;
  totalAmount?: number; // totalAmount é para visualização no carrinho
  calculatedValue?: number;
  comments?: string;
  package_quantity?: number | null;
  hasClubDiscount: boolean;
  selectedCuts: CutPayload[];
};

export type SetDeliveryTime = (
  date?: string,
  time?: SchedulingTime,
  frete?: number,
) => void;

export interface CartStore {
  productsCountIndividual: number;
  merchantId: string;
  products: Record<string, ProductCartType>;
  coupon?: ValidatedCoupon;
  deliveryTime?: OrderSchedule;
  isExtension: boolean;
  isEditOrder: boolean;
  customDeliveryFeeError: boolean;
  isCheckoutLink: boolean;
  linkOrderDetails?: GetLinkOrderDetailsResponse;
  crmBonusDiscount: number;
  crmBonusData?: CRMBonusDetailResponse;
  setCrmBonusData: (data?: CRMBonusDetailResponse) => void;
  setCrmBonusDiscount: (discount: number) => void;
  setDeliveryTime: SetDeliveryTime;
  setCoupon: (coupon?: ValidatedCoupon) => void;
  setProduct: (product: ProductCartType) => void;
  setCart: (products: Record<string, ProductCartType>) => void;
  removeProduct: (productId: string) => void;
  deleteProduct: (productId: string) => void;
  increaseQuantity: (productId: string) => void;
  clearCart: () => void;
  addCart: (
    product: Product,
    quantity: number,
    comments: string,
    package_quantity?: number | null,
  ) => void;
  updateCommentsCart: (productId: string, comments: string) => void;
  addCut: (productId: string, cut: CutPayload) => void;
  removeCut: (productId: string, cutID: string) => boolean; // true if the product is removed
  refreshClubPricing: () => void;
  setIsExtension: (isExtension: boolean) => void;
  setIsEditOrder: (isEditOrder: boolean) => void;
  setCustomDeliveryFeeError: (customDeliveryFeeError: boolean) => void;
  setIsCheckoutLink: (isCheckoutLink: boolean) => void;
  setLinkOrderDetails: (
    linkOrderDetails: GetLinkOrderDetailsResponse | undefined,
  ) => void;
}

export const useCartStore = create<CartStore>()(
  persist(
    (set, get) => ({
      products: {},
      productsCountIndividual: 0,
      merchantId: '',
      coupon: undefined,
      deliveryTime: undefined,
      isExtension: false,
      isEditOrder: false,
      customDeliveryFeeError: false,
      isCheckoutLink: false,
      linkOrderDetails: undefined,
      crmBonusDiscount: 0,
      crmBonusData: undefined,
      setCrmBonusData(data) {
        set({ crmBonusData: data });
      },
      setCrmBonusDiscount(discount) {
        set({ crmBonusDiscount: discount });
      },
      setLinkOrderDetails(linkOrderDetails) {
        set({ linkOrderDetails });
      },
      setIsCheckoutLink(isCheckoutLink) {
        set({ isCheckoutLink });
      },
      setIsExtension(isExtension) {
        set({ isExtension });
      },
      setIsEditOrder(isEditOrder) {
        set({ isEditOrder });
      },
      setCustomDeliveryFeeError(customDeliveryFeeError) {
        set({ customDeliveryFeeError });
      },
      setDeliveryTime: (date, time, frete) => {
        if (!date || !time) {
          set(() => ({ deliveryTime: undefined }));
          return;
        }

        const discountCalc =
          time.discount && frete != null
            ? calculateSchedulingDiscount(time.discount, frete)
            : 0;

        const deliveryTime = {
          date: date,
          time: time,
          discount_amount: discountCalc,
          time_interval: {
            start: time.start,
            end: time.end,
          },
        };

        set(() => ({ deliveryTime }));
      },
      setCoupon(coupon) {
        set(() => ({ coupon }));
      },
      refreshClubPricing() {
        const products = { ...get().products };

        Object.keys(products).forEach((productID) => {
          const product = products[productID]!;
          const club = getClub(product);
          products[productID] = {
            ...product,
            hasClubDiscount: hasClubDiscount(product),
            clubPrice: club?.price,
            clubName: club?.name,
            clubSubscriptionID: club?.subs_id,
            calculatedValue: calculatedValue(
              product,
              product.quantity,
              product.type,
            ),
          };
        });

        get().setCart(products);
      },
      addCart(product, quantity, comments, package_quantity) {
        const cartProduct = get().products[product.id];
        const type = product.product_type;
        const complementsCut =
          typeof product.complementsCut == 'string'
            ? JSON.parse(product.complementsCut)
            : product.complementsCut;

        const oldCut = complementsCut?.length > 0 ? true : false;
        const hasCut = oldCut || product.cuts?.length > 0;

        let total = 0;

        if (type === ProductType.UNIT || type === ProductType.KIT) {
          total = quantity;
        } else if (hasCut && type === ProductType.KG && product.add_grams) {
          if (oldCut) {
            total =
              product.add_grams *
              useComplementsStore
                .getState()
                .getCountComplementsCuts(product.id);
          } else {
            const cur = cartProduct
              ? getCutsQuantity(cartProduct.selectedCuts)
              : 0;
            total = cur + quantity;
          }
        } else if (
          !hasCut &&
          type === ProductType.KG &&
          product.estimated_weight
        ) {
          total = product.estimated_weight * quantity;
        }

        const selectedCuts = cartProduct?.selectedCuts || [];

        const finalProduct = {
          ...product,
          type: type,
          quantity:
            type === ProductType.KG && !oldCut
              ? quantity === 0
                ? 1
                : quantity
              : hasCut
                ? total
                : quantity,
          calculatedValue: calculatedValue(product, quantity, type),
          hasClubDiscount: hasClubDiscount(product),
          comments: comments,
          package_quantity: package_quantity,
          totalAmount: parseFloat(total.toFixed(2)),
          selectedCuts,
        };

        console.log('cart.ts store finalProduct', finalProduct);

        set((state) => {
          const products = { ...state.products };
          products[product.id] = finalProduct;
          const merchantId = useMerchantStore.getState().merchant?.id;

          return {
            products,
            productsCountIndividual: Object.keys(products).reduce(
              (acc, key) => {
                const product = products[key];
                if (product) {
                  if (product.type === ProductType.KG) {
                    return acc + 1;
                  } else {
                    return acc + product.quantity;
                  }
                }
                return acc;
              },
              0,
            ),
            merchantId: merchantId,
          };
        });
      },
      setCart(products) {
        set(() => {
          const merchantId = useMerchantStore.getState().merchant?.id;

          return {
            products,
            productsCountIndividual: Object.keys(products).reduce(
              (acc, key) => {
                const product = products[key];
                if (product?.type === ProductType.KG) {
                  return acc + 1;
                } else {
                  return acc + (product?.quantity ?? 0);
                }
              },
              0,
            ),
            merchantId: merchantId,
          };
        });
      },
      clearCart: () => {
        set({
          products: {},
          productsCountIndividual: 0,
          merchantId: '',
          coupon: undefined,
          deliveryTime: undefined,
        });
      },
      increaseQuantity: (productId) => {
        set((state) => {
          const products = { ...state.products };
          const product = products[productId];
          const merchantId = useMerchantStore.getState().merchant?.id;

          if (product) {
            const quantity = product?.quantity ? product?.quantity + 1 : 1;
            products[productId] = {
              ...product,
              quantity: quantity,
              calculatedValue: calculatedValue(product, quantity, product.type),
              hasClubDiscount: hasClubDiscount(product),
              totalAmount:
                product?.type === ProductType.KG && product.estimated_weight
                  ? quantity * product.estimated_weight
                  : quantity,
            };

            const productsCountIndividual =
              product.type === ProductType.KG
                ? state.productsCountIndividual
                : state.productsCountIndividual + 1;

            return {
              products,
              productsCountIndividual: productsCountIndividual,
              merchantId: merchantId,
            };
          }

          return state;
        });
      },
      setProduct: (product) => {
        set((state) => {
          const merchantId = useMerchantStore.getState().merchant?.id;

          return {
            productsCountIndividual:
              Object.keys(state.products).reduce((acc, key) => {
                const product = state.products[key];
                if (product?.type === ProductType.KG) {
                  return acc + 1;
                } else {
                  return acc + (product?.quantity ?? 0);
                }
              }, 0) + 1,
            products: {
              ...state.products,
              [product.id]: {
                ...product,
                quantity: 1,
              },
            },
            merchantId: merchantId,
          };
        });
      },
      removeProduct: (productId) => {
        set((state) => {
          const products = { ...state.products };
          const product = products[productId];
          const merchantId = useMerchantStore.getState().merchant?.id;

          if (
            (product?.type === ProductType.KG &&
              product.complements.length === 0 &&
              product.cuts?.length === 0) ||
            product?.type === ProductType.UNIT ||
            product?.type === ProductType.KIT
          ) {
            if (product.quantity > 1) {
              products[productId] = {
                ...product,
                quantity: product.quantity - 1,
                calculatedValue: calculatedValue(
                  product,
                  product.quantity - 1,
                  product.type,
                ),
                hasClubDiscount: hasClubDiscount(product),
                totalAmount:
                  product?.type === ProductType.KG && product.estimated_weight
                    ? (product.quantity - 1) * product.estimated_weight
                    : product.quantity - 1,
              };
            } else {
              if (product.type === ProductType.KIT && product.kit_type != null)
                useKitsStore.getState().deleteKitProduct(productId);
              useComplementsStore.getState().deleteComplements(productId);
              delete products[productId];
            }
            return {
              products,
              productsCountIndividual: Object.keys(products).reduce(
                (acc, key) => {
                  const product = products[key];
                  if (product) {
                    if (product.type === ProductType.KG) {
                      return acc + 1;
                    } else {
                      return acc + product.quantity;
                    }
                  }
                  return acc; // Add this line to handle the case when product is undefined
                },
                0,
              ),
              merchantId: merchantId,
            };
          } else {
            delete products[productId];
          }
          return {
            products,
            productsCountIndividual: Object.keys(products).reduce(
              (acc, key) => {
                const product = products[key];
                if (product?.type === ProductType.KG) {
                  return acc + 1;
                } else {
                  return acc + (product?.quantity ?? 0);
                }
              },
              0,
            ),
            merchantId: merchantId,
          };
        });
      },
      deleteProduct: (productId) => {
        set((state) => {
          const products = { ...state.products };
          useComplementsStore.getState().deleteComplements(productId);
          delete products[productId];
          const merchantId = useMerchantStore.getState().merchant?.id;

          return {
            products,
            productsCountIndividual: Object.keys(products).reduce(
              (acc, key) => {
                const product = products[key];
                if (product?.type === ProductType.KG) {
                  return acc + 1;
                } else {
                  return acc + (product?.quantity ?? 0);
                }
              },
              0,
            ),
            merchantId: merchantId,
          };
        });
      },
      addCut(productId, cut) {
        const cartProduct = get().products[productId];
        if (!cartProduct) return;
        let newSelectedCuts: CutPayload[] = [];
        const exist = cartProduct.selectedCuts.find((c) => c.id === cut.id);
        if (exist) {
          newSelectedCuts = cartProduct.selectedCuts.map((c) => {
            if (c.id === cut.id) return cut;
            return c;
          });
        } else {
          newSelectedCuts = [...cartProduct.selectedCuts, cut];
        }
        const total = getCutsQuantity(newSelectedCuts);
        const updated = {
          ...cartProduct,
          selectedCuts: newSelectedCuts,
          totalAmount: parseFloat(total.toFixed(2)),
        };
        set((state) => {
          const products = state.products;
          products[productId] = updated;
          return { products };
        });
      },
      removeCut(productId, cutID) {
        const cartProduct = get().products[productId];
        if (!cartProduct) return true;
        const newSelectedCuts = cartProduct.selectedCuts.filter(
          (c) => c.id !== cutID,
        );
        if (newSelectedCuts.length === 0) {
          get().deleteProduct(productId);
          return true;
        }
        const total = getCutsQuantity(newSelectedCuts);
        const updated = {
          ...cartProduct,
          selectedCuts: newSelectedCuts,
          totalAmount: parseFloat(total.toFixed(2)),
        };
        set((state) => {
          const products = state.products;
          products[productId] = updated;
          return { products };
        });
        // update the product price without removed cut
        get().addCart(
          cartProduct,
          0,
          cartProduct.comments || '',
          cartProduct.package_quantity,
        );
        return false;
      },
      updateCommentsCart: (productId, comments) => {
        set((state) => {
          const products = { ...state.products };
          const product = products[productId];
          if (product) {
            products[productId] = {
              ...product,
              comments: comments,
            };
          }
          return {
            products,
          };
        });
      },
    }),
    {
      name: 'cart',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !['isCheckoutLink', 'crmBonusDiscount', 'crmBonusData'].includes(
                key,
              ),
          ),
        ),
      version: 2,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
