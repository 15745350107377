import { Skeleton } from '@onbeef/components/skeleton';
import { FilterOption, FiltersKeys, FiltersType } from '@onbeefapp/constants';
import cx from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';

interface CardFilterCardUIProps {
  filter: FilterOption;
  handleSelectFilter?: (filter: FilterOption) => void;
  selected?: boolean;
}

export const FilterCardUI: React.FC<CardFilterCardUIProps> = ({
  filter,
  handleSelectFilter,
  selected = false,
}) => {
  return (
    <button
      type="button"
      onClick={() => handleSelectFilter && handleSelectFilter(filter)}
      className={cx(
        'flex shrink-0 flex-col items-center w-[120px] cursor-pointer overflow-hidden rounded-xl transition-all duration-300 border',
        selected ? 'border-green-400' : 'border-gray-100',
      )}
    >
      <div className="w-full bg-primary relative overflow-hidden">
        <div className="w-full relative flex items-center justify-center p-2">
          <img
            className="w-20 h-20 object-contain brightness-0 invert"
            loading="lazy"
            src={filter.image}
            alt={filter.name}
          />
        </div>
      </div>
      <div className="w-full bg-white py-2 px-1 text-center">
        <h3 className="text-gray-800 text-sm font-medium">{filter.name}</h3>
      </div>
    </button>
  );
};

export const FilterCards: React.FC = () => {
  const params = useSlug();
  const navigate = useNavigate();

  const { isLoading } = useMerchantCache(params.slug);
  const { merchantFilters, filter_preferences } = useMerchantStore((state) => ({
    merchantFilters: state.filters,
    filter_preferences: state.filter_preferences,
  }));

  const { setSelectedFilter, selectedFilters, filterProducts, filtersLoading } =
    useProductsStore((state) => ({
      setSelectedFilter: state.setSelectedFilter,
      selectedFilters: state.filters,
      filterProducts: state.filterProducts,
      filtersLoading: state.filtersLoading,
    }));

  const handleSelectFilter = (filter: FilterOption) => {
    if (!filter.type) filter.type = FiltersKeys.CUTS;
    setSelectedFilter(filter, {
      type:
        filter.type === FiltersKeys.CUTS ? FiltersType.CUTS : FiltersType.TAGS,
      key: filter.type,
    });
    filterProducts();
    navigate(`/search`, { state: { fromHome: true } });
  };

  const filters =
    filter_preferences && filter_preferences.length > 0
      ? filter_preferences.map((f) => f.filter).filter((f) => f != null)
      : merchantFilters?.CUTS?.content?.slice(0, 7);

  return (
    <div
      className={cx(
        'px-2 pb-5',
        isLoading || filtersLoading ? '' : '',
        !filters || filters?.length === 0 ? 'hidden' : '',
      )}
    >
      <Skeleton
        width={'150px'}
        height={'20px'}
        borderRadius="10px"
        isLoading={isLoading}
      >
        <h2 className="font-semibold mb-2">Filtrar produtos</h2>
      </Skeleton>

      <div className="space-x-3 flex w-full mx:auto overflow-x-scroll overflow-y-hidden pt-2 snap-x snap-mandatory">
        <Skeleton
          isLoading={isLoading}
          horizontal
          className="w-[120px] h-32 rounded-xl"
          repeatQuantity={6}
        >
          {filters?.map((filter) => {
            const selected = !!selectedFilters.find((f) => f.id === filter.id);
            return (
              <FilterCardUI
                key={filter.id}
                filter={filter}
                handleSelectFilter={handleSelectFilter}
                selected={selected}
              />
            );
          })}
          <button
            onClick={() => navigate('/search')}
            className="flex shrink-0 flex-col items-start justify-center w-20 h-full p-4 py-7 cursor-pointer overflow-hidden rounded-xl transition-all duration-300 border-2 border-gray-200 bg-white gap-2 hover:bg-gray-50"
          >
            <div className="flex items-center text-xs font-normal whitespace-nowrap">
              Ver mais <br /> filtros
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-10 h-10 text-center self-center text-primary"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </Skeleton>
      </div>
    </div>
  );
};
