import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useDrawerStore } from '../../stores/drawer';
import { CRMProgressBar } from '../CRMProgressBar';
import DrawerFilters from '../Drawers/DrawerFilters';
import FilterCategory from '../Filters/FilterCategory';
import { FilterSelector } from '../Filters/FilterSelector';
import HideMobile from '../HideMobile/HideMobile';
import Skeleton from '../Skeleton/Skeleton';
import ProductSearchInput from './ProductSearchInput';
import SearchInput from './SearchInput';

interface SearchBarProps {
  cart?: boolean;
  button?: boolean;
  nameSelected?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  cart = false,
  button = false,
  nameSelected,
}) => {
  const params = useSlug();
  const navigate = useNavigate();

  const { isLoading } = useMerchantCache(params.slug);

  const [isCart, setIsCart] = React.useState(false);

  const handleOpenSearchPage = () => {
    navigate('/search', { state: { autoFocus: true, fromHome: true } });
  };

  useEffect(() => {
    const stickyContainer = document.getElementById('stickyContainer');

    if (stickyContainer) {
      const initialOffsetTop = stickyContainer.offsetTop;

      window.addEventListener('scroll', () => {
        if (window.scrollY >= initialOffsetTop) {
          setIsCart(true);
          stickyContainer.classList.add('fixed');
        } else {
          setIsCart(false);
          stickyContainer.classList.remove('fixed');
        }
      });
    }
  }, []);

  const { isOpen, key: id_drawer } = useDrawerStore((state) => ({
    isOpen: state.isOpen,
    key: state.key,
  }));

  return (
    <Skeleton
      isLoading={isLoading}
      className={`w-full rounded-lg ${button ? 'h-10' : 'h-20'}`}
    >
      {button ? (
        <button
          onClick={handleOpenSearchPage}
          type="button"
          className="w-full sticky p-2 top-0 z-40 pt-1 bg-[#f5f5f5] pb-px"
        >
          <div className="relative flex items-center mt-1 mb-2 pointer-events-none">
            <SearchInput placeholder="Buscar categoria, produto ou marca" />
          </div>
        </button>
      ) : (
        <div
          className="sticky p-2 top-0 z-40 pt-1 bg-[#f5f5f5] pb-px"
          id="stickyContainer"
        >
          <Skeleton
            width="100%"
            height="60px"
            borderRadius="5px"
            isLoading={isLoading}
          >
            <CRMProgressBar />
            <ProductSearchInput cart={cart ? cart : isCart} />
            <DrawerFilters
              isOpen={isOpen}
              key={'drawerFilters'}
              id={id_drawer ?? ''}
            />
            <HideMobile>
              <FilterCategory nameSelected={nameSelected} />
            </HideMobile>
            <FilterSelector />
          </Skeleton>
        </div>
      )}
    </Skeleton>
  );
};

export default SearchBar;
