import React from 'react';
import HeaderBar from '../../components/Headers/HeaderBar';
import {
  CreditCardForm,
  creditCardValidation,
  ICreditCardForm,
} from '@onbeef/components/creditCardForm';
import {
  BillingAddress,
  LoadingIcon,
  mergeSchema,
  PaymentMethodType,
  Toast,
} from '@onbeefapp/constants';
import { useOrderDetails } from '../../stores/order-details';
import { useUserStore } from '../../stores/user';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useCreateCard } from '../../queries/payment-card';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/login';
import { CardPayload } from '../../queries/payment-card/types';
import { generateDeviceToken } from '../../utils/device';
import { TrackingContext } from '../../contexts/TrackingContext';

type NewCreditCardForm = ICreditCardForm;

const validationSchema = mergeSchema(creditCardValidation);

export const NewCreditCard: React.FC = () => {
  const navigate = useNavigate();

  const methods = useForm<NewCreditCardForm>({
    resolver: yupResolver(validationSchema),
  });

  const { track } = React.useContext(TrackingContext);

  const [brand, setBrand] = React.useState('');

  const { mutateAsync: createCard, isLoading } = useCreateCard();

  const { isAuthenticated } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
  }));

  const {
    address,
    name,
    email,
    taxpayer_id_number,
    phone,
    addCard,
    newLocalCard,
  } = useUserStore((state) => ({
    address: state.address,
    name: state.name,
    taxpayer_id_number: state.taxpayer_id_number,
    email: state.email,
    phone: state.phone,
    addCard: state.addCard,
    newLocalCard: state.newLocalCard,
  }));

  const { selectedPaymentMethod, setSelectedCardID } = useOrderDetails(
    (state) => ({
      selectedPaymentMethod: state.selectedPaymentMethod,
      setSelectedCardID: state.setSelectedCardID,
    }),
  );

  const onSubmit = async (data: NewCreditCardForm) => {
    let billing_address = {} as BillingAddress;
    if (data.billing_address.without && address) {
      billing_address = {
        address: address?.street_name,
        complement: address?.street_complement || '',
        zip_code: address?.zip,
        city: address?.city,
        state: address?.state,
        country: 'BR',
      };
    } else {
      billing_address = {
        address: data.billing_address.street_name,
        complement: data.billing_address.street_complement || '',
        zip_code: data.billing_address.zip,
        city: data.billing_address.city,
        state: data.billing_address.state,
        country: 'BR',
      };
    }

    const cardPayload: CardPayload = {
      token: generateDeviceToken(),
      payment_method: 'CREDIT_CARD',
      number: data.card.number,
      holder_name: data.card.name.toUpperCase(),
      exp_month: data.card.expiration.split('/')[0]!,
      exp_year: data.card.expiration.split('/')[1]!,
      cvv: data.card.cvv,
      billing_address: billing_address,
      holder_document: data.card.taxpayer_id_number!,
      ...(email && { email }),
      ...(phone && { phone }),
      ...(name && { name }),
      ...(taxpayer_id_number && { taxpayer_id_number }),
    };

    track('OnCustomerAtCheckoutCreateCreditCard', {});
    if (isAuthenticated) {
      handleCreateCard(cardPayload);
    } else {
      handleSaveLocalCard(cardPayload);
    }
  };

  const handleCreateCard = async (payload: CardPayload) => {
    try {
      const res = await createCard(payload);
      if (res) {
        res.message && Toast.success(res.message);
        addCard(res.card);
        setSelectedCardID(res.card.id);
        navigate(-1);
      }
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const handleSaveLocalCard = (payload: CardPayload) => {
    const id = newLocalCard(payload, brand);
    setSelectedCardID(id);
    navigate(-1);
  };

  return (
    <div className="w-full p-2">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col p-2 gap-4"
        >
          <HeaderBar forceDisplayGoBack>
            <div className="w-full text-center">Cartão de crédito</div>
          </HeaderBar>

          <CreditCardForm
            withoutEmail
            withoutInfo={false}
            address={address}
            active={
              selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD
            }
            cardBrandCallback={(b) => setBrand(b)}
          />

          <button
            className="w-full rounded-lg p-2 bg-primary text-contrastText flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? <LoadingIcon /> : 'Confirmar'}
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
