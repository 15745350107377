import { TrackingContext } from '../../contexts/TrackingContext';
import React from 'react';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useProductsStore } from '../../stores/products';
import Skeleton from '../Skeleton/Skeleton';
import SearchInput from './SearchInput';
import { debounce } from '@onbeefapp/constants';

interface ProductSearchInputProps {
  cart?: boolean;
}

const ProductSearchInput: React.FC<ProductSearchInputProps> = () => {
  const { search, filterProductsBySearch } = useProductsStore((state) => ({
    search: state.search,
    filterProductsBySearch: state.filterProductsBySearch,
  }));
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const { track } = React.useContext(TrackingContext);

  const [value, setValue] = React.useState(search);

  const handleSearch = (input: string) => {
    track('OnCustomerSearchInput', {
      term: input,
    });
    filterProductsBySearch(input);
  };

  const debouncedSearch = debounce(handleSearch, 1000);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setValue(v);
    debouncedSearch(v);
  };

  React.useEffect(() => {
    setValue(search);
  }, [search]);

  return (
    <Skeleton
      width="100%"
      height="30px"
      borderRadius="5px"
      className="mb-2"
      isLoading={isLoading}
    >
      <div className="relative flex items-center mt-1 mb-2">
        <SearchInput
          onChange={onChange}
          value={value}
          placeholder="Buscar categoria, produto ou marca"
        />
      </div>
    </Skeleton>
  );
};

export default ProductSearchInput;
