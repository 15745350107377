import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreditCardForm,
  ICreditCardForm,
  creditCardValidation,
} from '@onbeef/components/creditCardForm';
import { Checkbox, Select, TextInput } from '@onbeef/components/input';
import {
  LoadingIcon,
  Toast,
  formatBirthday,
  maskCPF,
  maskDate,
  maskPhone,
  mergeSchema,
  genderOptions,
} from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import HeaderBar from '../../components/Headers/HeaderBar';
import {
  useCreateClubSubscription,
  useVerifyPaymentStatus,
} from '../../queries/club';
import { ClubSubscriptionPayload } from '../../queries/club/types';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useUserStore } from '../../stores/user';
import { Tab } from '@onbeef/components/tab';
import { Helmet } from 'react-helmet';
import { ClockIcon } from '@heroicons/react/24/outline';

export type ICustomerForm = {
  have_account: boolean;
  without_cpf: boolean;
  name?: string;
  phone?: string;
  email?: string;
  cpf?: string;
  birthday?: string;
  sex?: string;
  password?: string;
};

enum tabs {
  CUSTOMER = 1,
  PAYMENT = 2,
}

const clubValidationSchema = Yup.object().shape({
  name: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Nome é obrigatório'),
  }),
  phone: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Celular é obrigatório'),
  }),
  email: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) =>
      schema.email('Email inválido').required('Email é obrigatório'),
  }),
  cpf: Yup.string().when(['have_account', 'without_cpf'], {
    is: (haveAcc: boolean, withoutCpf: boolean) => {
      return haveAcc ? !withoutCpf : !haveAcc;
    },
    then: (schema) =>
      schema.required('CPF é obrigatório').min(14, 'Informe um CPF válido'),
  }),
  birthday: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Data de nascimento obrigatória'),
  }),
  sex: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Sexo é obrigatório'),
  }),
  password: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Senha é obrigatória'),
  }),
});

const customerValidationSchema = mergeSchema(clubValidationSchema);
const paymentValidationSchema = mergeSchema(creditCardValidation);

export const ClubRegister: React.FC = () => {
  const { club_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const is_free = location?.state?.is_free || false;

  const merchant = useMerchantStore((state) => state.merchant);

  const clubs = useMerchantStore((state) => state.clubs);
  const club = clubs?.find((c) => c.id === club_id);

  const user = useAuthStore((state) => state.user);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const address = useUserStore((state) => state.address);
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const { mutateAsync: createClubSubscription, isLoading } =
    useCreateClubSubscription();
  const { mutateAsync: verifyPaymentStatus } = useVerifyPaymentStatus();

  const customerMethods = useForm<ICustomerForm>({
    resolver: yupResolver(customerValidationSchema),
  });
  const paymentMethods = useForm<ICreditCardForm>({
    resolver: yupResolver(paymentValidationSchema),
  });

  const [customerData, setCustomerData] = React.useState<
    ICustomerForm | undefined
  >();

  const [activeTab, setActiveTab] = React.useState(tabs.CUSTOMER);
  const [hiddenPassword, setHiddenPassword] = React.useState(true);

  const [token, setToken] = React.useState('');
  const [subID, setSubID] = React.useState('');

  const customerSubmit = async (formData: ICustomerForm) => {
    setCustomerData(formData);
    if (is_free) {
      handlePaymentSubmit(formData);
    } else {
      setActiveTab(tabs.PAYMENT);
    }
  };

  const paymentSubmit = async (formData: ICreditCardForm) => {
    if (customerData) {
      handlePaymentSubmit(customerData, formData);
    }
  };

  const handlePaymentSubmit = async (
    customerFormData: ICustomerForm,
    paymentFormData?: ICreditCardForm,
  ) => {
    const withCard =
      paymentFormData &&
      paymentFormData.card &&
      paymentFormData.card.credit_card_active;

    if (withCard && !paymentFormData?.billing_address.zip) {
      paymentMethods.setValue('billing_address.without', false);
      paymentMethods.handleSubmit(() => {})();
      return;
    }

    const payload: ClubSubscriptionPayload = {
      club_id: club_id!,
      email: customerFormData.email,
      method_payment: 'CREDIT_CARD',
      name: customerFormData.name,
      birthday: formatBirthday(customerFormData.birthday),
      gender: customerFormData.sex,
      phone: customerFormData.phone,
      taxpayer_id_number: customerFormData.cpf,
      password: customerFormData.password,
      card: withCard
        ? {
            cvv: paymentFormData.card.cvv,
            expiry_month: paymentFormData.card.expiration.split('/')[0] || '',
            expiry_year: paymentFormData.card.expiration.split('/')[1] || '',
            holder_name: paymentFormData.card.name,
            number: paymentFormData.card.number,
            holder: {
              billing_address: {
                address: paymentFormData.billing_address.street_name,
                zip: paymentFormData.billing_address.zip,
                number: paymentFormData.billing_address.street_number,
                city: paymentFormData.billing_address.city,
                state: paymentFormData.billing_address.state,
                country: 'BR',
                complement:
                  paymentFormData.billing_address.street_complement || '',
              },
              name: paymentFormData.card.name,
              email: customerFormData.email,
              phone: customerFormData.phone,
              taxpayer_id_number: customerFormData.cpf,
            },
          }
        : undefined,
    };

    try {
      const res = await createClubSubscription(payload);
      if (res.payment && res.payment.link) {
        window.open(res.payment.link, '_blank');
      }
      res.message && Toast.success(res.message);
      res.auth && setAuthState(true, res.auth);
      if (res.token && res.id) {
        setToken(res.token);
        setSubID(res.id);
      } else {
        navigate('/', { replace: true });
      }
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const handleVerifyPayment = async () => {
    try {
      const res = await verifyPaymentStatus({ subscription_id: subID, token });
      if (res.auth) {
        setAuthState(true, res.auth);
        Toast.success(
          'Pagamento confirmado! Seus benefícios já estão disponíveis!',
        );
        navigate('/', { replace: true });
      }
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
      navigate('/club', { replace: true });
    }
  };

  React.useEffect(() => {
    customerMethods.setValue('have_account', haveAccount);
    customerMethods.setValue('without_cpf', haveAccount);
    if (haveAccount) {
      customerMethods.setValue('name', user.name);
      customerMethods.setValue('email', user.email);
      user.phone && customerMethods.setValue('phone', maskPhone(user.phone));
      user.taxpayer_id_number &&
        customerMethods.setValue('cpf', maskCPF(user.taxpayer_id_number));
    }
  }, [user]);

  React.useEffect(() => {
    if (token) {
      const intervalID = setInterval(() => {
        handleVerifyPayment();
      }, 3000);
      return () => clearInterval(intervalID);
    }
  }, [token]);

  const withoutCpf = customerMethods.watch('without_cpf');
  const haveAccount = isAuthenticated && user?.taxpayer_id_number != null;

  if (!club) {
    navigate(-1);
  }

  return (
    <div className="w-full h-full p-4 space-y-2">
      <Helmet>
        <meta name="title" content={`${club?.name} do(a) ${merchant?.name}`} />
        <meta name="description" content={club?.description} />
      </Helmet>
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Assinar clube
        </span>
      </HeaderBar>
      <div className="w-full flex flex-col space-y-6">
        <div className="w-full flex flex-col space-y-6 pb-6">
          {token ? (
            <div className="flex flex-col justify-center items-center gap-4 p-6 rounded-lg max-w-md mx-auto">
              <ClockIcon className="w-24 h-24 text-gray-600" />

              <span className="text-xl font-semibold text-center text-gray-800 mt-4">
                Estamos processando seu pagamento!
              </span>

              <span className="font-medium text-gray-600 text-center mt-2">
                Isso pode levar até 5 minutos. Por favor, aguarde enquanto
                finalizamos o processo.
              </span>

              <LoadingIcon className="text-black !w-8 !h-8 mt-4" />
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-2">
              <span className="font-medium mb-2">
                Quero ser parte do clube {club?.name} e ganhar benefícios
              </span>
              <div className="w-full flex flex-col items-center space-y-4">
                {!is_free && (
                  <div className="w-full flex flex-row items-center">
                    <Tab
                      active={activeTab === tabs.CUSTOMER}
                      onClick={() => setActiveTab(tabs.CUSTOMER)}
                      className="w-full"
                    >
                      <span className="text-sm">Dados</span>
                    </Tab>
                    <Tab
                      active={activeTab === tabs.PAYMENT}
                      onClick={() => setActiveTab(tabs.PAYMENT)}
                      disabled={!customerData}
                      className="w-full"
                    >
                      <span className="text-sm">Pagamento</span>
                    </Tab>
                  </div>
                )}

                {activeTab === tabs.CUSTOMER && (
                  <FormProvider {...customerMethods}>
                    <form
                      onSubmit={customerMethods.handleSubmit(customerSubmit)}
                      className="w-full flex flex-col space-y-4"
                    >
                      {!haveAccount && (
                        <div className="w-full flex flex-col space-y-2">
                          <TextInput name="name" customLabel="Nome" />
                          <TextInput name="email" customLabel="Email" />
                          <TextInput
                            name="birthday"
                            customLabel="Data de aniversário"
                            onChange={(e) => {
                              customerMethods.setValue(
                                'birthday',
                                maskDate(e.target.value),
                              );
                              customerMethods.trigger('birthday');
                            }}
                          />
                          <TextInput
                            name="phone"
                            customLabel="Celular"
                            inputMode="numeric"
                            autoComplete="phone"
                            maxLength={15}
                            placeholder="(00) 00000-0000"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              customerMethods.setValue(
                                'phone',
                                maskPhone(e.target.value),
                              );
                              customerMethods.trigger('phone');
                            }}
                          />
                          <Select
                            customLabel={'Sexo'}
                            placeholder={'Selecione seu sexo'}
                            options={genderOptions}
                            name={'sex'}
                          />
                        </div>
                      )}
                      {haveAccount && (
                        <Checkbox
                          name="without_cpf"
                          customLabel="Mesmo CPF da conta"
                        />
                      )}
                      {!withoutCpf && (
                        <TextInput
                          name="cpf"
                          customLabel="CPF"
                          inputMode="numeric"
                          autoComplete="cpf"
                          onChange={(e) => {
                            customerMethods.setValue(
                              'cpf',
                              maskCPF(e.target.value),
                            );
                            customerMethods.trigger('cpf');
                          }}
                        />
                      )}
                      {!haveAccount && (
                        <TextInput
                          name="password"
                          customLabel="Senha"
                          type={hiddenPassword ? 'password' : 'text'}
                          className={`mb-1 rounded-b-lg text-base f-16 transition-all duration-700 ease-in-out`}
                          endIcon={
                            <button
                              type="button"
                              onClick={() => setHiddenPassword(!hiddenPassword)}
                            >
                              {hiddenPassword ? (
                                <EyeIcon
                                  className={`h-6 w-6 mr-2 mb-1 text-gray-600`}
                                />
                              ) : (
                                <EyeSlashIcon
                                  className={`h-6 w-6 mr-2 mb-1 text-gray-600`}
                                />
                              )}
                            </button>
                          }
                        />
                      )}

                      <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full p-2 flex justify-center mt-4 rounded-lg bg-primary text-contrastText"
                      >
                        {isLoading ? (
                          <LoadingIcon />
                        ) : is_free ? (
                          'Participar de graça'
                        ) : (
                          'Próxima etapa'
                        )}
                      </button>
                    </form>
                  </FormProvider>
                )}

                {activeTab === tabs.PAYMENT && (
                  <FormProvider {...paymentMethods}>
                    <form
                      className="w-full flex flex-col space-y-6"
                      onSubmit={paymentMethods.handleSubmit(paymentSubmit)}
                    >
                      <div className="w-full flex flex-col items-center space-y-2">
                        <CreditCardForm active={!is_free} address={address} />
                      </div>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full p-2 bg-primary text-contrastText rounded-lg flex items-center justify-center"
                      >
                        {isLoading ? <LoadingIcon /> : 'Pagar'}
                      </button>
                    </form>
                  </FormProvider>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
