import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Yup from '@onbeefapp/constants/src/utils/Yup';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { useSlug } from '../../hooks/useSlug';
import { useValidateCoupon } from '../../queries/coupon';
import { useMerchantCache } from '../../queries/home';
import { useCartStore } from '../../stores/cart';
import { useOrderDetails } from '../../stores/order-details';
import LoadingSpin from '../LoadingSpin';
import Skeleton from '../Skeleton/Skeleton';
import { TextInput } from '@onbeef/components/input';
import { Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';

type PrivateCuponData = {
  coupon: string;
};

const FormPrivateCoupon: React.FC = () => {
  const params = useSlug();
  const navigate = useNavigate();
  const { isLoading: isLoadingMerchant } = useMerchantCache(params.slug);

  const { merchantId } = useCartStore();
  const { deliveryType } = useOrderDetails();
  const { subtotalWithDiscount } = React.useContext(OrderDetailsContext);

  const { setCoupon } = useCartStore();

  const {
    mutate: validateCoupon,
    isLoading,
    data,
    error,
  } = useValidateCoupon();

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required('Cupom é obrigatório'),
  });

  const methods = useForm<PrivateCuponData>({
    resolver: yupResolver(validationSchema),
  });
  const errors = methods.formState.errors;

  const onSubmit = (data: PrivateCuponData) => {
    if (!merchantId || isLoading) return;
    validateCoupon({
      code: data.coupon,
      merchant_id: merchantId,
      order_amount: parseFloat(subtotalWithDiscount.toFixed(2)),
      type_delivery: deliveryType,
    });
  };

  React.useEffect(() => {
    if (data) {
      if (data.error) {
        methods.setError('coupon', { message: data.error[0] });
        return;
      }
      setCoupon(data);
      Toast.success(data.message);
      navigate(-1);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  }, [error]);

  return (
    <Skeleton isLoading={isLoadingMerchant} className="w-full rounded-md h-11">
      <FormProvider {...methods}>
        <form
          className="flex flex-col w-full"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="flex w-full justify-around">
            <div className="flex flex-col w-[75%]">
              <TextInput
                name="coupon"
                validationSchema={{}}
                placeholder="Insira seu cupom de desconto"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setValue('coupon', e.target.value.toUpperCase());
                  methods.trigger('coupon');
                }}
                className={`w-full rounded-lg outline-none border border-gray-300 text-sm p-2 ${
                  errors?.coupon !== undefined && 'border-red-400'
                }`}
              />
            </div>
            <div className="flex w-[20%]">
              <button
                type="submit"
                className="p-2 text-contrastText bg-primary w-full text-sm rounded-md flex items-center justify-center font-semibold"
              >
                {isLoading ? <LoadingSpin /> : 'Validar'}
              </button>
            </div>
          </div>
          <div className="flex w-full text-red-400 text-xs">
            {errors?.coupon && errors?.coupon?.message}
          </div>
        </form>
      </FormProvider>
    </Skeleton>
  );
};

export default FormPrivateCoupon;
