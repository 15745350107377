import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import {
  Gender,
  genderOptions,
  maskCPF,
  maskDate,
  maskPhone,
} from '@onbeefapp/constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  GroupRadioButton,
  RadioButton,
  TextInput,
} from '@onbeef/components/input';

export interface ICustomerForm {
  with_password: boolean;
  no_password_confirmation: boolean;
  name?: string;
  phone?: string;
  email?: string;
  taxpayer_id_number?: string;
  birthday?: string;
  gender?: Gender;
  password?: string;
  password_confirmation?: string;
  hide_birthday?: boolean;
  hide_email?: boolean;
  hide_cpf?: boolean;
}

export interface CustomerFormProps {
  withPassword?: boolean;
  phoneDisabled?: boolean;
  emailDisabled?: boolean;
  cpfDisabled?: boolean;
  noPasswordConfirmation?: boolean;
  hideBirthday?: boolean;
  hideEmail?: boolean;
  hideCpf?: boolean;
}

export const CustomerForm: React.FC<CustomerFormProps> = ({
  withPassword = true,
  phoneDisabled = false,
  emailDisabled = false,
  cpfDisabled = false,
  noPasswordConfirmation = false,
  hideBirthday = false,
  hideEmail = false,
  hideCpf = false,
}) => {
  const methods = useFormContext<ICustomerForm>();
  const [hiddenPassword, setHiddenPassword] = React.useState(true);
  const [hiddenPasswordConfirmation, setHiddenPasswordConfirmation] =
    React.useState(true);

  React.useEffect(() => {
    methods.reset({
      with_password: withPassword,
      no_password_confirmation: noPasswordConfirmation,
      hide_birthday: hideBirthday,
      hide_email: hideEmail,
      hide_cpf: hideCpf,
    });
  }, [withPassword, noPasswordConfirmation, hideBirthday, hideEmail, hideCpf]);

  return (
    <div className="w-full flex flex-col space-y-2">
      <TextInput name="name" customLabel="Nome" />
      <TextInput
        name="phone"
        customLabel="Celular"
        inputMode="numeric"
        autoComplete="phone"
        maxLength={15}
        placeholder="(00) 00000-0000"
        disabled={phoneDisabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          methods.setValue('phone', maskPhone(e.target.value));
          methods.trigger('phone');
        }}
      />
      <TextInput
        name="email"
        customLabel="Email"
        disabled={emailDisabled}
        containerClassName={hideEmail ? 'hidden' : ''}
      />
      <GroupRadioButton
        alignment={'horizontal'}
        className="flex flex-col gap-1"
      >
        <span>Sexo</span>
        <div className="w-full flex gap-2 flex-col md:flex-row">
          {genderOptions.map((opt) => (
            <RadioButton
              key={opt.value}
              name={'gender'}
              label={opt.label}
              value={opt.value}
              id={opt.value}
            />
          ))}
        </div>
      </GroupRadioButton>
      <TextInput
        name="birthday"
        customLabel="Data de aniversário"
        onChange={(e) => {
          methods.setValue('birthday', maskDate(e.target.value));
          methods.trigger('birthday');
        }}
        containerClassName={hideBirthday ? 'hidden' : ''}
      />
      <TextInput
        name="taxpayer_id_number"
        customLabel="CPF"
        inputMode="numeric"
        autoComplete="cpf"
        onChange={(e) => {
          methods.setValue('taxpayer_id_number', maskCPF(e.target.value));
          methods.trigger('taxpayer_id_number');
        }}
        disabled={cpfDisabled}
        containerClassName={hideCpf ? 'hidden' : ''}
      />
      {withPassword && (
        <>
          <TextInput
            name="password"
            customLabel="Senha"
            type={hiddenPassword ? 'password' : 'text'}
            className={`mb-1 rounded-b-lg text-base f-16 transition-all duration-700 ease-in-out`}
            endIcon={
              <button
                type="button"
                onClick={() => setHiddenPassword(!hiddenPassword)}
              >
                {hiddenPassword ? (
                  <EyeIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                ) : (
                  <EyeSlashIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                )}
              </button>
            }
          />
          {!noPasswordConfirmation && (
            <TextInput
              name="password_confirmation"
              customLabel="Confirmação de senha"
              type={hiddenPasswordConfirmation ? 'password' : 'text'}
              className={`mb-1 rounded-b-lg text-base f-16 transition-all duration-700 ease-in-out`}
              endIcon={
                <button
                  type="button"
                  onClick={() =>
                    setHiddenPasswordConfirmation(!hiddenPasswordConfirmation)
                  }
                >
                  {hiddenPasswordConfirmation ? (
                    <EyeIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                  ) : (
                    <EyeSlashIcon
                      className={`h-6 w-6 mr-2 mb-1 text-gray-600`}
                    />
                  )}
                </button>
              }
            />
          )}
        </>
      )}
    </div>
  );
};
