import { yupResolver } from '@hookform/resolvers/yup';
import { creditCardValidation } from '@onbeef/components/creditCardForm';
import { Checkbox, TextInput } from '@onbeef/components/input';
import {
  DeliveryType,
  LoadingIcon,
  PaymentMethodType,
  Toast,
  currencyToFloat,
  mascaraMoedaFormat,
  maskCPF,
  maskPhone,
} from '@onbeefapp/constants';
import Yup, { mergeSchema } from '@onbeefapp/constants/src/utils/Yup';
import cx from 'classnames';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ExtensionCheckoutButton } from '../../../../../lojista/src/components/ModalCreateOrder/ExtensionCheckoutButton';
import { OrderDetailsContext } from '../../../contexts/OrderDetailsContext';
import { TrackingContext } from '../../../contexts/TrackingContext';
import { useSlug } from '../../../hooks/useSlug';
import CartSummary from '../../../pages/Cart/CartSummary';
import OrderDetailItemsImage from '../../../pages/Orders/Detail/OrderDetailItemsImage';
import { usePayOrder } from '../../../queries/checkout-link';
import { PayOrderPayload } from '../../../queries/checkout-link/types';
import { usePhoneHasClub } from '../../../queries/club';
import { useMerchantCache } from '../../../queries/home';
import { useCreateOrder } from '../../../queries/order';
import { OrderPayload } from '../../../queries/order/types';
import { useCartStore } from '../../../stores/cart';
import { useComplementsStore } from '../../../stores/complements';
import { useKitsStore } from '../../../stores/kits';
import { useAuthStore } from '../../../stores/login';
import { useMerchantStore } from '../../../stores/merchant';
import { useOrderDetails } from '../../../stores/order-details';
import { useOrderStore } from '../../../stores/orders';
import { useGeneratedOrderStore } from '../../../stores/pix';
import { useUserStore } from '../../../stores/user';
import { isMobileDevice } from '../../../utils/function';
import { CartProductsMapper } from '../../../utils/OrderUtils';
import CollapsibleItem from '../../Collapse/CollapsibleItem';
import LoadingScreen from '../../LoadingScreen';
import ConfirmOrderWithoutClubModal from '../../Modals/ConfirmOrderWithoutClub';
import Skeleton from '../../Skeleton/Skeleton';
import { ConfirmCvv } from './CheckoutCreditCard';
import { FormCustomerCheckoutInfo } from './FormCustomerCheckoutInfo';

export type Checkout = {
  name: string;
  phone: string;
  document?: string;
  email: string;
  money_back: string;
  without_money_back: boolean;
  result: boolean;
  id: string;
  freight: number;
  type: string;
  duration: number;
  pickup_duration: number;
  expired_at: string;
  cvv: string;
};

interface FormCheckoutProps {
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
  onClickContinue?: () => void;
  orderId?: string;
  extensionCustomCouponCode?: string;
  extensionCustomDeliveryFee?: number | string;
}

export const useCreateCheckoutForm = (): UseFormReturn<Checkout> => {
  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  const { name, phone, email, taxpayer_id_number } = useUserStore((state) => ({
    name: state.name,
    phone: state.phone,
    email: state.email,
    taxpayer_id_number: state.taxpayer_id_number,
  }));

  const { selectedPaymentMethod, withoutMoneyBack } = useOrderDetails(
    (state) => ({
      selectedPaymentMethod: state.selectedPaymentMethod,
      withoutMoneyBack: state.withoutMoneyBack,
    }),
  );

  const { isExtension, isCheckoutLink } = useCartStore((state) => ({
    isExtension: state.isExtension,
    isCheckoutLink: state.isCheckoutLink,
  }));

  const checkoutFormValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    phone: Yup.string()
      .required('Celular é obrigatório')
      .min(14, 'Celular inválido'),
    document:
      (!isExtension && merchant?.cpf_cnpj_enable) ||
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX
        ? Yup.string()
            .required('Documento é obrigatório')
            .test('document', 'CPF/CNPJ inválido', (value) =>
              validateCPForCNPJ(value),
            )
        : Yup.string().nullable(),
    email:
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX
        ? Yup.string().required('E-mail é obrigatório')
        : Yup.string().nullable(),
    money_back:
      selectedPaymentMethod === PaymentMethodType.MONEY && !withoutMoneyBack
        ? Yup.string().required('Troco é obrigatório')
        : Yup.string().nullable(),
    without_money_back: Yup.boolean(),
  });

  function validateCPForCNPJ(value?: string | null | undefined): boolean {
    if (!value) return true;
    if (isCheckoutLink) return true; // ignore censored taxpayer_id_number
    return cpf.isValid(value) || cnpj.isValid(value);
  }

  const validationSchema = mergeSchema(
    checkoutFormValidation,
    creditCardValidation,
  );

  return useForm<Checkout>({
    resolver: yupResolver(validationSchema) as any,
    mode: 'onBlur',
    defaultValues: {
      document: taxpayer_id_number ? maskCPF(taxpayer_id_number) : undefined,
      name: name,
      phone: phone ? maskPhone(phone) : undefined,
      email: email,
    },
  });
};

const FormCheckoutProvider: React.FC<{
  children: React.ReactNode;
  methods: UseFormReturn<Checkout>;
  existingMethods?: UseFormReturn<Checkout>;
}> = ({ children, methods, existingMethods }) => {
  if (existingMethods) {
    return <>{children}</>;
  }

  return <FormProvider {...methods}>{children}</FormProvider>;
};

const FormCheckout: React.FC<FormCheckoutProps> = ({
  onClickContinue,
  orderId,
  extensionCustomCouponCode,
  extensionCustomDeliveryFee,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const slugParams = useSlug();
  const params = useParams();
  const navigate = useNavigate();
  const { track } = React.useContext(TrackingContext);

  const { merchant, clubs } = useMerchantStore((state) => ({
    merchant: state.merchant,
    clubs: state.clubs,
  }));
  const { isLoading: isLoadingMerchant } = useMerchantCache(slugParams.slug);

  const {
    deliveryType,
    deliveryInstructions,
    selectedPaymentMethod,
    selectedCardID,
    withoutMoneyBack,
    setWithoutMoneyBack,
    setDeliveryInstructions,
    setSelectedCardID,
    employeeID,
  } = useOrderDetails((state) => ({
    deliveryType: state.deliveryType,
    deliveryInstructions: state.deliveryInstructions,
    selectedPaymentMethod: state.selectedPaymentMethod,
    selectedCardID: state.selectedCardID,
    withoutMoneyBack: state.withoutMoneyBack,
    setWithoutMoneyBack: state.setWithoutMoneyBack,
    setDeliveryInstructions: state.setDeliveryInstructions,
    setSelectedCardID: state.setSelectedCardID,
    employeeID: state.employeeID,
  }));

  const {
    products,
    coupon,
    deliveryTime,
    isExtension,
    isCheckoutLink,
    linkOrderDetails,
    clearCart,
    setCustomDeliveryFeeError,
    crmBonusDiscount,
    setCrmBonusData,
    setCrmBonusDiscount,
  } = useCartStore((state) => ({
    products: state.products,
    coupon: state.coupon,
    deliveryTime: state.deliveryTime,
    isExtension: state.isExtension,
    isCheckoutLink: state.isCheckoutLink,
    linkOrderDetails: state.linkOrderDetails,
    clearCart: state.clearCart,
    setCustomDeliveryFeeError: state.setCustomDeliveryFeeError,
    crmBonusDiscount: state.crmBonusDiscount,
    setCrmBonusData: state.setCrmBonusData,
    setCrmBonusDiscount: state.setCrmBonusDiscount,
  }));

  const {
    customer_id,
    userCards,
    localCards,
    address,
    name,
    taxpayer_id_number,
    phone,
    email,
    gender,
    birthday,
    ageGroup,
    setMoneyBack,
  } = useUserStore((state) => ({
    customer_id: state.customer_id,
    userCards: state.cards,
    localCards: state.local_cards,
    address: state.address,
    name: state.name,
    taxpayer_id_number: state.taxpayer_id_number,
    phone: state.phone,
    email: state.email,
    gender: state.gender,
    ageGroup: state.ageGroup,
    birthday: state.birthday,
    setMoneyBack: state.setMoneyBack,
  }));

  const { isAuthenticated, userLoyaltyProgramme } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    userLoyaltyProgramme: state.user?.loyalty_programmes?.[0],
  }));

  const existingMethods = useFormContext<Checkout>();

  const methods = existingMethods || useCreateCheckoutForm();
  const errors = methods.formState.errors;

  const [activeListItems, setActiveListItems] = React.useState(false);

  const { mutateAsync: phoneHasClub, isLoading: hasClubLoading } =
    usePhoneHasClub();

  const {
    mutateAsync,
    data: createOrderData,
    isLoading: createOrderLoading,
    isSuccess,
    isError,
  } = useCreateOrder();

  const {
    mutateAsync: payOrder,
    data: payOrderData,
    isLoading: payOrderLoading,
    isSuccess: paySuccess,
    isError: payError,
  } = usePayOrder();

  const isLoading = createOrderLoading || payOrderLoading;

  const { clearMerchantIdComplements } = useComplementsStore((state) => ({
    clearMerchantIdComplements: state.clearMerchantIdComplements,
  }));

  const { clearAllKits } = useKitsStore((state) => ({
    clearAllKits: state.clearAllKits,
  }));

  const { subtotal, useReward, clubDiscountAvailable } =
    React.useContext(OrderDetailsContext);
  const { setGeneratedOrder } = useGeneratedOrderStore((state) => ({
    setGeneratedOrder: state.setGeneratedOrder,
  }));
  const { addPaidOrder } = useOrderStore((state) => ({
    addPaidOrder: state.addPaid,
  }));

  const handleClearCartClick = () => {
    setDeliveryInstructions('');
    track(`OnCustomerAtCheckoutCartClear`, {});
    track(`OnCustomerLeaveCheckout`, {});
    clearCart();
    clearMerchantIdComplements();
    clearAllKits();
    setSelectedCardID(undefined);
    setCrmBonusData(undefined);
    setCrmBonusDiscount(0);
  };

  React.useEffect(() => {
    if (createOrderData) {
      if (Array.isArray(createOrderData?.error)) {
        createOrderData?.error?.map((error: any) => {
          if (
            error ==
            'Loja não tem o horário selecionado disponível para agendamento!'
          ) {
            navigate(`/schedule`);
          }
          Toast.error(error);
        });

        return;
      } else if (createOrderData.error === true) {
        return Toast.error(createOrderData?.message);
      }
      setGeneratedOrder(createOrderData.id, createOrderData, !!useReward);
    }

    if (isSuccess) {
      handleClearCartClick();
    }

    if (
      isSuccess &&
      createOrderData?.payment_codes?.status === 'AUTHORIZED_PENDING_CAPTURE' &&
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD
    ) {
      track('OnCustomerSubmitOrder', {
        payment_method: selectedPaymentMethod,
        delivery_type: deliveryType,
      });
      navigate(
        `/checkout/${createOrderData?.id}/validate-cents/${createOrderData?.code}`,
        {
          replace: true,
        },
      );
      addPaidOrder(params.id);
      return;
    }

    if (createOrderData?.payment_codes?.error) {
      Toast.error(createOrderData.payment_codes?.error?.toString());
    } else {
      if (
        isSuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX &&
        createOrderData
      ) {
        track('OnCustomerAtCheckoutContinueWithPix', {
          payment_method: selectedPaymentMethod,
          delivery_type: deliveryType,
          subtotal: subtotal,
        });
        navigate(`/checkout/pix/${createOrderData.id}`, { replace: true });
      } else {
        if (createOrderData) {
          track('OnCustomerSubmitOrder', {
            payment_method: selectedPaymentMethod,
            delivery_type: deliveryType,
          });
          if (isExtension && onClickContinue) {
            onClickContinue();
          } else {
            navigate(`/status/${createOrderData.id}`, { replace: true });
          }
        }
      }
    }
  }, [isSuccess, isError]);

  React.useEffect(() => {
    if (payOrderData) {
      setGeneratedOrder(payOrderData.id, payOrderData, !!useReward);
    }

    if (
      paySuccess &&
      payOrderData?.payment_codes?.status === 'AUTHORIZED_PENDING_CAPTURE' &&
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD
    ) {
      navigate(
        `/checkout/${payOrderData.id}/validate-cents/${payOrderData.code}`,
        {
          replace: true,
        },
      );
      addPaidOrder(params.id);
    }

    if (payOrderData?.payment_codes?.error) {
      Toast.error(payOrderData.payment_codes?.error?.toString());
    } else {
      if (
        paySuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX &&
        payOrderData
      ) {
        clearCart();
        navigate(`/checkout/pix/${payOrderData.id}`, { replace: true });
      }
    }
  }, [paySuccess, payError]);

  React.useEffect(() => {
    if (isCheckoutLink && linkOrderDetails) {
      methods.setValue('name', linkOrderDetails.customer.name);
      methods.setValue('phone', linkOrderDetails.customer.phone);
      methods.setValue(
        'document',
        linkOrderDetails.customer.taxpayer_id_number || taxpayer_id_number,
      );
      methods.setValue('email', linkOrderDetails.customer.email || email);
    } else {
      methods.setValue('name', name);
      methods.setValue('phone', phone ? maskPhone(phone) : '');
      methods.setValue(
        'document',
        taxpayer_id_number ? maskCPF(taxpayer_id_number) : '',
      );
      methods.setValue('email', email);
    }
  }, [isCheckoutLink, linkOrderDetails, phone]);

  const without_money_back = methods.watch('without_money_back');

  React.useEffect(() => {
    setWithoutMoneyBack(without_money_back);
  }, [without_money_back]);

  const handleConfirmCvv = async () => {
    if (selectedPaymentMethod !== PaymentMethodType.CHECKOUT_CREDIT_CARD)
      return true;
    const localCard = localCards.find((lc) => lc.id === selectedCardID);
    if (methods.getValues('cvv') || localCard) return true;

    const isMobile = isMobileDevice();
    const res = await Swal.fire({
      html: '<div id="confirm-cvv-content"></div>',
      showCancelButton: false,
      showConfirmButton: false,
      preConfirm: () => {
        const input = document.getElementById('cvv') as HTMLInputElement;
        return input.value;
      },
      didOpen: () => {
        const container = document.getElementById('confirm-cvv-content');
        if (container) {
          const root = ReactDOM.createRoot(container);
          root.render(<ConfirmCvv />);
        }
      },
      position: isMobile ? 'bottom' : 'center',
      showClass: {
        backdrop: 'animate-slideInFromBottom',
      },
      hideClass: {
        backdrop: 'animate-slideOutToBottom',
      },
      customClass: {
        popup: isMobile ? 'rounded-t-lg w-full mx-0' : '',
        container: isMobile ? 'p-0' : '',
      },
    });
    if (res.isConfirmed && res.value) {
      methods.setValue('cvv', res.value);
      return true;
    }
    return false;
  };

  const handleConfirmClub = async () => {
    if (
      clubs &&
      clubs.length > 0 &&
      clubDiscountAvailable &&
      !isAuthenticated
    ) {
      const { has } = await phoneHasClub({
        id: merchant!.id,
        phone: methods.getValues('phone'),
      });
      if (has) {
        const res = await Swal.fire({
          html: '<div id="confirm-club-content"></div>',
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: () => {
            const container = document.getElementById('confirm-club-content');
            if (container) {
              const root = ReactDOM.createRoot(container);
              root.render(<ConfirmOrderWithoutClubModal />);
            }
          },
        });

        if (res.isConfirmed) {
          navigate('/login', { state: { goBack: '/checkout' } });
          return false;
        }
        return res.dismiss === Swal.DismissReason.cancel;
      }
    }
    return true;
  };

  const submitOrder = React.useCallback(
    (payloadOrder: OrderPayload) => {
      const handleErrors = (error: any) => {
        const response = error.response.data;
        if (Array.isArray(response?.error)) {
          response?.error?.map((error: any) => {
            Toast.error(error);
          });
          return;
        } else if (response.error === true) {
          return Toast.error(response?.message);
        }
      };

      if (isCheckoutLink && linkOrderDetails) {
        const linkPayload: PayOrderPayload = {
          method_payment: payloadOrder.method_payment!,
          email: payloadOrder.email ? payloadOrder.email : null,
          taxpayer_id_number: payloadOrder.taxpayer_id_number
            ? payloadOrder.taxpayer_id_number
            : null,
          payment: payloadOrder.payment,
        };
        payOrder({
          orderID: linkOrderDetails.id,
          code: linkOrderDetails.code,
          payload: linkPayload,
        }).catch(handleErrors);
        return;
      }

      const extension = payloadOrder.origin === 'EXTENSION';
      if (extension) {
        if (extensionCustomCouponCode && extensionCustomCouponCode !== '') {
          payloadOrder.code_coupon = extensionCustomCouponCode;
        }
        const customExtensionCustomFeeAmount: number = currencyToFloat(
          String(extensionCustomDeliveryFee),
        );
        if (extensionCustomDeliveryFee && customExtensionCustomFeeAmount >= 0) {
          payloadOrder.freight_amount = String(customExtensionCustomFeeAmount);
        }
      }
      mutateAsync(payloadOrder).catch(handleErrors);
    },
    [
      extensionCustomCouponCode,
      extensionCustomDeliveryFee,
      isCheckoutLink,
      linkOrderDetails,
    ],
  );

  const validations = (data: Checkout) => {
    if (!selectedPaymentMethod) {
      Toast.error('Selecione uma forma de pagamento');
      return true;
    }

    if (selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD) {
      const card = userCards.find((c) => c.id === selectedCardID);
      const localCard = localCards.find((c) => c.id === selectedCardID);
      if (!card && !localCard) {
        selectedCardID && setSelectedCardID(undefined);
        Toast.error('Informe um cartão!');
        navigate('/card/new');
        return true;
      }
    }

    if (deliveryType === DeliveryType.DELIVERY && !address && isExtension) {
      Toast.error('Selecione um endereço');
      return true;
    }

    if (
      !withoutMoneyBack &&
      selectedPaymentMethod === PaymentMethodType.MONEY &&
      currencyToFloat(data.money_back) < subtotal
    ) {
      Toast.error('Valor do troco deve ser maior que o valor total do pedido');
      return true;
    }

    if (
      isExtension &&
      deliveryType !== DeliveryType.WITHDRAWAL &&
      address &&
      (!address.latitude || !address.longitude) &&
      !extensionCustomDeliveryFee
    ) {
      Toast.error(
        'O valor da taxa de entrega é obrigatório quando o endereço foi colocado manualmente',
      );
      setCustomDeliveryFeeError(true);
      return true;
    }

    return false;
  };

  const onSubmit = async (data: Checkout) => {
    const validation = validations(data);
    if (validation === true) return;

    const confirms = [handleConfirmClub, handleConfirmCvv];
    for (let confirm of confirms) {
      const ok = await confirm();
      if (!ok) return;
    }

    // suporta apenas 1 clube
    const subsClubID = Object.values(products).find(
      (p) => p.clubSubscriptionID != null,
    )?.clubSubscriptionID;

    const orderItems = CartProductsMapper.toPayload(Object.values(products));

    const orderPayload: OrderPayload = {
      order_id: orderId ?? null,
      merchant_id: merchant?.id ?? '',
      origin: isExtension ? 'EXTENSION' : 'CATALOG',
      items: orderItems,
      use_bonus: crmBonusDiscount !== 0,
      loyalty_programme: useReward
        ? {
            customer_loyalty_programm_id:
              userLoyaltyProgramme!.customer_loyalty_programme_id,
            reward: {
              id: userLoyaltyProgramme!.reward!.id,
            },
          }
        : undefined,
      type_delivery:
        merchant?.delivery_details === 6 ? DeliveryType.UBER : deliveryType,
      comments:
        selectedPaymentMethod === PaymentMethodType.MONEY && !withoutMoneyBack
          ? `${deliveryInstructions} Troco para: ${data.money_back}`
          : deliveryInstructions,
      method_payment: selectedPaymentMethod,
      name: name,
      phone: phone,
      taxpayer_id_number:
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX
          ? taxpayer_id_number
          : undefined,
      email: email,
      gender: gender,
      age_group: ageGroup,
      birthday: birthday,
      subscription_club_id: subsClubID,
      amount: (Math.round((subtotal + Number.EPSILON) * 100) / 100).toFixed(2),
    };

    if (selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD) {
      orderPayload.payment = {
        installments: 1,
      };
      const localCard = localCards.find((lc) => lc.id === selectedCardID);
      if (localCard) {
        orderPayload.payment.card = localCard.payload;
      } else {
        orderPayload.payment.card = {
          id: selectedCardID!,
          cvv: methods.getValues('cvv'),
        };
      }
    }

    if (isExtension) {
      orderPayload.customer_id = customer_id;
      orderPayload.employee_id = employeeID;
    }

    if (deliveryType === DeliveryType.DELIVERY && address) {
      orderPayload.address = {
        city: address?.city,
        country: address?.country,
        neighborhood: address?.neighborhood,
        latitude: address?.latitude || null,
        longitude: address?.longitude || null,
        state: address?.state,
        street_complement: address?.street_complement ?? '',
        street_name: address?.street_name,
        street_number: address?.street_number,
        street_reference: address?.street_reference ?? '',
        zip: address?.zip,
      };
      orderPayload.lat_destiny = address.latitude || null;
      orderPayload.long_destiny = address.longitude || null;
    } else {
      orderPayload.lat_destiny = null;
      orderPayload.long_destiny = null;
    }

    if (coupon) {
      orderPayload.code_coupon = coupon.code;
      orderPayload.type = coupon.type;
    } else {
      orderPayload.code_coupon = undefined;
      orderPayload.type = undefined;
    }

    if (
      (deliveryType === DeliveryType.DELIVERY || isExtension) &&
      deliveryTime
    ) {
      orderPayload.schedule = deliveryTime;
    } else {
      orderPayload.schedule = undefined;
    }

    submitOrder(orderPayload);
  };

  return (
    <React.Fragment>
      <FormCheckoutProvider methods={methods} existingMethods={existingMethods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start justify-start w-full">
            {isCheckoutLink && (
              <div
                className={cx(
                  'flex flex-col gap-2 px-2 w-full',
                  activeListItems ? 'shadow-md' : '',
                )}
              >
                <CollapsibleItem
                  title="Items do pedido"
                  active={activeListItems}
                  onClick={() => setActiveListItems(!activeListItems)}
                >
                  <div
                    className="flex flex-col text-sm overflow-y-auto"
                    style={{ maxHeight: '30rem' }}
                  >
                    <OrderDetailItemsImage
                      items={linkOrderDetails?.items}
                      withImage
                    />
                  </div>
                </CollapsibleItem>
              </div>
            )}

            {!isExtension && (
              <FormCustomerCheckoutInfo
                setExtensionCustomCouponCode={setExtensionCustomCouponCode}
                setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
              />
            )}

            <div className="fixed left-0 bottom-0 flex flex-col items-start justify-start w-full p-3 bg-white rounded-t-xl">
              {/* Solicitar troco para o usuário caso selecione Dinheiro */}
              {selectedPaymentMethod === PaymentMethodType.MONEY && (
                <div className="flex flex-col gap-2 w-full mb-4 rounded-md">
                  <Skeleton
                    isLoading={isLoadingMerchant}
                    className="w-full rounded-md h-11"
                  >
                    {!without_money_back && (
                      <div>
                        <TextInput
                          name="money_back"
                          customLabel="Troco para quanto?"
                          validationSchema={{}}
                          autoComplete="money_back"
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                            mascaraMoedaFormat(e)
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            methods.setValue('money_back', e.target.value);
                            setMoneyBack(e.target.value);
                            //add trigger
                            methods.trigger('money_back');
                          }}
                          className={`py-2 px-4 pl-2 rounded-md w-full text-xs`}
                          error={errors?.money_back !== undefined}
                        />
                        <div className="text-red-400 text-xs">
                          {errors?.money_back && errors?.money_back?.message}
                        </div>
                      </div>
                    )}
                    <Checkbox
                      name="without_money_back"
                      customLabel="Sem troco"
                    />
                  </Skeleton>
                </div>
              )}

              {/* Termos de Serviço e Política de Uso de Dados */}
              <div className="w-full rounded-md">
                {isExtension && (
                  <CartSummary
                    extensionCustomDeliveryFee={extensionCustomDeliveryFee}
                    extensionCustomCouponCode={extensionCustomCouponCode}
                    setExtensionCustomCouponCode={setExtensionCustomCouponCode}
                    setExtensionCustomDeliveryFee={
                      setExtensionCustomDeliveryFee
                    }
                  />
                )}
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-16 h-2 rounded-lg"
                >
                  <div className="text-xs mt-2 text-[#7F8EA1] ml-1">
                    Ao enviar, você concorda com os <br className="sm:hidden" />
                    <a
                      href="https://docs.google.com/document/d/1Kv2DSSvgbt1X0KILj7kklXPS7Deg9xi94_9ggp7u_fk/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline font-regular text-[#000]"
                    >
                      Termos e Política de Dados
                    </a>{' '}
                    da Onbeef e do açougue.
                  </div>
                </Skeleton>
              </div>
              {/* Button confirm */}
              <div className="flex flex-row items-center justify-center w-full mt-3">
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-full rounded-md h-11"
                >
                  {isExtension ? (
                    <ExtensionCheckoutButton isLoading={isLoading} />
                  ) : (
                    <button
                      className="w-full flex justify-center p-2 text-contrastText bg-primary rounded-md text-sm"
                      type="submit"
                      disabled={isLoading || hasClubLoading}
                    >
                      {isLoading || hasClubLoading ? (
                        <LoadingIcon />
                      ) : selectedPaymentMethod ===
                        PaymentMethodType.CHECKOUT_PIX ? (
                        'Gerar PIX'
                      ) : isCheckoutLink ? (
                        'Realizar pagamento'
                      ) : (
                        'Fazer pedido'
                      )}
                    </button>
                  )}
                </Skeleton>
              </div>
            </div>
          </div>
        </form>
      </FormCheckoutProvider>
      {!isExtension && <LoadingScreen active={isLoading} />}
    </React.Fragment>
  );
};

export default FormCheckout;
