import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import {
  CardPayload,
  CardsResponse,
  CreateCardResponse,
  PayWithCreditCard,
  PayWithCreditCardResponse,
} from './types';

export const payWithCreditCard = async (payload: PayWithCreditCard) => {
  if (!payload) return;
  const { data } = await api.post<PayWithCreditCardResponse>(
    '/customers/orders/link/pay',
    payload,
  );
  return data;
};

export const createCard = async (payload: CardPayload) => {
  if (!payload) return;
  const { data } = await api.post<CreateCardResponse>(
    '/customers/cards',
    payload,
  );
  return data;
};

export const getCards = async (visitorId: string | undefined) => {
  if (!visitorId) return;
  const { data } = await api.get<CardsResponse>('/customers/cards', {
    headers: {
      'X-Token': visitorId,
    },
  });
  return data;
};

export const deleteCard = async (id: string) => {
  const { data } = await api.delete(`/customers/cards/${id}`);
  return data;
};

interface ValidateCentsPayload {
  cents: string;
  order_id: string;
  code: string;
}

async function validateCents({ cents, order_id, code }: ValidateCentsPayload) {
  if (!cents || !order_id) return;
  let res = await api.post(
    `/all/customers/orders/${order_id}/verification/${code}`,
    {
      cents,
    },
  );
  return res;
}

export function useCreateCard() {
  return useMutation(['useCreatecard'], createCard);
}

export function useGetCards() {
  return useMutation(['getCards'], getCards);
}

export function useDeleteCard() {
  return useMutation(['deleteCard'], deleteCard);
}

export function usePayWithCreditCard() {
  return useMutation(['payWithCreditCard'], payWithCreditCard);
}

export function useValidateCents() {
  return useMutation(['validateCents'], validateCents);
}
