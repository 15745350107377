import { useDrawerStore } from '../../stores/drawer';
import { useMerchantStore } from '../../stores/merchant';
import { IProductsStore, useProductsStore } from '../../stores/products';

type FilterProps = {
  handleSelectFilter: IProductsStore['setSelectedFilter'];
};

export const FilterOptions = ({ handleSelectFilter }: FilterProps) => {
  const filters = useMerchantStore((state) => state.filters);

  const { selectedFilters } = useProductsStore((state) => ({
    selectedFilters: state.filters,
  }));

  const { selectedFilterKey } = useDrawerStore((state) => ({
    selectedFilterKey: state.selectedFilterKey,
  }));

  const filter = selectedFilterKey ? filters?.[selectedFilterKey] : undefined;

  return (
    <div className="flex flex-col space-y-4">
      <article className="font-medium text-lg">{filter?.description}</article>
      <div className="flex flex-wrap gap-3 pb-6">
        {filter?.content.map((f, index) => {
          const filterSelected = selectedFilters.find((sf) => sf.id === f.id);
          return (
            <button
              key={`tagsProductEventType${index}`}
              onClick={() => {
                handleSelectFilter(f, {
                  type: filter.type,
                  key: selectedFilterKey!,
                });
              }}
              className={`shrink-0 p-2 min-w-[6.25rem] transition-all border ${
                filterSelected
                  ? `bg-primary text-contrastText border-primary`
                  : `bg-transparent text-primary border-primary`
              } rounded-2xl text-sm text-center font-medium flex flex-col gap-1 justify-center items-center cursor-pointer hover:opacity-80`}
            >
              <img
                className={'text-contrastText'}
                src={f.image}
                width={'45px'}
                height={'45px'}
                alt={f.name}
              />
              {f.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

type DrawerFiltersProps = {
  isOpen: boolean;
  id: string;
  height?: string;
};

const DrawerFilters = (params: DrawerFiltersProps) => {
  const { isOpen, id, height = '400px' } = params;

  const { filters, setSelectedFilter, filterProducts } = useProductsStore(
    (state) => ({
      filters: state.filters,
      setSelectedFilter: state.setSelectedFilter,
      filterProducts: state.filterProducts,
    }),
  );

  const { toggleDrawer } = useDrawerStore((state) => ({
    toggleDrawer: state.toggleDrawer,
  }));

  let open = isOpen && id === 'drawerFilters';

  const handleFilter = (
    ...params: Parameters<IProductsStore['setSelectedFilter']>
  ) => {
    setSelectedFilter(...params);
    filterProducts();
    handleClose();
  };

  const handleClose = () => {
    toggleDrawer('drawerFilters');
  };

  return (
    <div>
      <div
        className={`z-50 fixed inset-0 bottom-0 right-0 p-4 shadow-lg transition-all duration-300 transform
        ${
          open
            ? 'translate-y-0 bg-black-transparent-medium'
            : 'hidden bg-transparent'
        }`}
        onClick={handleClose}
      />
      <div
        style={{
          height: height,
        }}
        className={`translate-y-0 fixed z-50 bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform
        ${open ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
      >
        {filters ? (
          <div
            className={`h-full w-full z-50 overflow-y-scroll flex flex-col space-y-4`}
          >
            <FilterOptions handleSelectFilter={handleFilter} />
          </div>
        ) : (
          <span>Nenhum filtro disponível no momento</span>
        )}
      </div>
    </div>
  );
};

export default DrawerFilters;
