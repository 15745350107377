import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingContext } from '../../contexts/TrackingContext';

interface CardViewAllProductsProps {
  quantity: number;
  category_slug?: string;
}

const CardViewAllProducts: React.FC<CardViewAllProductsProps> = ({
  quantity,
  category_slug,
}) => {
  const navigate = useNavigate();
  const { track } = React.useContext(TrackingContext);

  return (
    <div
      onClick={() => {
        track('OnCustomerViewCategory', {
          slug: category_slug,
        });
        navigate(`/category/${category_slug}`);
      }}
      className="snap-start shrink-0 flex flex-col place-content-center w-20 max-w-xs relative bg-white rounded-xl border-2 border-gray-200 transition-all duration-300 hover:bg-gray-50 hover:cursor-pointer"
    >
      <div className="space-y-2 flex flex-col">
        <article className="text-2xl font-bold self-center">
          + {quantity}
        </article>
        <article className="text-xs font-normal self-center text-center">
          Produtos adicionais
        </article>
        <article className="text-xs font-bold self-center text-center text-primary">
          Ver mais
        </article>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-10 h-10 text-center self-center text-primary"
        >
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default CardViewAllProducts;
