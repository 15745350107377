import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CartButton from '../../components/CartButton';
import Category from '../../components/Category';
import Content from '../../components/Content';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import Header from '../../components/Headers/HeaderHome';
import HideMobile from '../../components/HideMobile/HideMobile';
import RightSide from '../../components/RightSide/RightSide';
import RotativeBanner from '../../components/Rotative/RotativeBanner';
import SearchBar from '../../components/Search/SearchBar';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useCartStore } from '../../stores/cart';
import { useDrawerStore } from '../../stores/drawer';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Incomplete from '../Incomplete/Incomplete';
import Sidebar from '../Sidebar/Sidebar';
import Benefits from '../../components/Benefits/Benefits';
import { FilterCards } from '../../components/Filters/FilterCards';

const Home: React.FC = () => {
  const { highlights, clearFilters } = useProductsStore((state) => ({
    highlights: state.highlights,
    clearFilters: state.clearFilters,
  }));
  const params = useSlug();
  const navigate = useNavigate();
  const { isOpen } = useDrawerStore((state) => ({
    isOpen: state.isOpen,
  }));
  const { isLoading } = useMerchantCache(params.slug);
  const { incompleteList, merchant } = useMerchantStore((state) => {
    return {
      incompleteList: state.incompleteList,
      merchant: state.merchant,
    };
  });
  const { productsCountIndividual } = useCartStore((state) => ({
    productsCountIndividual: state.productsCountIndividual,
  }));

  // PWA
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pwa = queryParams.get('pwa');
    if (merchant && pwa === 'true') {
      const canPwa = false as boolean;
      // merchant.subscription.plan.resources?.find(
      //   (item) => item.tag === 'APP',
      // ) != null;

      const isInStandaloneMode = () =>
        window.matchMedia('(display-mode: standalone)').matches ||
        (window.navigator as any).standalone ||
        document.referrer.includes('android-app://');

      if (canPwa === true) {
        let agent = '';
        if (navigator.userAgent.match(/iPhone/i) && !isInStandaloneMode()) {
          agent = 'iphone';
        }
        if (navigator.userAgent.match(/Android/i) && !isInStandaloneMode()) {
          agent = 'android';
        }
        if (agent === '') return;
        navigate(`/pwa?agent=${agent}`);
      }
    }
  }, [merchant]);

  React.useEffect(() => {
    clearFilters();
  }, []);

  if (
    incompleteList.filter((item) => item.status === false).length > 0 &&
    !isLoading
  ) {
    return <Incomplete />;
  }
  const showCart = productsCountIndividual > 0;

  return (
    <div>
      {/* Sidebar only for desktop views */}
      <Sidebar />

      <RightSide>
        <HideMobile>
          <Header />
        </HideMobile>

        <SearchBar button />

        <RotativeBanner />

        <Benefits />

        {/* Highlights */}
        <Category slug="destaques" name="Destaques" products={highlights} />

        <FilterCards />

        <Content />

        {/* Drawer com as especifições da gramatura do produto */}
        <DrawerGrams />

        {/* Carrinho hover bottom */}
        {/* Cart */}
        {/* if drawer is closed */}
        {!isOpen && showCart && <CartButton />}
      </RightSide>
    </div>
  );
};

export default Home;
