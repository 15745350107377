import {
  ClubSubscriptionStatus,
  LoadingIcon,
  Toast,
  formatCurrency,
} from '@onbeefapp/constants';
import { useCancelClubSubscription } from '../../../queries/club';
import { UserClub } from '../../../queries/login/types';
import { useAuthStore } from '../../../stores/login';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export interface CancelClubSubscriptionProps {
  subscription: UserClub;
}

export const CancelClubSubscription: React.FC<CancelClubSubscriptionProps> = ({
  subscription,
}) => {
  const navigate = useNavigate();

  const updateUserClubSubscriptions = useAuthStore(
    (state) => state.updateUserClubSubscriptions,
  );

  const { mutateAsync: cancelClubSubcription, isLoading } =
    useCancelClubSubscription();

  const handleChangePaymentMethod = async () => {
    navigate(`/club/payment-method/${subscription.club_id}`);
  };

  const handleCancelSubscription = async () => {
    try {
      const res = await cancelClubSubcription(subscription.id);
      res.message && Toast.success(res.message);
      updateUserClubSubscriptions(res.data);
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  const alreadyCanceled =
    subscription?.status === ClubSubscriptionStatus.CANCELED;

  const isPaid = subscription.club_price !== 0;
  const isPaymentMethodChangeable = isPaid && subscription.change_method_type;
  const withPaymentProblem = isPaid && subscription.message != null;

  return (
    <div className="flex flex-col space-y-2 mt-1">
      <div className="flex flex-col items-center space-y-2">
        {withPaymentProblem && (
          <div className="w-full flex flex-row gap-1 text-red-400">
            <ExclamationCircleIcon className="w-20 h-20 lg:w-5 lg:h-5" />{' '}
            <span className="text-xs">{subscription.message}</span>
          </div>
        )}
        {alreadyCanceled && (
          <div className="w-full flex flex-col items-start">
            <span className="font-medium">Assinatura cancelada</span>
            {subscription.expired_at && (
              <span className="text-sm font-medium">
                Seus benefícios vão durar até:{' '}
                {format(new Date(subscription.expired_at), 'dd/MM/yy')}
              </span>
            )}
          </div>
        )}
      </div>
      {isPaymentMethodChangeable && (
        <div>
          <button
            type="button"
            className={`bg-blue-600 flex font-semibold justify-center text-sm p-2 text-contrastText w-full rounded-md`}
            onClick={handleChangePaymentMethod}
          >
            Trocar forma de pagamento
          </button>
        </div>
      )}
      <button
        type="button"
        className={`bg-red-500 flex font-semibold justify-center text-sm p-2 text-contrastText w-full rounded-md ${
          alreadyCanceled ? 'hidden' : ''
        }`}
        onClick={handleCancelSubscription}
      >
        {isLoading ? <LoadingIcon /> : 'Cancelar inscrição'}
      </button>
    </div>
  );
};
