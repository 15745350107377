import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingIcon, maskPhone, mergeSchema } from '@onbeefapp/constants';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Countdown as LoyaltyCountdown } from '../../../../fidelidade/src/components/Countdown';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useLoyaltyPhoneValidation } from './hooks/useLoyaltyPhoneValidation';
import { useAuthStore } from '../../stores/login';
import { useNavigate } from 'react-router-dom';
interface LoyaltyPhoneForm {
  phone: string;
  code: string;
}

const validationSchema = mergeSchema(
  Yup.object().shape({
    phone: Yup.string().when(['$verifying'], {
      is: true,
      then: (s) => s.nullable(),
      otherwise: (s) =>
        s.required('Celular é obrigatório').min(14, 'Celular inválido'),
    }),
    code: Yup.string().when(['$verifying'], {
      is: true,
      then: (s) =>
        s
          .required('O código é obrigatório')
          .max(4, 'O código deve ter menos de 4 digitos'),
      otherwise: (s) => s.nullable(),
    }),
  }),
);

export const LoyaltyPhone: React.FC = () => {
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);

  const {
    verifyingToken,
    runCheckPhone,
    runVerifyPhone,
    isLoading,
    submitDisabled,
    setSubmitDisabled,
    submitReleasedAt,
    reset,
    phone,
  } = useLoyaltyPhoneValidation();

  const methods = useForm<LoyaltyPhoneForm>({
    resolver: yupResolver(validationSchema),
    context: { verifying: !!verifyingToken },
    defaultValues: {
      phone: user?.phone ? maskPhone(user.phone) : '',
    },
  });

  const onSubmit = async (data: LoyaltyPhoneForm) => {
    if (verifyingToken) {
      await runVerifyPhone(data);
    } else {
      await runCheckPhone(data);
    }
    methods.reset();
  };

  const handleBack = () => {
    reset();
    navigate(-1);
  };

  return (
    <div className="w-full h-screen p-2">
      <HeaderBar onClickBack={handleBack}>
        <span className="text-sm font-normal text-center w-full">
          Fidalidade
        </span>
      </HeaderBar>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-8 mx-auto max-w-md p-6 rounded-xl shadow-sm"
        >
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            {verifyingToken ? 'Verificação' : 'Primeiros passos'}
          </h2>

          {verifyingToken ? (
            <div className="w-full flex flex-col gap-3">
              <p className="text-sm font-medium text-gray-700">
                Enviamos um código de verificação para o número{' '}
                <span className="font-bold">{maskPhone(phone)}</span>
              </p>
              <label htmlFor="code" className="text-sm text-gray-600">
                Digite o código recebido por SMS
              </label>
              <input
                {...methods.register('code')}
                onChange={(e) => {
                  const v = e.target.value;
                  if (v.length > 4) return;
                  methods.setValue('code', v);
                }}
                value={methods.watch('code')}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-transparent transition-all"
                placeholder="0000"
              />
            </div>
          ) : (
            <div className="w-full flex flex-col gap-3">
              <label
                htmlFor="phone"
                className="text-sm font-medium text-gray-700"
              >
                Digite seu número de celular
              </label>
              <input
                {...methods.register('phone')}
                onChange={(e) => {
                  methods.setValue('phone', maskPhone(e.target.value));
                }}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-transparent transition-all"
                placeholder="(00) 00000-0000"
              />
            </div>
          )}

          {methods.formState.errors.phone && (
            <p className="mt-2 text-sm text-red-600">
              {methods.formState.errors.phone.message}
            </p>
          )}

          {methods.formState.errors.code && (
            <p className="mt-2 text-sm text-red-600">
              {methods.formState.errors.code.message}
            </p>
          )}

          <button
            type="submit"
            disabled={submitDisabled || isLoading}
            className="w-full mt-6 px-6 py-3 rounded-lg bg-primary text-white font-medium 
            hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed
            flex items-center justify-center"
          >
            {isLoading ? <LoadingIcon className="text-white" /> : 'Continuar'}
          </button>

          {submitDisabled && (
            <div className="mt-4 p-4 bg-yellow-100 rounded-lg">
              <p className="text-sm">
                Muitas tentativas, aguarde{' '}
                <LoyaltyCountdown
                  targetDate={submitReleasedAt}
                  onStop={() => setSubmitDisabled(false)}
                />{' '}
                e tente novamente.
              </p>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
