import { formatCurrency } from '@onbeefapp/constants';
import { CreateOrderResponse } from '../queries/order/types';
import { StatusType } from './constants';
import { mobileSize } from './constants';

export function isMobileDevice(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    'mobile',
    'android',
    'iphone',
    'ipad',
    'ipod',
    'blackberry',
    'windows phone',
  ];

  const isNarrowScreen = window.innerWidth < mobileSize.mobile;
  const isMobileUA = mobileKeywords.some((keyword) =>
    userAgent.includes(keyword),
  );
  return isNarrowScreen || isMobileUA;
}

export function statusPage(order: CreateOrderResponse | undefined): {
  title: string;
  message: string | undefined;
  status: string;
} {
  if (order === undefined) {
    return {
      title: 'Pedido com erro',
      message: 'Pedido não encontrado',
      status: StatusType.ERROR,
    };
  }

  let messageError = undefined;

  if (order.payment_codes?.error) {
    messageError = order.payment_codes?.error[0];
  }

  if (order.payment_codes.status === 'PENDING') {
    return {
      title: 'Aguardando pagamento',
      message: undefined,
      status: StatusType.PENDING,
    };
  } else if (order.payment_codes.status === 'PAID') {
    return {
      title: 'Pedido realizado!',
      message: undefined,
      status: StatusType.SUCCESS,
    };
  } else if (order.payment_codes.status === 'NOT_AUTHORIZED') {
    return {
      title: 'Pedido não autorizado',
      message: messageError,

      status: StatusType.ERROR,
    };
  } else if (order.payment_codes.status === 'FAILED') {
    return {
      title: 'Pedido com erro',
      message: messageError,

      status: StatusType.ERROR,
    };
  }

  if (order.message === 'Pedido registrado com sucesso') {
    return {
      title: 'Pedido realizado!',
      message: undefined,
      status: StatusType.SUCCESS,
    };
  }

  return {
    title: 'Pedido com erro',
    message: messageError,
    status: 'ERROR',
  };
}

export function getFreightCostType(
  cost_type: string | null,
  freight_amount: number,
  delivery_details: number | undefined,
) {
  switch (cost_type) {
    case 'TO_BE_DETERMINED':
      return 'À Combinar';
    case 'FREE':
      return 'Frete Grátis';
    case 'AMOUNT':
      return formatCurrency(freight_amount);
    default: {
      if (freight_amount == null || freight_amount?.toString() === '0') {
        if (delivery_details === 2) {
          return 'À combinar';
        } else {
          return 'Frete Grátis';
        }
      } else {
        return formatCurrency(freight_amount);
      }
    }
  }
}

export function getCurrentWeekDay(): string {
  const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const currentDate = new Date();
  const currentDayIndex = currentDate.getDay();

  return daysOfWeek[currentDayIndex] ?? 'AAA';
}

export const getBrowserInfo = (): object => {
  // Get the window size
  const width = window.innerWidth;
  const height = window.innerHeight;

  // Get the device type (mobile or desktop) based on the screen width
  const deviceType = width < 768 ? 'mobile' : 'desktop';

  // Get the user-agent string
  const userAgent = navigator.userAgent;

  // Get the OS name and version from the user-agent string using a regular expression
  const osMatch = userAgent.match(
    /(?:Windows|Mac OS X|Linux|Android|iOS|iPadOS) (?:NT )?([\d._]+)/,
  );
  const osName = osMatch ? osMatch[0] : 'Unknown';
  const osVersion = osMatch ? osMatch[1] : 'Unknown';

  // Get the screen orientation (portrait or landscape) from the window object
  const orientation =
    window.screen.orientation &&
    window.screen.orientation.type.startsWith('portrait')
      ? 'portrait'
      : 'landscape';

  // Get the browser version from the user-agent string using a regular expression
  const browserMatch = userAgent.match(
    /(?:Chrome|Firefox|Safari|Edge|Opera)\/([\d.]+)/,
  );
  const browserVersion = browserMatch ? browserMatch[1] : 'Unknown';

  // Return an object with all the information
  return {
    width,
    height,
    deviceType,
    userAgent,
    osName,
    osVersion,
    orientation,
    browserVersion,
  };
};

export const getStorage = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return localStorage;
  } catch (e) {
    return sessionStorage;
  }
};
