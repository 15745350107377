import { create } from 'zustand';
import { LoyaltyObject } from '../../../fidelidade/src/types/types';

interface LoyaltyPhoneStore {
  phone: string;
  verifyingToken: string;
  submitDisabled: boolean;
  submitReleasedAt: Date | undefined;
  loyaltyObject: LoyaltyObject | undefined;
  setPhone: (phone: string) => void;
  setVerifyingToken: (token: string) => void;
  setSubmitDisabled: (disabled: boolean) => void;
  setSubmitReleasedAt: (date: Date | undefined) => void;
  setLoyaltyObject: (loyaltyObject?: LoyaltyObject) => void;
  reset: () => void;
}

export const useLoyaltyPhoneStore = create<LoyaltyPhoneStore>((set) => ({
  phone: '',
  verifyingToken: '',
  submitDisabled: false,
  submitReleasedAt: undefined,
  loyaltyObject: undefined,
  setPhone: (phone) => set({ phone }),
  setVerifyingToken: (token) => set({ verifyingToken: token }),
  setSubmitDisabled: (disabled) => set({ submitDisabled: disabled }),
  setSubmitReleasedAt: (date) => set({ submitReleasedAt: date }),
  setLoyaltyObject: (loyaltyObject) => set({ loyaltyObject }),
  reset: () =>
    set({
      phone: '',
      verifyingToken: '',
      submitDisabled: false,
      submitReleasedAt: undefined,
      loyaltyObject: undefined,
    }),
}));
