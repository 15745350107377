import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import {
  LoyaltySubscriptionPayload,
  LoyaltySubscriptionResponse,
} from './types';
import { LoginResponse } from '../login/types';
import { LoyaltyObject } from '../../../../fidelidade/src/types/types';
import axios from 'axios';

export const createLoyaltySubscription = async (
  payload: LoyaltySubscriptionPayload,
) => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await api.post<LoyaltySubscriptionResponse>(
    '/all/customers/loyalty/subscription',
    payload,
    {
      headers: accessToken
        ? {
            Authorization: 'Bearer: ' + accessToken,
          }
        : {},
    },
  );
  return data;
};

export const refreshLoyaltyData = async () => {
  const { data } =
    await api.get<LoginResponse['loyalty_programmes']>('/customers/loyalty');
  return data;
};

export const loyaltyCheckPhone = async ({
  slug,
  phone,
}: {
  slug: string;
  phone: string;
}) => {
  const baseURL = import.meta.env.VITE_REACT_APP_API_URL;
  const res = axios.get<LoyaltyObject>(
    `${baseURL}/all/customers/loyalty/${slug}/customers/${phone}/check`,
  );
  return res;
};

export const loyaltyVerifyPhone = async ({
  slug,
  phone,
  code,
  token,
}: {
  slug: string;
  phone: string;
  code: string;
  token: string;
}) => {
  const res = await api.post(
    `/all/merchants/${slug}/customers/${phone}/verify/code`,
    { code, token },
  );
  return res;
};

export function useCreateLoyaltySubscription() {
  return useMutation(['createLoyaltySubscription'], createLoyaltySubscription);
}

export function useRefreshLoyaltyData() {
  return useMutation(['useRefreshLoyaltyData'], refreshLoyaltyData);
}

export function useLoyaltyCheckPhone() {
  return useMutation(['loyaltyCheckPhone'], loyaltyCheckPhone);
}

export function useLoyaltyVerifyPhone() {
  return useMutation(['loyaltyVerifyPhone'], loyaltyVerifyPhone);
}
