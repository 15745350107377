import Checkout from '@onbeef/components/checkout';
import { NotFound } from '@onbeef/components/sharedPages';
import { ValidateCents } from '@onbeef/components/validateCents';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { CheckoutLinkSuccess } from './components/CheckoutLinkSuccess';
import PixPayment from './components/PixPayment';
import StatusPage from './components/StatusPage';
import { OrderDetailsCtxProvider } from './contexts/OrderDetailsContext';
import { Address } from './pages/Cart/Address/address';
import Cart from './pages/Cart/Cart';
import Coupons from './pages/Cart/Coupons/Coupons';
import AllSchedulings from './pages/Cart/Scheduling/AllSchedulings';
import Category from './pages/Category/Category';
import { Club } from './pages/Club';
import { ChangeClubPaymentMethod } from './pages/Club/ChangePaymentMethod';
import { ClubPreview } from './pages/Club/Preview';
import { ClubRegister } from './pages/Club/Register';
import { CRMPhoneValidation } from './pages/CRM/phoneValidation';
import CustomerResetPassword from './pages/CustomerResetPassword/CustomerResetPassword';
import Details from './pages/Details/Details';
import Entrypoint from './pages/Entrypoint/Entrypoint';
import ErrorPage from './pages/Error/ErrorJs';
import ErrorNotFound from './pages/Error/ErrorNotFound';
import ErrorWithoutSlug from './pages/Error/ErrorWithoutSlug';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home/Home';
import { Login } from './pages/Login';
import { LoyaltyClubRegister, LoyaltyDetail } from './pages/LoyaltyClub';
import { LoyaltyPhone } from './pages/LoyaltyClub/LoyaltyPhone';
import { LoyaltyPreview } from './pages/LoyaltyClub/LoyaltyPreview';
import RateOrder from './pages/Orders/Assessment/RateOrder';
import UnloggedRating from './pages/Orders/Assessment/UnloggedRating';
import OrderDetailInterface from './pages/Orders/Detail/OrderDetail';
import MyOrders from './pages/Orders/MyOrders';
import OrderEntrypoint from './pages/Orders/OrderEntrypoint';
import Product from './pages/Product/Product';
import { ProfileAddress } from './pages/Profile/items/Address/ProfileAddress';
import { ProfileInfo } from './pages/Profile/items/ProfileInfo';
import { ProfilePassword } from './pages/Profile/items/ProfilePassword';
import { Profile } from './pages/Profile/Profile';
import Pwa from './pages/Pwa/Pwa';
import { Signup } from './pages/Signup';
import { useValidateCents } from './queries/payment-card';
import { NewCreditCard } from './pages/Checkout/NewCreditCard';
import { FilterPage } from './pages/Filter/FilterPage';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <Entrypoint />
        </Sentry.ErrorBoundary>
      ),
      children: [
        {
          path: '/',
          element: (
            <OrderDetailsCtxProvider>
              <Home />,
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/signup',
          element: <Signup />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/profile',
          element: <Profile />,
        },
        {
          path: '/profile/info',
          element: <ProfileInfo />,
        },
        {
          path: '/profile/password',
          element: <ProfilePassword />,
        },
        {
          path: '/profile/address',
          element: <ProfileAddress />,
        },
        {
          path: '/club/preview',
          element: <ClubPreview />,
        },
        {
          path: '/club/:club_id',
          element: <ClubRegister />,
        },
        {
          path: '/club/payment-method/:club_id',
          element: <ChangeClubPaymentMethod />,
        },
        {
          path: '/club',
          element: <Club />,
        },
        {
          path: '/pwa',
          element: <Pwa />,
        },
        {
          path: '/search',
          element: <FilterPage />,
        },
        {
          path: '/category/:name',
          element: <Category />,
        },
        {
          path: '/product/:name/:brand?',
          element: <Product />,
        },
        {
          path: '/details',
          element: <Details />,
        },
        {
          path: '/cart',
          element: (
            <OrderDetailsCtxProvider>
              <Cart />
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/cart/address',
          element: <Address />,
        },
        {
          path: '/coupon',
          element: (
            <OrderDetailsCtxProvider>
              <Coupons />
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/schedule',
          element: (
            <OrderDetailsCtxProvider>
              <AllSchedulings />
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/card/new',
          element: <NewCreditCard />,
        },
        {
          path: '/checkout/:id?/:code?',
          element: (
            <OrderDetailsCtxProvider>
              <Checkout />
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/checkout/pix/:id',
          element: <PixPayment />,
        },
        {
          path: '/checkout/success',
          element: <CheckoutLinkSuccess />,
        },
        {
          path: '/checkout/:orderid/validate-cents/:code',
          element: <ValidateCents validateCents={useValidateCents()} />,
        },
        {
          path: '/status/:id',
          element: (
            <OrderDetailsCtxProvider>
              <StatusPage />
            </OrderDetailsCtxProvider>
          ),
        },
        {
          path: '/validate/phone/',
          element: <CRMPhoneValidation />,
        },
        {
          path: '/loyalty/detail',
          element: <LoyaltyDetail />,
        },
        {
          path: '/loyalty',
          element: <LoyaltyPreview />,
        },
        {
          path: '/loyalty/phone',
          element: <LoyaltyPhone />,
        },
        {
          path: '/loyalty/register/:phone?/:token?/',
          element: <LoyaltyClubRegister />,
        },
        {
          path: '/customer/reset-password/:email/:token',
          element: <CustomerResetPassword />,
        },
        {
          path: '/orders',
          element: <OrderEntrypoint />,
          children: [
            {
              path: '/orders',
              element: <MyOrders />,
            },
            {
              path: '/orders/:id',
              element: <OrderDetailInterface />,
            },
            {
              path: '/orders/:id/rate',
              element: <RateOrder />,
            },
          ],
        },
        {
          path: '/orders/:code/:phone',
          element: <UnloggedRating />,
        },
      ],
    },
    {
      path: '/not-found',
      element: <ErrorNotFound />,
    },
    {
      path: '/',
      element: <ErrorWithoutSlug />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
