import { ScaleIcon } from '@heroicons/react/24/outline';
import {
  DeliveryType,
  PaymentMethodType,
  currencyFormat,
  formatCurrency,
} from '@onbeefapp/constants';
import * as React from 'react';
import { OrderDetailsContext } from '../../../contexts/OrderDetailsContext';
import { useSlug } from '../../../hooks/useSlug';
import { useMerchantCache } from '../../../queries/home';
import { useCartStore } from '../../../stores/cart';
import { useMerchantStore } from '../../../stores/merchant';
import { useOrderDetails } from '../../../stores/order-details';
import { getMarginTax, showCouponDiscount } from '../../../utils/CartUtils';
import Skeleton from '../../Skeleton/Skeleton';

export const CheckoutSummary: React.FC = () => {
  const { coupon, crmBonusDiscount } = useCartStore((state) => ({
    coupon: state.coupon,
    crmBonusDiscount: state.crmBonusDiscount,
  }));

  const { deliveryType } = useOrderDetails((state) => {
    return {
      deliveryType: state.deliveryType,
    };
  });

  const { slug } = useSlug();

  const { isLoading: isLoadingMerchant } = useMerchantCache(slug);
  const { loyalty_programme } = useMerchantStore((state) => ({
    loyalty_programme: state.loyalty_programme,
  }));

  const {
    frete,
    withFreteGratisDiscount,
    subtotalFormatted,
    valueFeesFormatted,
    totalWithFees,
    withFees,
    subtotal,
    subtotalWithDiscount,
    hasProductPerKilo,
    discountFormatted,
    hasClubDiscount,
    userClubDiscount,
    userClubDiscountFormatted,
    loyaltyRewardFormatted,
    loyaltyGiftFormatted,
    marginValue,
  } = React.useContext(OrderDetailsContext);

  const { selectedPaymentMethod } = useOrderDetails((state) => ({
    selectedPaymentMethod: state.selectedPaymentMethod,
  }));

  const hasMargin = marginValue || getMarginTax() > 0;

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full space-y-1 border-b-[1px] pb-2 mt-2 rounded-lg py-2">
        <Skeleton isLoading={isLoadingMerchant} className="w-32 h-4 rounded-lg">
          <div className="text-base font-semibold text-left mb-2">
            Resumo de valores
          </div>
        </Skeleton>

        <div className="flex flex-row justify-between text-sm font-normal text-left w-full">
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-16 h-4 rounded-lg"
          >
            <div>Subtotal</div>
          </Skeleton>
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-12 h-4 rounded-lg"
          >
            {subtotalFormatted}
          </Skeleton>
        </div>
        {deliveryType === DeliveryType.DELIVERY && (
          <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Taxa de entrega</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {withFreteGratisDiscount ? (
                  <>
                    <s>{frete}</s>{' '}
                    <span className="text-green-600">Grátis</span>
                  </>
                ) : (
                  frete
                )}
              </div>
            </Skeleton>
          </div>
        )}
        {crmBonusDiscount > 0 && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Cashback</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{formatCurrency(crmBonusDiscount)}</div>
            </Skeleton>
          </div>
        )}
        {subtotal !== subtotalWithDiscount && coupon && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Desconto (cupom)</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {coupon
                  ? showCouponDiscount(coupon, subtotal - userClubDiscount)
                  : discountFormatted}
              </div>
            </Skeleton>
          </div>
        )}
        {hasClubDiscount && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>Desconto do clube</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{userClubDiscountFormatted}</div>
            </Skeleton>
          </div>
        )}
        {loyaltyRewardFormatted && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>{loyalty_programme?.name}</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{loyaltyRewardFormatted}</div>
            </Skeleton>
          </div>
        )}
        {loyaltyGiftFormatted && (
          <div className="flex flex-row justify-between text-sm text-green-600 font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-10 h-4 rounded-lg"
            >
              <div>{loyalty_programme?.name} para novos clientes</div>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>{loyaltyGiftFormatted}</div>
            </Skeleton>
          </div>
        )}
        {(selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
          selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX) &&
          withFees && (
            <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-20 h-4 rounded-lg"
              >
                <div>Taxa de serviço</div>
              </Skeleton>
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-12 h-4 rounded-lg"
              >
                <div>{valueFeesFormatted}</div>
              </Skeleton>
            </div>
          )}
        {hasMargin && (
          <div className="flex flex-row justify-between text-sm text-[#787878] font-normal text-left w-full">
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-20 h-4 rounded-lg"
            >
              <div>Margem de variação de peso</div>
            </Skeleton>
            <Skeleton
              isLoading={isLoadingMerchant}
              className="w-12 h-4 rounded-lg"
            >
              <div>
                {marginValue != null
                  ? currencyFormat(marginValue)
                  : currencyFormat(getMarginTax())}
              </div>
            </Skeleton>
          </div>
        )}
        <div className="flex flex-row justify-between text-sm font-semibold text-left w-full">
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-10 h-4 rounded-lg"
          >
            <div className="flex items-center">
              <span className="mr-1">{`Total${
                hasProductPerKilo ? ' estimado' : ''
              }`}</span>
            </div>
          </Skeleton>

          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-12 h-4 rounded-lg"
          >
            <div>{currencyFormat(totalWithFees)}</div>
          </Skeleton>
        </div>

        {hasProductPerKilo && (
          <Skeleton
            isLoading={isLoadingMerchant}
            className="w-full h-12 rounded-lg"
          >
            <div className="!mt-2 rounded-lg overflow-hidden border border-gray-200 shadow-sm bg-white">
              <div className="flex items-center px-3 py-2 border-b border-gray-100 bg-primary bg-opacity-10">
                <ScaleIcon className="text-contrastText stroke-2 mr-2 flex-shrink-0 w-4 h-4" />
                <p className="font-medium text-xs text-contrastText">
                  Valor sujeito a alteração
                </p>
                <span className="ml-auto text-[10px] text-contrastText bg-primary bg-opacity-20 px-2 py-0.5 rounded-full">
                  Produtos por peso
                </span>
              </div>
              <div className="p-2">
                <p className="text-xs leading-relaxed text-gray-600 mb-2">
                  Os produtos vendidos por peso terão seu valor final ajustado
                  após a pesagem no momento do envio.
                </p>
                {hasMargin && (
                  <>
                    <div className="flex items-center mt-2 text-xs text-gray-600">
                      <div className="bg-primary bg-opacity-10 rounded-full w-4 h-4 flex items-center justify-center text-contrastText mr-2 flex-shrink-0 text-[10px] font-medium">
                        1
                      </div>
                      <p>
                        A margem de variação já está incluída no total acima.
                      </p>
                    </div>
                    <div className="flex items-center mt-2 text-xs text-gray-600">
                      <div className="bg-primary bg-opacity-10 rounded-full w-4 h-4 flex items-center justify-center text-contrastText mr-2 flex-shrink-0 text-[10px] font-medium">
                        2
                      </div>
                      <p>
                        Se o peso for menor que o estimado, a diferença será
                        estornada.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Skeleton>
        )}
      </div>
    </>
  );
};
