import cx from 'classnames';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useCartStore } from '../../stores/cart';
import Skeleton from '../Skeleton/Skeleton';
import { TrackingContext } from '../../contexts/TrackingContext';

interface HeaderBarProps {
  children?: React.ReactNode;
  backToLastPage?: boolean;
  backToMainPage?: boolean;
  forceDisplayGoBack?: boolean;
  onClickBack?: () => void;
  className?: string;
  colorButton?: string;
}

const HeaderBar: React.FC<HeaderBarProps> = ({
  children,
  backToMainPage,
  backToLastPage = true,
  forceDisplayGoBack = false,
  className,
  colorButton,
  onClickBack,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = React.useContext(TrackingContext);

  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink);

  const isMerchantDetailsPage = location?.pathname?.includes('/details');
  const isCart = location?.pathname?.includes('/cart');
  const isProduct = location?.pathname?.includes('/product');
  const isCheckout = location?.pathname?.includes('/checkout');
  const isCategory = location?.pathname?.includes('/category');
  const isCouponPage = location?.pathname?.includes('/coupon');

  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const navigateBack = () => {
    if (onClickBack) {
      return onClickBack();
    }

    if (isMerchantDetailsPage) {
      track(`OnCustomerLeaveMerchantDetails`, {});
    }
    if (isCart) {
      track(`OnCustomerLeaveCart`, {});
    }
    if (isProduct) {
      track(`OnCustomerLeaveProductPage`, {});
    }
    if (isCheckout) {
      track(`OnCustomerLeaveCheckout`, {});
      track(`OnCustomerViewCart`, {});
    }
    if (isCategory) {
      track(`OnCustomerLeaveCategory`, {});
    }
    if (isCouponPage) {
      track(`OnCustomerLeaveCouponPage`, {});
      track(`OnCustomerViewCart`, {});
    }

    if (backToMainPage) {
      return navigate(`/`);
    }

    if (backToLastPage) {
      return navigate(-1);
    }
  };

  return (
    <React.Fragment>
      <div
        className={cx(
          `flex flex-row justify-between w-full mb-4 space-y-1`,
          className,
        )}
      >
        <Skeleton
          width="30px"
          height="30px"
          isLoading={isLoading}
          borderRadius="10px"
        >
          {(!isCheckoutLink || forceDisplayGoBack) && (
            <button
              type="button"
              onClick={navigateBack}
              className={cx(`p-[2px] rounded-full`, colorButton)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </Skeleton>
        {children && (
          <Skeleton
            width="100%"
            height="20px"
            className="ml-2 mr-2"
            isLoading={isLoading}
            borderRadius="10px"
          >
            {children}
          </Skeleton>
        )}
      </div>
    </React.Fragment>
  );
};

export default HeaderBar;
