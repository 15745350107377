import cx from 'classnames'
import React from 'react'
import {
  FieldValues,
  RegisterOptions,
  useFormContext,
  FieldError,
} from 'react-hook-form'
import styled from 'styled-components'
import { getValueByKey } from './utils'

export interface InputPayload
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string
  className?: string
  customLabel?: string
  type?: string
  customLabelWidth?: string
  placeholder?: string
  name: string
  validationSchema?: RegisterOptions<FieldValues, string> | undefined
  shrink?: boolean
  ref?: React.LegacyRef<HTMLInputElement>
  icon?: JSX.Element
  endIcon?: JSX.Element
  error?: boolean
  isFromLogin?: boolean
  InputAdornment?: JSX.Element
}

const Input = styled.input`
  background-color: #fff;
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`

export const TextInput: React.FC<InputPayload> = ({
  containerClassName = 'w-full',
  className,
  name,
  type = 'text',
  placeholder,
  icon,
  endIcon,
  validationSchema,
  customLabel,
  customLabelWidth = 'w-full',
  error,
  isFromLogin,
  InputAdornment,
  required,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  // if custom error boolean is present then don't display auto field error
  const fieldError = getValueByKey(errors, name) as FieldError | undefined
  const isFieldError = !error && fieldError && fieldError.message

  return (
    <div className={cx(containerClassName, customLabel && 'flex flex-col')}>
      <label
        htmlFor={name}
        className={cx(
          'mb-2 text-left text-sm font-medium text-gray-500',
          customLabelWidth ? customLabelWidth : 'w-full',
          error || errors?.message ? 'text-red-400' : ''
        )}
      >
        {customLabel}
        {required && customLabel && (
          <span className="text-red-400 ml-1">*</span>
        )}
      </label>
      <div className="relative">
        <div className="absolute top-0 bottom-0 flex items-center justify-center">
          {icon}
        </div>
        <Input
          id={name}
          type={type}
          placeholder={placeholder}
          role="presentation"
          className={cx(
            `${
              !isFromLogin ? `rounded-md` : ''
            } border border-gray-400 py-1 disabled:bg-gray-100 ${
              icon ? 'pl-10' : 'pl-3'
            }`,
            isFieldError || error ? ' border-red-400' : '',
            type === 'radio' || type === 'checkbox'
              ? 'w-5 h-5 items-center flex'
              : 'w-full',
            className
          )}
          required={required}
          {...register?.(name, validationSchema ? validationSchema : {})}
          {...rest}
        />
        <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center">
          {endIcon}
        </div>
        {InputAdornment && (
          <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center">
            {InputAdornment}
          </div>
        )}
      </div>
      {isFieldError && (
        <span className="text-red-400 text-sm">{fieldError?.message}</span>
      )}
    </div>
  )
}

TextInput.displayName = 'TextInput'
export type TextInputProps = InputPayload
export default TextInput
