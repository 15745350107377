import { Address, censorCreditCard, Gender } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getStorage } from '../utils/function';
import { Card, CardPayload } from '../queries/payment-card/types';
import { LoginResponse } from '../queries/login/types';

export interface LocalCard {
  id: string;
  formatted: {
    brand: string;
    number: string;
  };
  payload: CardPayload;
}

type UserStore = {
  customer_id: string;
  name: string;
  phone: string;
  email: string;
  taxpayer_id_number: string;
  birthday?: string;
  gender?: Gender;
  ageGroup?: string;
  money_back: string;
  cards: Card[];
  local_cards: LocalCard[];
  setCustomerId: (customer_id: string) => void;
  setName: (name?: string) => void;
  setEmail: (email?: string) => void;
  setPhone: (phone?: string) => void;
  setTaxPayerIdNumber: (taxpayer_id_number?: string) => void;
  setBirthday: (birthday?: string) => void;
  setGender: (gender?: Gender) => void;
  setAgeGroup: (ageGroup?: string) => void;
  setMoneyBack: (money_back: string) => void;
  address?: Address;
  addresses?: Address[];
  setAddress: (address?: Address) => void;
  setAddresses: (addresses?: Address[]) => void;
  setCards: (cards: Card[]) => void;
  addCard: (card: Card) => void;
  newLocalCard: (payload: CardPayload, brand: string) => string;
  removeCard: (id: string) => void;
  removeLocalCard: (id: string) => void;
  set: (login: LoginResponse) => void;
  clear: () => void;
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      customer_id: '',
      name: '',
      phone: '',
      email: '',
      taxpayer_id_number: '',
      birthday: '',
      gender: '' as Gender,
      merchant_id: '',
      money_back: '',
      ageGroup: '',
      address: undefined,
      addresses: undefined,
      cards: [],
      local_cards: [],
      setCustomerId(customer_id) {
        set(() => ({ customer_id: customer_id }));
      },
      setAddress(address) {
        set(() => ({ address: address }));
      },
      setAddresses(addresses) {
        set(() => ({ addresses: addresses }));
      },
      setName(name) {
        set(() => ({ name: name }));
      },
      setEmail(email) {
        set(() => ({ email: email }));
      },
      setPhone(phone) {
        set(() => ({ phone: phone }));
      },
      setTaxPayerIdNumber(taxpayer_id_number) {
        set(() => ({ taxpayer_id_number: taxpayer_id_number }));
      },
      setBirthday(birthday) {
        set(() => ({ birthday }));
      },
      setGender(gender) {
        set(() => ({ gender }));
      },
      setMoneyBack(money_back) {
        set(() => ({ money_back: money_back }));
      },
      setAgeGroup(ageGroup) {
        set(() => ({ ageGroup }));
      },
      setCards(cards) {
        set(() => ({ cards }));
      },
      addCard(card) {
        set((prev) => ({
          cards: [...prev.cards, card],
        }));
      },
      removeCard(id) {
        set((prev) => ({
          cards: prev.cards.filter((card) => card.id !== id),
        }));
      },
      newLocalCard(payload, brand) {
        const id = Math.random().toString(36).substring(7);
        const capitalize = (str: string): string =>
          str?.[0]?.toUpperCase() + str.slice(1) || '';
        const newCard: LocalCard = {
          id,
          formatted: {
            brand: capitalize(brand),
            number: censorCreditCard(payload.number),
          },
          payload,
        };
        set((prev) => ({
          local_cards: [...prev.local_cards, newCard],
        }));
        return id;
      },
      removeLocalCard(id) {
        set((prev) => ({
          local_cards: prev.local_cards.filter((card) => card.id !== id),
        }));
      },
      set(login) {
        if (login) {
          const user: Partial<UserStore> = {};
          if (login.name) user.name = login.name;
          if (login.email) user.email = login.email;
          if (login.taxpayer_id_number)
            user.taxpayer_id_number = login.taxpayer_id_number;
          if (login.phone) user.phone = login.phone;
          if (login.id) user.customer_id = login.id;
          if (login.birthday) user.birthday = login.birthday;
          if (login.gender) user.gender = login.gender as Gender;
          set({
            ...user,
          });
        }
      },
      clear() {
        set({
          name: '',
          phone: '',
          email: '',
          taxpayer_id_number: '',
          address: undefined,
          money_back: '',
          customer_id: '',
          birthday: '',
          gender: '' as Gender,
          ageGroup: '',
          cards: [],
          local_cards: [],
        });
      },
    }),
    {
      name: 'user',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['cards', 'local_cards'].includes(key),
          ),
        ),
      storage: createJSONStorage(() => getStorage()),
      version: 1,
    },
  ),
);
