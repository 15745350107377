import { Toast } from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingContext } from '../contexts/TrackingContext';
import { useSlug } from '../hooks/useSlug';
import { useMerchantCache } from '../queries/home';
import { useCartStore } from '../stores/cart';
import { useMerchantStore } from '../stores/merchant';
import Skeleton from './Skeleton/Skeleton';

const CartButton: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const { productsCountIndividual } = useCartStore((state) => ({
    productsCountIndividual: state.productsCountIndividual,
  }));

  const { isMerchantOpen } = useMerchantStore((state) => ({
    isMerchantOpen: state.isMerchantOpen,
  }));
  const { track } = React.useContext(TrackingContext);

  const navigate = useNavigate();

  return (
    <Skeleton className="h-8 w-14" borderRadius="40px" isLoading={isLoading}>
      <div className="fixed w-full lg:w-3/5 xl:w-3/4 bottom-12 z-[9998]">
        <div className="w-full flex items-center justify-center space-x-2">
          <button
            id="cart-button"
            className={`flex items-center text-contrastText text-sm font-semibold justify-center hover:drop-shadow-xl flex-row px-6 sm:px-10 lg:px-20 z-[99999] space-x-4 rounded-full py-2 bg-secondary shadow-2xl drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]`}
            onClick={() => {
              if (!isMerchantOpen) {
                return Toast.error(
                  'Loja fechada, não é possível adicionar produtos na sacola',
                );
              }

              if (productsCountIndividual <= 0) {
                Toast.error('Sua sacola está vazia');
                return;
              }
              track(`OnCustomerViewCart`, {
                items: productsCountIndividual,
              });
              navigate(`/cart`);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 0.75H1C0.447715 0.75 0 1.19772 0 1.75V2.25C0 2.80228 0.447715 3.25 1 3.25H3.0119L5.73626 14.7312C6.18267 16.6125 7.84065 17.9508 9.76471 17.9987V18H17.5874C19.5362 18 21.2014 16.5956 21.5301 14.6747L22.7857 7.33734C22.9947 6.11571 22.0537 5 20.8143 5H5.99657L5.21623 1.7114C5.08251 1.14787 4.57918 0.75 4 0.75Z"
                fill="currentColor"
              />
              <path
                d="M10 21C10 22.1046 9.10457 23 8 23C6.89543 23 6 22.1046 6 21C6 19.8954 6.89543 19 8 19C9.10457 19 10 19.8954 10 21Z"
                fill="currentColor"
              />
              <path
                d="M21 21C21 22.1046 20.1046 23 19 23C17.8954 23 17 22.1046 17 21C17 19.8954 17.8954 19 19 19C20.1046 19 21 19.8954 21 21Z"
                fill="currentColor"
              />
            </svg>
            <div>{productsCountIndividual}</div>
            <div>Ver carrinho</div>
          </button>
        </div>
      </div>
    </Skeleton>
  );
};

export default CartButton;
