import { XMarkIcon } from '@heroicons/react/24/outline';
import { FiltersKeys, SelectedFilter } from '@onbeefapp/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useDrawerStore } from '../../stores/drawer';
import { useProductsStore } from '../../stores/products';
import { useMerchantStore } from '../../stores/merchant';

interface FilterButtonProps {
  label: React.ReactNode | string;
  filterKey: FiltersKeys;
}

const FilterButton: React.FC<FilterButtonProps> = ({ label, filterKey }) => {
  const filters = useMerchantStore((state) => state.filters);

  const { selectedFilters, removeSelectedFilter, filterProducts } =
    useProductsStore((state) => ({
      selectedFilters: state.filters,
      removeSelectedFilter: state.removeSelectedFilter,
      filterProducts: state.filterProducts,
    }));

  const { setFilterType, toggleDrawer } = useDrawerStore((state) => ({
    setFilterType: state.setSelectedFilterKey,
    toggleDrawer: state.toggleDrawer,
  }));

  const handleClick = () => {
    setFilterType(filterKey);
    toggleDrawer('drawerFilters');
  };

  const handleRemoveFilter = (selectedFilter: SelectedFilter) => {
    removeSelectedFilter(selectedFilter);
    filterProducts();
  };

  const selectedFilter = selectedFilters.find(
    (filter) => filter.key === filterKey,
  );

  if (!filters?.[filterKey] || filters?.[filterKey]?.content?.length === 0)
    return null;

  return selectedFilter ? (
    <div
      className={`whitespace-nowrap py-1 px-2 rounded-md text-sm font-medium bg-primary text-contrastText flex items-center gap-1`}
    >
      {selectedFilter.name}
      <button onClick={() => handleRemoveFilter(selectedFilter)} type="button">
        <XMarkIcon className="w-4 h-4 stroke-2 text-contrastText" />
      </button>
    </div>
  ) : (
    <button
      onClick={handleClick}
      type="button"
      className={`whitespace-nowrap py-1 px-2 rounded-md text-sm font-medium bg-gray-50 text-gray-700 border border-gray-200 hover:bg-gray-100`}
    >
      {label}
    </button>
  );
};

export const FilterSelector: React.FC = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="w-full flex items-center justify-start">
        <div className="w-full flex items-center gap-1 overflow-x-scroll">
          {/* <FilterButton
            label={<ArrowsUpDownIcon className="w-4 h-4" />}
          /> */}

          <FilterButton
            label="Como quer o corte?"
            filterKey={FiltersKeys.CUTS}
          />

          <FilterButton
            label="Onde vai preparar?"
            filterKey={FiltersKeys.PRODUCT_PREPARATION_MODE}
          />

          <FilterButton
            label="Qual a ocasião?"
            filterKey={FiltersKeys.PRODUCT_EVENT_TYPE}
          />
          <FilterButton
            label="Tipo de produto?"
            filterKey={FiltersKeys.PRODUCT_STORAGE}
          />
        </div>
      </div>
    </FormProvider>
  );
};
