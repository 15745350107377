import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const spUnixTimestamp = () => {
  const spTime = utcToZonedTime(new Date(), 'America/Sao_Paulo')
  return Math.floor(spTime.getTime() / 1000)
}

export const timerDuration = (seconds: number) => {
  return format(new Date(seconds * 1000), 'mm:ss')
}

export const diff = (target: Date, current: Date) => {
  return {
    days: differenceInDays(target, current),
    hours: differenceInHours(target, current) % 24,
    minutes: differenceInMinutes(target, current) % 60,
    seconds: differenceInSeconds(target, current) % 60,
    totalMs: differenceInMilliseconds(target, current),
  }
}

export const isNowBetweenTimespan = (start: string, end: string): boolean => {
  const [startHour, startMin] = start.split(':').map(Number)
  const [endHour, endMin] = end.split(':').map(Number)
  const [currHour, currMin] = [new Date().getHours(), new Date().getMinutes()]

  if (
    startHour === undefined ||
    startMin === undefined ||
    endHour === undefined ||
    endMin === undefined
  )
    return false

  if (startHour === currHour ? startMin > currMin : startHour > currHour) {
    return false
  }
  if (endHour === currHour ? endMin < currMin : endHour < currHour) {
    return false
  }

  return true
}

export const isNowBetweenDates = (start: string, end: string): boolean => {
  const normalizeDate = (dateStr: string): Date | null => {
    const parts = dateStr.split('-')
    if (parts.length !== 3) return null
    const [year, month, day] = parts.map(Number)
    if (isNaN(year!) || isNaN(month!) || isNaN(day!)) return null

    return new Date(year!, month! - 1, day!)
  }

  const now = new Date()
  const todayMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  )

  const startDate = normalizeDate(start)
  const endDate = normalizeDate(end)

  if (startDate === null || endDate === null)
    throw new Error('Invalid date format')

  return todayMidnight >= startDate && todayMidnight <= endDate
}

export const isDateExpired = (date: string) => {
  return diff(new Date(date), new Date()).totalMs <= 0
}
