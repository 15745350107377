import { Complement, Filters, Item } from '@onbeefapp/constants';
import { ProductCartType } from './cart';
import { create } from 'zustand';

interface DrawerState {
  isOpen: boolean;
  key?: string;
  product: ProductCartType;
  cutID: string;
  selectedFilterKey?: keyof Filters;
  complement: Complement;
  complementItem: Item;
}

interface DrawerStore extends DrawerState {
  toggleDrawer: (key: string) => void;
  setProductDrawer: (product: ProductCartType) => void;
  setCutID: (cutID: string) => void;
  setSelectedFilterKey: (filterType?: keyof Filters) => void;
  setComplement: (complement: Complement) => void;
  setComplementItem: (complementItem: Item) => void;
}

export const useDrawerStore = create<DrawerStore>((set, get) => ({
  isOpen: false,
  key: undefined,
  product: {} as ProductCartType,
  cutID: '',
  selectedFilterKey: undefined,
  complementItem: {} as Item,
  complement: {} as Complement,
  toggleDrawer: (key) => {
    const open = !get().isOpen;
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    set({ isOpen: open, key, cutID: '' });
  },
  setProductDrawer: (product: ProductCartType) => set({ product }),
  setCutID(cutID) {
    set({ cutID });
  },
  setSelectedFilterKey(filterType) {
    set({ selectedFilterKey: filterType });
  },
  setComplement: (complement: Complement) => set({ complement }),
  setComplementItem: (complementItem: Item) => set({ complementItem }),
}));
