import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isToday from 'date-fns/isToday'
import ptBR from 'date-fns/locale/pt-BR'
import { parseISO } from 'date-fns'

export const formatOrderTime = (date: string, especific = false) => {
  const orderDate = new Date(date)

  return isToday(orderDate) && !especific
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: ptBR,
      })
    : format(new Date(orderDate), "dd/MM/yy 'às' HH:mm'h'", { locale: ptBR })
}

export const formatStringDate = (date: string) => {
  return format(new Date(date), 'dd/MM/yy', { locale: ptBR })
}

export const formatStringToDate = (date: string) => {
  return parseISO(date)
}

export function formatDateBanner(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const formatPayloadDateTime = (date?: string | null) => {
  if (!date) return null
  const fullDate = new Date(date)
  return format(fullDate, 'yyyy-MM-dd HH:mm')
}
