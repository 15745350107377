import { BanknotesIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import {
  LoadingIcon,
  Toast,
  currencyFormat,
  currencyToFloat,
  debounce,
  formatCurrency,
  maskPhone,
} from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Slide } from 'react-toastify';
import Swal from 'sweetalert2';
import FormCheckout from '../../components/Forms/Checkout';
import LoadingSpin from '../../components/LoadingSpin';
import { CartRelatedProductsModal } from '../../components/Modals/CartRelatedProductsModal';
import Skeleton from '../../components/Skeleton/Skeleton';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { useCRMGetBonusDetails } from '../../queries/crm';
import { useMerchantCache } from '../../queries/home';
import { useGetSchedulings } from '../../queries/order';
import { useGetRelatedProductsList } from '../../queries/related';
import { useCartStore } from '../../stores/cart';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useOrderDetails } from '../../stores/order-details';
import { useUserStore } from '../../stores/user';
import { getMarginTax, getRestFreeFreigth } from '../../utils/CartUtils';
import { DeliveryType, StepType } from '../../utils/constants';

interface Props {
  showSummary?: boolean;
  onClickContinue?: () => void;
  orderId?: string;
  extensionCustomCouponCode?: string;
  extensionCustomDeliveryFee?: number | string;
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const CartSummary: React.FC<Props> = ({
  showSummary = true,
  onClickContinue,
  orderId,
  extensionCustomCouponCode,
  extensionCustomDeliveryFee,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const params = useSlug();
  const location = useLocation();
  const isExtension = useCartStore((state) => state.isExtension);
  const [open, setOpen] = React.useState(false);
  const [relatedProductsOpen, setRelatedProductsOpen] = React.useState(false);
  const navigate = useNavigate();
  const [percentageProgress, setPercentageProgress] = React.useState('0%');
  const [toOpenCrmDetail, setToOpenCrmDetail] = React.useState(false);
  const { restFreeFreigth, percentage } = getRestFreeFreigth();
  const {
    productsCountIndividual,
    coupon,
    setCoupon,
    deliveryTime,
    crmBonusData,
    setCrmBonusData,
    setCrmBonusDiscount,
  } = useCartStore((state) => ({
    deliveryTime: state.deliveryTime,
    productsCountIndividual: state.productsCountIndividual,
    coupon: state.coupon,
    setCoupon: state.setCoupon,
    crmBonusData: state.crmBonusData,
    setCrmBonusData: state.setCrmBonusData,
    setCrmBonusDiscount: state.setCrmBonusDiscount,
  }));
  const { deliveryType, setCurrentStep, currentStep } = useOrderDetails(
    (state) => ({
      deliveryType: state.deliveryType,
      setCurrentStep: state.setCurrentStep,
      currentStep: state.currentStep,
    }),
  );

  const { isMerchantOpen, delivery, merchant, schedules, crm_bonus, coupons } =
    useMerchantStore((state) => ({
      isMerchantOpen: state.isMerchantOpen,
      delivery: state.delivery,
      merchant: state.merchant,
      schedules: state.schedules,
      crm_bonus: state.crm_bonus,
      coupons: state.coupons,
    }));

  const { isLoading } = useMerchantCache(params.slug);

  const { data } = useGetSchedulings(merchant?.id);

  const { mutateAsync: getRelatedProductsList, data: relatedProductsList } =
    useGetRelatedProductsList();

  const { mutateAsync: getCRMBonusDetails, isLoading: fetchingCrmBonusData } =
    useCRMGetBonusDetails();

  const debouncedGetRelatedProductsList = debounce(getRelatedProductsList, 500);

  const { track } = React.useContext(TrackingContext);

  React.useEffect(() => {
    if (!merchant || isExtension) return;
    debouncedGetRelatedProductsList({ merchant_id: merchant.id });
  }, [merchant]);

  React.useEffect(() => {
    if (percentage) {
      setPercentageProgress(`${percentage}`);
    }
  }, [percentage]);

  React.useEffect(() => {
    if (productsCountIndividual <= 0 && !isExtension) {
      navigate(`/`);
      track(`OnCustomerAtCartClear`, {});
      track(`OnCustomerLeaveCart`, {});
      Toast.error('Sua sacola está vazia');
    }
  }, [productsCountIndividual]);

  const {
    frete,
    withFreteGratisDiscount,
    isFreteGratis,
    subtotal,
    subtotalWithDiscount,
    hasProductPerKilo,
    discountFormatted,
    loyaltyGiftFormatted,
    loyaltyRewardFormatted,
    subtotalFormatted,
    totalFormatted,
    isCouponLoading,
    errorRadius,
    clubDiscountAvailable,
    hasClubDiscount,
    totalClubDiscountFormatted,
    userClubDiscountFormatted,
  } = React.useContext(OrderDetailsContext);

  React.useEffect(() => {
    if (errorRadius == true && !isExtension) {
      Toast.error('Ainda não atendemos na sua região');
      Toast.success('Clique aqui para chamar a loja no whatsapp', {
        position: 'top-center',
        className: 'toastify-sucess',
        autoClose: 50000,
        closeButton: false,
        icon: true,
        onClick: () => {
          window.open(
            `https://api.whatsapp.com/send?phone=${merchant?.phone}&text=Olá, vocês conseguem entregar no bairro ${address?.neighborhood}, ${address?.city}?`,
            '_blank',
          );
        },
        hideProgressBar: true,
        closeOnClick: true,
        transition: Slide,
        theme: 'colored',
      });
    }
  }, [errorRadius]);

  let blockMinValue = false;

  if (deliveryType === DeliveryType.DELIVERY && !isExtension) {
    if (delivery) {
      if (delivery.amount_order_min) {
        blockMinValue =
          subtotal >= parseInt(delivery.amount_order_min) ? false : true;
      }
    }
  }

  const buttonLabel = 'Continuar';

  const handleClick = () => {
    setOpen(!open);
  };

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { address, phone, name, setPhone, setName } = useUserStore((state) => ({
    address: state.address,
    phone: state.phone,
    name: state.name,
    setPhone: state.setPhone,
    setName: state.setName,
  }));

  const addCoupon = () => {
    if (coupon) return;
    track(`OnCustomerLeaveCart`, {});
    track(`OnCustomerAtCartViewCoupon`, {});
    navigate(`/coupon`);
  };

  const removeCoupon = () => {
    setCoupon(undefined);
  };

  const handlePhonePrompt = () => {
    Swal.fire({
      title: name ? 'Qual seu número?' : 'Qual seu nome e número?',
      html: name
        ? '<input id="swal-input-phone" class="swal2-input" placeholder="(00) 00000-0000" type="tel">'
        : '<input id="swal-input-name" class="swal2-input" placeholder="Seu nome" type="text"><br><input id="swal-input-phone" class="swal2-input" placeholder="(00) 00000-0000" type="tel">',
      showCancelButton: false,
      confirmButtonText: 'Confirmar',
      didOpen: () => {
        const phoneInput = document.getElementById(
          'swal-input-phone',
        ) as HTMLInputElement;
        if (phoneInput) {
          phoneInput.addEventListener('input', (event) => {
            const target = event.target as HTMLInputElement;
            target.value = maskPhone(target.value);
          });
        }
      },
      preConfirm: () => {
        const phoneValue = (
          document.getElementById('swal-input-phone') as HTMLInputElement
        )?.value;
        if (!name) {
          const nameValue = (
            document.getElementById('swal-input-name') as HTMLInputElement
          )?.value;
          if (!nameValue || !phoneValue) {
            Swal.showValidationMessage(
              'Você precisa informar seu nome e celular',
            );
            return null;
          }
          return { name: nameValue, phone: phoneValue };
        } else if (!phoneValue) {
          Swal.showValidationMessage('Você precisa informar seu celular');
          return null;
        }
        return { phone: phoneValue };
      },
      buttonsStyling: false,
      customClass: {
        title: 'text-2xl font-medium',
        actions: 'gap-2',
        confirmButton:
          'bg-primary text-contrastText py-2 px-6 rounded-lg outline-none',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result?.value?.name) {
          setName(result.value.name);
        }
        if (phone !== result.value?.phone) {
          setCrmBonusData(undefined);
          setPhone(result.value?.phone);
        }
      }
    });
  };

  const handleSeeCrmBalance = () => {
    if (!phone || !name) {
      handlePhonePrompt();
    } else if (crmBonusData) {
      const discount = crmBonusData.total || 0;
      Swal.fire({
        title: crmBonusData.content?.modal?.title,
        html: crmBonusData.content?.modal?.body,
        confirmButtonText: crmBonusData.content?.modal?.button_text,
        cancelButtonText:
          crmBonusData.content?.modal?.button_unlock_all_discount,
        denyButtonText: 'Trocar número do telefone',
        showDenyButton: true,
        showCancelButton:
          crmBonusData.content?.modal?.button_unlock_all_discount != null,
        buttonsStyling: false,
        customClass: {
          actions: 'gap-2 pb-12',
          confirmButton:
            'bg-primary text-contrastText py-2 px-6 rounded-lg outline-none',
          cancelButton:
            'bg-gray-500 text-white py-2 px-6 rounded-lg outline-none',
          denyButton: 'py-2 underline absolute bottom-4',
        },
      }).then((res) => {
        if (res.isDenied) {
          if (isAuthenticated) {
            navigate('/profile/info', { state: { goBack: '/cart' } });
            Toast.success('Troque seu número de telefone aqui');
          } else {
            handlePhonePrompt();
          }
        }
        if (res.isConfirmed && crmBonusData.check_phone) {
          return navigate('/validate/phone', {
            state: { go_back: '/cart', token: crmBonusData.token },
          });
        }
        if (res.isDismissed && res.dismiss === Swal.DismissReason.cancel) {
          return navigate('/');
        }
        if (res.isConfirmed && discount !== 0) setCrmBonusDiscount(discount);
      });
    }
  };

  const handleGetCRMBonusDetails = debounce(
    async (name: string, phone: string, balance_amount: number) => {
      try {
        if (!merchant || !crm_bonus?.status || isExtension) return;
        const res = await getCRMBonusDetails({
          id: merchant.id,
          name: name,
          phone: phone,
          balance_amount: balance_amount,
        });
        setToOpenCrmDetail(!res.check_phone);
        setCrmBonusData(res);
        setCrmBonusDiscount(0);
        if (!crmBonusData && res.check_phone) {
          navigate('/validate/phone', {
            state: { go_back: '/cart', token: res.token },
          });
        }
      } catch (error) {
        const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
          ?.error;
        msgs?.forEach((msg) => Toast.error(msg));
      }
    },
    1000,
  );

  React.useEffect(() => {
    if (!crm_bonus?.status || isExtension) {
      setCrmBonusData(undefined);
      setCrmBonusDiscount(0);
      return;
    }
  }, [crm_bonus, isExtension]);

  React.useEffect(() => {
    if (isExtension) return;
    if (
      name &&
      phone &&
      (crmBonusData
        ? crmBonusData?.has_bonus || crmBonusData.check_phone
        : true)
    ) {
      handleGetCRMBonusDetails(name, phone, subtotalWithDiscount);
    }
  }, [name, phone, subtotalWithDiscount]);

  React.useEffect(() => {
    if (crmBonusData && toOpenCrmDetail) {
      handleSeeCrmBalance();
      setToOpenCrmDetail(false);
    }
  }, [crmBonusData, toOpenCrmDetail]);

  React.useEffect(() => {
    if (location?.state?.change_crm_phone) {
      handlePhonePrompt();
    }
  }, [location.state]);

  const verifyContinue = () => {
    if (
      data?.status === 'ACTIVE' &&
      !deliveryTime &&
      deliveryType === DeliveryType.DELIVERY &&
      address &&
      isMerchantOpen &&
      schedules?.open === false &&
      schedules?.receive_orders_when_closed === true
    ) {
      setCurrentStep(StepType.SCHEDULING);
      Toast.error(
        'Esta loja está fechada, para agendar o seu pedido você deve selecionar o "Tempo de entrega"',
      );

      return false;
    }

    if (deliveryType === DeliveryType.DELIVERY) {
      if (!address) {
        Toast.error('Selecione um endereço');
        setCurrentStep(StepType.ADDRESS);
        !isExtension && navigate('/cart/address');
        return false;
      }

      if (errorRadius == true) {
        Toast.error('Ainda não atendemos na sua região');
        return false;
      }

      if (blockMinValue && delivery && delivery) {
        Toast.error(
          `O valor mínimo para entrega é de ${currencyFormat(
            parseInt(delivery.amount_order_min),
          )}`,
        );
        return false;
      }
    }
    return true;
  };

  const handleContinue = () => {
    if (onClickContinue) {
      return onClickContinue();
    }
    const verified = verifyContinue();
    if (!verified) return;
    if (
      relatedProductsList &&
      relatedProductsList.length > 0 &&
      !relatedProductsOpen
    ) {
      return setRelatedProductsOpen(true);
    }
    track(`OnCustomerViewCheckout`, {});
    navigate(`/checkout`);
  };

  if (isExtension && !showSummary) {
    if (currentStep === StepType.ADDRESS) return;
    return (
      <FormCheckout
        onClickContinue={onClickContinue}
        orderId={orderId}
        extensionCustomCouponCode={extensionCustomCouponCode}
        extensionCustomDeliveryFee={extensionCustomDeliveryFee}
        setExtensionCustomCouponCode={setExtensionCustomCouponCode}
        setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
      />
    );
  }

  let finalTotal: number | string | undefined = currencyToFloat(totalFormatted);
  const floatCustomDeliveryFee = currencyToFloat(
    String(extensionCustomDeliveryFee) ?? 0,
  );
  if (floatCustomDeliveryFee > 0) {
    finalTotal = !isNaN(Number(frete))
      ? currencyToFloat(totalFormatted) - currencyToFloat(frete)
      : currencyToFloat(totalFormatted);
    finalTotal += currencyToFloat(String(extensionCustomDeliveryFee));
    finalTotal = formatCurrency(finalTotal);
  } else {
    finalTotal = totalFormatted;
  }

  const availableCoupons = coupons?.quantity || 0;

  return (
    <React.Fragment>
      <div
        className={`${
          !isExtension && 'fixed bottom-0'
        } flex flex-col items-start transition-all justify-start w-full ${
          !isExtension
            ? 'p-3 mt-2 h-auto'
            : currentStep === StepType.ADDRESS
              ? 'hidden'
              : ''
        } bg-white rounded-t-xl`}
      >
        {!isExtension && clubDiscountAvailable && (
          <Skeleton isLoading={isLoading} className="w-full rounded-md h-4">
            <div className="flex items-center justify-center w-full mb-2">
              <span className="text-sm text-green-600 font-medium">
                {hasClubDiscount ? (
                  `Você economizou ${userClubDiscountFormatted} nessa compra.`
                ) : (
                  <Link to="/club" state={{ goBack: '/cart' }}>
                    Junte-se ao clube e economize {totalClubDiscountFormatted}{' '}
                    nessa compra!
                  </Link>
                )}
              </span>
            </div>
          </Skeleton>
        )}
        {!isExtension && loyaltyRewardFormatted && (
          <Skeleton isLoading={isLoading} className="w-full rounded-md h-4">
            <div className="flex flex-col items-center justify-center w-full mb-2">
              <span className="text-sm text-green-600 font-medium">
                Prêmio por fidelidade: {loyaltyRewardFormatted}
              </span>
              {loyaltyGiftFormatted && (
                <span className="text-sm text-green-600 font-medium">
                  Prêmio para novos clientes: {loyaltyGiftFormatted}
                </span>
              )}
            </div>
          </Skeleton>
        )}
        {!isExtension && (
          <div className="flex flex-col items-center justify-center w-full mb-3 text-sm">
            {crm_bonus?.status && (
              <Skeleton
                isLoading={isLoading}
                className="w-full rounded-md h-11"
              >
                <div className="flex items-center justify-between w-full p-2 bg-white rounded-lg shadow-sm">
                  <div className="flex items-center ml">
                    <BanknotesIcon className="w-[20px] text-primary mr-2" />
                    Ganhe GIFTBACK com esta compra
                  </div>
                  <button
                    className="mr text-primary flex items-center justify-center"
                    onClick={handleSeeCrmBalance}
                  >
                    {fetchingCrmBonusData ? (
                      <LoadingIcon className="text-primary" />
                    ) : (
                      'Ver meu saldo'
                    )}
                  </button>
                </div>
              </Skeleton>
            )}
            <Skeleton isLoading={isLoading} className="w-full rounded-md h-11">
              <div className="flex items-center justify-between w-full p-2 bg-white rounded-lg shadow-sm">
                <div className="flex flex-row gap-1 items-center ml">
                  <div className="flex items-center">
                    <svg
                      version="1.1"
                      className="inline w-[20px] text-primary mr-2"
                      x="0px"
                      y="0px"
                      viewBox="0 0 32 32"
                    >
                      <path
                        style={{
                          fill: 'none',
                          stroke: 'currentColor',
                          strokeWidth: '2',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          strokeMiterlimit: '10',
                        }}
                        d="M29,12.6V10c0-0.6-0.4-1-1-1h-6.3c-0.3,0.6-1,1-1.7,1s-1.4-0.4-1.7-1H4c-0.6,0-1,0.4-1,1v2.6c1.2,0.7,2,2,2,3.4
	                s-0.8,2.8-2,3.4V22c0,0.6,0.4,1,1,1h14.3c0.3-0.6,1-1,1.7-1s1.4,0.4,1.7,1H28c0.6,0,1-0.4,1-1v-2.6c-1.2-0.7-2-2-2-3.4
	                S27.8,13.2,29,12.6z"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="20"
                        y1="12"
                        x2="20"
                        y2="15"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="20"
                        y1="20"
                        x2="20"
                        y2="17"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="10"
                        y1="19"
                        x2="14"
                        y2="13"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="9"
                        y1="12"
                        x2="9"
                        y2="15"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="15"
                        y1="17"
                        x2="15"
                        y2="20"
                      />
                      <rect
                        x="-360"
                        y="-360"
                        fill="none"
                        width="536"
                        height="680"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col ">
                    {coupon ? <span>{coupon.code}</span> : 'Cupom de desconto'}
                    {availableCoupons > 0 && (
                      <span className="text-xs text-gray-500">
                        <span className="text-primary font-bold">
                          {availableCoupons}
                        </span>{' '}
                        disponíve{availableCoupons > 1 ? 'is' : 'l'}
                      </span>
                    )}
                  </div>
                </div>
                <button className="mr text-primary" onClick={addCoupon}>
                  {coupon ? (
                    <button onClick={removeCoupon}>Remover</button>
                  ) : (
                    'Adicionar'
                  )}
                </button>
              </div>
            </Skeleton>
          </div>
        )}

        {!blockMinValue && (
          <div
            className={`flex flex-col w-full mt-1 transition-all mb-4 ${
              open
                ? `${
                    coupon || subtotal !== subtotalWithDiscount
                      ? 'h-[87px]'
                      : 'h-[67px]'
                  }`
                : 'h-5'
            }`}
          >
            <div className={`flex flex-row justify-between w-full mt-1s`}>
              <Skeleton isLoading={isLoading} className="w-1/2 h-5 rounded-lg">
                <div
                  className="font-semibold text-sm flex items-center"
                  onClick={handleClick}
                >
                  <span className="mr-1">
                    {coupon
                      ? `Total ${
                          hasProductPerKilo ? 'estimado' : ''
                        } com desconto`
                      : deliveryType === DeliveryType.DELIVERY
                        ? `Total ${
                            hasProductPerKilo ? 'estimado' : ''
                          } com a entrega`
                        : `Total${hasProductPerKilo ? ' estimado' : ''}`}
                  </span>
                </div>
              </Skeleton>
              <Skeleton isLoading={isLoading} className="w-24 h-5 rounded-lg">
                <div className="flex flex-row items-center gap-1">
                  <div className="font-semibold text-sm" onClick={handleClick}>
                    {isCouponLoading ? (
                      <LoadingSpin color="primary" />
                    ) : (
                      finalTotal
                    )}
                  </div>
                  <div className="place-content-center text-black">
                    {open ? (
                      <ChevronUpIcon
                        className="w-4 h-4"
                        stroke="black"
                        strokeWidth={2}
                      />
                    ) : (
                      <ChevronDownIcon
                        className="w-4 h-4"
                        stroke="black"
                        strokeWidth={2}
                      />
                    )}
                  </div>
                </div>
              </Skeleton>
            </div>
            {open && (
              <div
                className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
              >
                <Skeleton
                  isLoading={isLoading}
                  className="w-1/2 h-5 rounded-lg"
                >
                  <div className="font-semibold text-sm text-[#787878]">
                    Subtotal
                  </div>
                </Skeleton>
                <Skeleton isLoading={isLoading} className="w-24 h-5 rounded-lg">
                  <div className="flex flex-row">
                    <div className="font-semibold text-sm text-[#787878]">
                      {subtotalFormatted}
                    </div>
                  </div>
                </Skeleton>
              </div>
            )}
            {deliveryType === DeliveryType.DELIVERY && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm text-[#787878]">
                        Taxa de entrega
                      </div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm text-[#787878]">
                          {withFreteGratisDiscount ? (
                            <>
                              <s>
                                {extensionCustomDeliveryFee &&
                                floatCustomDeliveryFee > 0
                                  ? extensionCustomDeliveryFee
                                  : frete}
                              </s>{' '}
                              <span className="text-green-600">Grátis</span>
                            </>
                          ) : extensionCustomDeliveryFee &&
                            floatCustomDeliveryFee > 0 ? (
                            extensionCustomDeliveryFee
                          ) : (
                            frete
                          )}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
            {getMarginTax() > 0 && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px]`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm text-[#787878]">
                        Margem de variação de peso
                      </div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm text-[#787878]">
                          {currencyFormat(getMarginTax())}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
            {subtotal !== subtotalWithDiscount && (
              <>
                {open && (
                  <div
                    className={`flex flex-row justify-between w-full mt-1 pr-[22px] text-green-600`}
                  >
                    <Skeleton
                      isLoading={isLoading}
                      className="w-1/2 h-5 rounded-lg"
                    >
                      <div className="font-semibold text-sm">Desconto</div>
                    </Skeleton>
                    <Skeleton
                      isLoading={isLoading}
                      className="w-24 h-5 rounded-lg"
                    >
                      <div className="flex flex-row">
                        <div className="font-semibold text-sm">
                          {isCouponLoading ? (
                            <LoadingSpin color="primary" />
                          ) : (
                            discountFormatted
                          )}
                        </div>
                      </div>
                    </Skeleton>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* Messagem caso pedido mínimo não seja igual ou maior */}
        {blockMinValue &&
          deliveryType === DeliveryType.DELIVERY &&
          delivery && (
            <div className={`flex flex-col w-full mt-1 transition-all`}>
              <div className={`flex flex-row justify-between w-full mt-1`}>
                <div className="font-semibold text-sm text-[#d90016]">
                  O pedido mínimo dessa loja é{' '}
                  {delivery &&
                    currencyFormat(parseInt(delivery?.amount_order_min))}{' '}
                  sem contar com a taxa de entrega
                </div>
              </div>
            </div>
          )}

        {/* Progresso para frete grátis */}
        {restFreeFreigth > 0 &&
          deliveryType === DeliveryType.DELIVERY &&
          !blockMinValue &&
          !isFreteGratis && (
            <div className="flex flex-col items-center justify-center w-full mt-3 text-sm">
              <Skeleton isLoading={isLoading} className="w-9/12 h-5 rounded-lg">
                <div>
                  Falta {currencyFormat(restFreeFreigth)} para
                  <span className="ml-1 font-semibold text-green-600">
                    entrega grátis
                  </span>
                </div>
              </Skeleton>

              <Skeleton
                isLoading={isLoading}
                className="w-full h-3 mt-4 rounded-full"
              >
                <div className="flex flex-row items-start justify-start w-full h-2 mt-4 ml-2 bg-gray-200 rounded-full">
                  <div
                    className={`w-[${percentageProgress}] h-full bg-primary rounded-full transition-all duration-500`}
                    style={{ width: percentageProgress }}
                  ></div>
                </div>
              </Skeleton>
            </div>
          )}

        {/* Button confirm */}
        {!isExtension && (
          <div className="flex flex-row items-center justify-center w-full mt-3">
            <Skeleton isLoading={isLoading} className="w-full rounded-md h-11">
              <button
                className=" p-2 text-contrastText bg-primary rounded-md w-full text-sm"
                onClick={handleContinue}
              >
                {buttonLabel}
              </button>
            </Skeleton>
          </div>
        )}
      </div>

      <CartRelatedProductsModal
        open={relatedProductsOpen}
        list={relatedProductsList?.[0]}
        handleContinue={handleContinue}
        handleClose={() => setRelatedProductsOpen(false)}
      />
    </React.Fragment>
  );
};

export default CartSummary;
