import { yupResolver } from '@hookform/resolvers/yup';
import {
  LoadingIcon,
  Toast,
  formatBirthday,
  maskPhone,
  mergeSchema,
} from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CustomerForm,
  ICustomerForm,
  customerValidationSchema,
} from '../../components/Forms/CustomerForm';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useCreateLoyaltySubscription } from '../../queries/loyalty';
import { LoyaltySubscriptionPayload } from '../../queries/loyalty/types';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useUserStore } from '../../stores/user';
import { useLoyaltyPhoneValidation } from './hooks/useLoyaltyPhoneValidation';

export type LoyaltyClubRegisterForm = ICustomerForm;

const validationSchema = mergeSchema(customerValidationSchema);

export const LoyaltyClubRegister: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const merchantLoyaltyProgramme = useMerchantStore(
    (state) => state.loyalty_programme,
  );
  const user = useAuthStore((state) => state.user);
  const address = useUserStore((state) => state.address);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setAuthState = useAuthStore((state) => state.setAuthState);
  const setAddress = useUserStore((state) => state.setAddress);
  const logout = useAuthStore((state) => state.logout);

  const { mutateAsync: createLoyaltySubscription, isLoading } =
    useCreateLoyaltySubscription();

  const methods = useForm<LoyaltyClubRegisterForm>({
    resolver: yupResolver(validationSchema),
  });

  const { loyaltyObject, reset } = useLoyaltyPhoneValidation();

  const [isRegisterLink, setIsRegisterLink] = React.useState(false);

  const handleAuth = () => {
    if (isAuthenticated) {
      logout();
      methods.reset();
    } else {
      navigate(`/login`, {
        state: { goBack: location.state?.goBack || '/loyalty' },
      });
    }
  };

  const handleGoBack = () => {
    if (isRegisterLink) navigate('/');
    else navigate(-1);
  };

  const onSubmit = async (formData: LoyaltyClubRegisterForm) => {
    const payload: LoyaltySubscriptionPayload = {
      loyalty_programme_id: merchantLoyaltyProgramme!.id,
      customer_id: user?.id,
      email: formData.email || undefined,
      name: formData.name,
      birthday: formData.birthday
        ? formatBirthday(formData.birthday)
        : undefined,
      gender: formData.gender?.toUpperCase(),
      phone: formData.phone,
      taxpayer_id_number: formData.taxpayer_id_number || undefined,
      password: formData.password,
    };

    if (isRegisterLink) {
      payload.token = params.token;
    }

    try {
      const res = await createLoyaltySubscription(payload);
      Toast.success(res.message);
      if (res.data.has_complete_registration) {
        navigate('/login', { state: { goBack: '/loyalty' }, replace: true });
        return;
      }
      res.data.auth && setAuthState(true, res.data.auth);
      setAddress(address);
      navigate('/');
      reset();
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  React.useEffect(() => {
    if (params) {
      const { phone, token } = params;
      phone && methods.setValue('phone', maskPhone(phone));
      if (phone && token) {
        setIsRegisterLink(true);
        return;
      }
    }
    setIsRegisterLink(false);
  }, [params]);

  return (
    <div id="loyalty-register" className="w-full h-full p-4 space-y-2">
      <HeaderBar onClickBack={handleGoBack}>
        <span className="text-sm font-normal text-center w-full">
          {merchantLoyaltyProgramme?.name}
        </span>
      </HeaderBar>
      {!isRegisterLink && (
        <div className="w-full flex flex-row justify-between px-2 py-1">
          <span>{user?.name ? user.name : 'Ja possui uma conta?'}</span>
          <button
            type="button"
            onClick={handleAuth}
            className="text-sm no-underline hover:underline"
          >
            {isAuthenticated ? 'Mudar de conta' : 'Fazer login'}
          </button>
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomerForm
            phoneDisabled={isRegisterLink}
            noPasswordConfirmation
            hideBirthday={loyaltyObject?.data?.customer?.has_birthday}
            hideEmail={loyaltyObject?.data?.customer?.has_email}
            hideCpf={loyaltyObject?.data?.customer?.has_taxpayer_id_number}
          />

          <button
            type="submit"
            disabled={isLoading}
            className="w-full mt-4 mb-4 p-2 bg-primary text-contrastText rounded-lg flex items-center justify-center"
          >
            {isLoading ? <LoadingIcon /> : 'Quero os benefícios'}
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
