import { useNavigate } from 'react-router-dom';
import Stars from '../../../../../packages/assets/images/stars.png';
import WallPaper from '../../../../../packages/assets/images/wallpaper.png';
import StarList from '../../assets/images/favorite.png';
import Loyalty from '../../assets/images/loyalty-program.png';
import Star from '../../assets/images/star.png';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useMerchantStore } from '../../stores/merchant';
import { Helmet } from 'react-helmet';
import { useAuthStore } from '../../stores/login';
import { useLoyaltyPhoneValidation } from './hooks/useLoyaltyPhoneValidation';
import { LoadingIcon, Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import { useCreateLoyaltySubscription } from '../../queries/loyalty';
import React from 'react';

export const LoyaltyPreview: React.FC = () => {
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const merchantLoyaltyProgramme = useMerchantStore(
    (state) => state.loyalty_programme,
  );

  const { user, isAuthenticated } = useAuthStore((state) => ({
    user: state.user,
    isAuthenticated: state.isAuthenticated,
  }));

  const {
    mutateAsync: createLoyaltySubscription,
    isLoading: isSubscriptionLoading,
  } = useCreateLoyaltySubscription();

  const { isLoading, runCheckPhone, reset } = useLoyaltyPhoneValidation();

  const isUserDataValid = () => {
    if (
      !user?.id ||
      !user?.email ||
      !user?.name ||
      !user?.phone ||
      !user?.taxpayer_id_number
    ) {
      return false;
    }
    return true;
  };

  const handleLoyaltyRegister = async () => {
    try {
      const res = await createLoyaltySubscription({
        loyalty_programme_id: merchantLoyaltyProgramme!.id,
        customer_id: user?.id,
        email: user?.email,
        name: user?.name,
        phone: user?.phone,
        taxpayer_id_number: user?.taxpayer_id_number,
      });
      reset();
      res.message && Toast.success(res.message);
      navigate('/');
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const handleOpenLoyaltyRegister = async () => {
    try {
      const verifySms = merchant?.config.send_sms_loyalty_programme_catalog;

      if (!verifySms && isAuthenticated && isUserDataValid()) {
        await handleLoyaltyRegister();
        return;
      }

      if (isAuthenticated) {
        await runCheckPhone({ phone: user?.phone });
      }

      navigate('/loyalty/phone');
    } catch (error) {
      Toast.error('Ocorrou algum erro inesperado, tente novamente mais tarde');
    }
  };

  React.useEffect(() => {
    if (
      user?.loyalty_programmes &&
      user.loyalty_programmes.find(
        (loyalty) => loyalty.id === merchantLoyaltyProgramme?.id,
      )
    ) {
      navigate('/loyalty/detail', { replace: true });
    }
  }, [user, merchantLoyaltyProgramme]);

  if (!merchantLoyaltyProgramme) {
    navigate(-1);
  }

  return (
    <div className="w-full h-full">
      <Helmet>
        <meta name="title" content={`Programa de fidelide ${merchant?.name}`} />
        <meta
          name="description"
          content={`${merchantLoyaltyProgramme?.name} ${merchantLoyaltyProgramme?.messages.join(
            ', ',
          )}`}
        />
      </Helmet>
      <div className="bg-primary z-[-9] absolute w-full h-60 opacity-50" />
      <img
        src={Stars}
        alt="Imagem do clube"
        className="w-full h-60 z-[-8] object-cover absolute top-0 grayscale"
      />
      <img
        src={WallPaper}
        alt="Imagem do clube"
        className="w-full h-60 z-[-10] object-cover absolute top-0 grayscale"
      />

      <div className="w-full h-full space-y-2">
        <HeaderBar className="p-4 !mb-16" colorButton="bg-white"></HeaderBar>
        <div className="flex flex-col space-y-4 items-center">
          <div className="absolute top-5 flex flex-col space-y-2">
            <img
              src={Star}
              alt="Imagem do clube"
              className="h-14 w-14 z-[-8] self-center"
            />
            <div className="text-lg font-medium text-contrastText">
              Clube de fidelidade{' '}
              <span className="font-medium pt-1">
                {merchantLoyaltyProgramme?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 rounded-t-2xl bg-white flex flex-col items-center justify-center space-y-6 w-full">
        <span className="font-semibold text-lg">
          Quais serão seus benefícios?
        </span>
        <img src={Loyalty} className="w-20 h-20" alt="Imagem da fidelidade" />
        <ul className="flex flex-col space-y-2 text-black px-6">
          {merchantLoyaltyProgramme?.messages.map((message) => (
            <div className="flex flex-row space-x-2" key={message}>
              <img src={StarList} className="h-4 w-4 mt-1" alt="Estrela" />
              <div>{message}</div>
            </div>
          ))}
        </ul>
        <button
          type="button"
          className="flex items-center justify-center w-full rounded-lg bg-primary text-contrastText p-2 text-center"
          onClick={handleOpenLoyaltyRegister}
          disabled={isLoading || isSubscriptionLoading}
        >
          {isLoading || isSubscriptionLoading ? (
            <LoadingIcon />
          ) : (
            'Quero participar!'
          )}
        </button>
      </div>
    </div>
  );
};
