import bifefino from '../assets/images/cortes/Bife Fino - Site.png'
import bifegrosso from '../assets/images/cortes/Bife Grosso - Site.png'
import bifemedio from '../assets/images/cortes/Bife Médio - Site.png'
import Carpaccio from '../assets/images/cortes/Carpaccio.png'
import Hamburguer from '../assets/images/cortes/Hamburguer.png'
import inteira from '../assets/images/cortes/Inteira.png'
import Strogonoff2 from '../assets/images/cortes/Strogonoff2.png'
import tiras from '../assets/images/cortes/TirasOnbeef.png'
import apassarinho2 from '../assets/images/cortes/a passarinho2.png'
import bifemedio2 from '../assets/images/cortes/bife medio-2.png'
import cubos from '../assets/images/cortes/cubos.png'
import desfiado from '../assets/images/cortes/desfiado.png'
import empanado from '../assets/images/cortes/empanado.png'
import escalope from '../assets/images/cortes/escalope.png'
import espeto from '../assets/images/cortes/espeto.png'
import fatiado from '../assets/images/cortes/fatiado.png'
import feijoada from '../assets/images/cortes/feijoada.png'
import file from '../assets/images/cortes/file.png'
import grelha from '../assets/images/cortes/grelha.png'
import iscas from '../assets/images/cortes/iscas.png'
import linguica from '../assets/images/cortes/linguica.png'
import medalhao from '../assets/images/cortes/medalhao.png'
import moida1 from '../assets/images/cortes/moida1.png'
import moida2 from '../assets/images/cortes/moida2.png'
import ostrinha from '../assets/images/cortes/ostrinha.png'
import peca1 from '../assets/images/cortes/peca1.png'
import picado from '../assets/images/cortes/picado.png'
import postas from '../assets/images/cortes/postas.png'
import temperado from '../assets/images/cortes/temperado.png'
import tirinhasg from '../assets/images/cortes/tirinhas.png'
import visaCard from '../assets/images/payments/visa.png'
import eloCard from '../assets/images/payments/elo.webp'
import masterCard from '../assets/images/payments/mastercard.png'
import hiperCard from '../assets/images/payments/hiper.png'
import discoverCard from '../assets/images/payments/discover.png'
import auraCard from '../assets/images/payments/aura.png'
import jcbCard from '../assets/images/payments/jcb.png'
import amexCard from '../assets/images/payments/amex.png'
import dinersCard from '../assets/images/payments/diners.png'
import pix from '../assets/images/payments/pix.png'
import { LoyaltyProgramme } from '../../../../apps/catalogo/src/queries/loyalty/types'
import { formatCurrency } from './currency-format'

export const estados = [
  {
    id: 'AC',
    estado: 'Acre',
  },
  {
    id: 'AL',
    estado: 'Alagoas',
  },
  {
    id: 'AM',
    estado: 'Amazonas',
  },
  {
    id: 'AP',
    estado: 'Amapá',
  },
  {
    id: 'BA',
    estado: 'Bahia',
  },
  {
    id: 'CE',
    estado: 'Ceará',
  },
  {
    id: 'DF',
    estado: 'Distrito Federal',
  },
  {
    id: 'ES',
    estado: 'Espírito Santo',
  },
  {
    id: 'GO',
    estado: 'Goiás',
  },
  {
    id: 'MA',
    estado: 'Maranhão',
  },
  {
    id: 'MG',
    estado: 'Minas Gerais',
  },
  {
    id: 'MS',
    estado: 'Mato Grosso do Sul',
  },
  {
    id: 'MT',
    estado: 'Mato Grosso',
  },
  {
    id: 'PA',
    estado: 'Pará',
  },
  {
    id: 'PB',
    estado: 'Paraíba',
  },
  {
    id: 'PE',
    estado: 'Pernambuco',
  },
  {
    id: 'PI',
    estado: 'Piauí',
  },
  {
    id: 'PR',
    estado: 'Paraná',
  },
  {
    id: 'RJ',
    estado: 'Rio de Janeiro',
  },
  {
    id: 'RN',
    estado: 'Rio Grande do Norte',
  },
  {
    id: 'RO',
    estado: 'Rondônia',
  },
  {
    id: 'RR',
    estado: 'Roraima',
  },
  {
    id: 'RS',
    estado: 'Rio Grande do Sul',
  },
  {
    id: 'SC',
    estado: 'Santa Catarina',
  },
  {
    id: 'SP',
    estado: 'São Paulo',
  },
  {
    id: 'SE',
    estado: 'Sergipe',
  },
  {
    id: 'TO',
    estado: 'Tocantins',
  },
]

export type Status =
  | 'DELIVERY'
  | 'PENDING'
  | 'REJECTED'
  | 'PREPARATION'
  | 'OUT_FOR_DELIVERY'
  | 'DELIVERED'
  | 'WITHDRAWAL'

export type PagarmeStatus =
  | 'PROCESSING'
  | 'CAPTURED'
  | 'AUTHORIZED'
  | 'PAID'
  | 'CANCELED'
  | 'FAILED'
  | 'NOT_AUTHORIZED'
  | 'REFUNDED'
  | 'PENDING'
  | 'WAITING_PAYMENT'
  | 'PENDING_REFUND'
  | 'REFUSED'
  | 'CHARGEDBACK'
  | 'ANALYZING'
  | 'PENDING_REVIEW'

export const typeToHuman: Record<string, string> = {
  ALL: 'Todos',
  DRAFT: 'Rascunhos',
  DELIVERY: 'Entrega',
  PENDING: 'Pendente',
  UBER: 'Uber',
  REJECTED: 'Cancelado',
  PREPARATION: 'Em preparo',
  OUT_FOR_DELIVERY: 'Rota/Pronto',
  DELIVERED: 'Entregue',
  WITHDRAWAL: 'Retirada',
}

export const ORDER_STATUS = {
  PICKUP: 'PICKUP',
  PICKUP_COMPLETE: 'PICKUP_COMPLETE',
  DROPOFF: 'DROPOFF',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  DELIVERED: 'DELIVERED',
  PAID: 'PAID',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PREPARATION: 'PREPARATION',
  AWAITING_MERCHANT: 'AWAITING_MERCHANT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
}

export const orderStatusColor: Record<string, string> = {
  DRAFT: '#fcd74e',
  PENDING: '#3856cf',
  PREPARATION: '#fcd74e',
  OUT_FOR_DELIVERY: '#f07205',
  DELIVERED: '#2ba81b',
  CANCELED: '#55c746',
  REJECTED: '#cf3838',
}

export const orderStatusToNextStatusLabel: Record<string, string> = {
  PENDING: 'Aceitar',
  PREPARATION: 'Saiu para entrega',
  OUT_FOR_DELIVERY: 'Entregue',
  CALLUBER: 'Chamar Uber',
  PICKUP: 'Motorista a caminho',
  PICKUPCOMPLETE: 'Motorista pegou pedido',
}

export const orderStatusToNextStatus = (
  status: string,
  type_delivery: string
) => {
  if (
    status === ORDER_STATUS.PREPARATION &&
    type_delivery === DeliveryType.WITHDRAWAL
  ) {
    return 'Pronto para retirada'
  }
  return orderStatusToNextStatusLabel[status]
}

export const orderStatusToNextStatusAPI: Record<string, string> = {
  PENDING: 'PREPARATION',
  PREPARATION: 'OUT_FOR_DELIVERY',
  OUT_FOR_DELIVERY: 'DELIVERED',
}

interface TypeToHumanColor {
  [key: string]: {
    color: string
    label: string
  }
}

export const orderStatusToHumanColor: TypeToHumanColor = {
  DELIVERY: { color: '#0088ff', label: 'Entrega' },
  PENDING: { color: '#ff8400', label: 'Pendente' },
  REJECTED: { color: '#c7000a', label: 'Cancelado' },
  CANCELED: { color: '#c7000a', label: 'Cancelado' },
  FAILED: { color: '#c7000a', label: 'Falha' },
  WHATSAPP: { color: '#9c9c9c', label: 'Via whatsapp' },
  PREPARATION: { color: '#ff8400', label: 'Em preparo' },
  OUT_FOR_DELIVERY: { color: '#ff8400', label: 'Saiu para entrega' },
  DELIVERED: { color: '#3da628', label: 'Entregue' },
  WITHDRAWAL: { color: '#3da628', label: 'Retirada' },
}

export const orderStatusToHuman = (
  status: string,
  type_delivery: string
): string => {
  return status === ORDER_STATUS.OUT_FOR_DELIVERY &&
    type_delivery === DeliveryType.WITHDRAWAL
    ? 'Pronto para retirada'
    : orderStatusToHumanColor[status]!.label
}

export const pagarmeToHumanColor: Record<
  string,
  {
    color: string
    label: string
  }
> = {
  processing: { color: '#ff8400', label: 'Processando' },
  captured: { color: '#3da628', label: 'Pago' },
  authorized: { color: '#3da628', label: 'Autorizado' },
  paid: { color: '#3da628', label: 'Pago' },
  canceled: { color: '#c7000a', label: 'Cancelado' },
  failed: { color: '#c7000a', label: 'Falha no pagamento' },
  not_authorized: { color: '#c7000a', label: 'Não autorizado' },
  refunded: { color: '#c7000a', label: 'Estornado' },
  pending: { color: '#ff8400', label: 'Aguardando pagamento' },
  waiting_payment: { color: '#ff8400', label: 'Aguardando pagamento' },
  pending_refund: { color: '#ff8400', label: 'Aguardando estorno' },
  refused: { color: '#c7000a', label: 'Recusado' },
  chargedback: { color: '#c7000a', label: 'Chargeback' },
  analyzing: { color: '#ff8400', label: 'Em análise' },
  pending_review: { color: '#ff8400', label: 'Revisão' },
}

export const typeLinks = {
  INSTAGRAM: 'Instagram',
  WHATSAPP: 'Whatsapp',
  MAPS: 'Localização',
}

export const colors = {
  '#1b9051': 'theme-green',
  '#000000': 'theme-black',
  '#F61F1B': 'theme-red',
  '#a62707': 'theme-red-dark',
  '#173189': 'theme-blue',
  '#5E1593': 'theme-purple',
  '#F87622': 'theme-orange',
  '#FFE135': 'theme-yellow',
}

export const colorNames = {
  'theme-green': 'Verde',
  'theme-black': 'Preto',
  'theme-red': 'Vermelho',
  'theme-red-dark': 'Vermelho escuro',
  'theme-blue': 'Azul',
  'theme-purple': 'Roxo',
  'theme-orange': 'Laranja',
  'theme-yellow': 'Amarelo',
}

export const daysOfWeek = {
  MON: 'Segunda',
  TUE: 'Terça',
  WED: 'Quarta',
  THU: 'Quinta',
  FRI: 'Sexta',
  SAT: 'Sábado',
  SUN: 'Domingo',
}

export enum ProductType {
  KG = 'KG',
  UNIT = 'UNIT',
  KIT = 'KIT',
}

export enum ProductTag {
  MEAT = 'MEAT',
  KIT = 'KIT',
  OTHER = 'OTHER',
}

export enum KitType {
  OPEN = 'KIT_OPEN',
  CLOSED = 'KIT_CLOSED',
}

export enum DefaultCategoryType {
  BURGER_KIT = 'BURGER_KIT',
  BARBECUE_KIT = 'BARBECUE_KIT',
  LAMB = 'LAMB',
  CHEESES = 'CHEESES',
  BUILD_YOUR_KIT = 'BUILD_YOUR_KIT',
  FRUITS_VEGETABLES = 'FRUITS_VEGETABLES',
  POULTRY = 'POULTRY',
  PORK = 'PORK',
  OTHERS = 'OTHERS',
  EVERYDAY_KIT = 'EVERYDAY_KIT',
  BEEF = 'BEEF',
  FISH = 'FISH',
  CURING_MEATS = 'CURING_MEATS',
  COLD_CUTS = 'COLD_CUTS',
}

export enum PaymentMethodType {
  PIX = 'PIX',
  MONEY = 'Dinheiro',
  CHECKOUT_CREDIT_CARD = 'CHECKOUT_CREDIT_CARD',
  CHECKOUT_PIX = 'CHECKOUT_PIX',
  WHATSAPP_PIX = 'WHATSAPP_PIX',
  CHECKOUT_LINK = 'CHECKOUT_LINK',
  CREDIT = 'CREDIT',
}

export const typeToHumanPaymentMethod: Record<string, string> = {
  PIX: 'Pix',
  MONEY: 'Dinheiro',
  CHECKOUT_CREDIT_CARD: 'Cartão de Crédito',
  CHECKOUT_PIX: 'Pix',
  WHATSAPP_PIX: 'Pix via Whatsapp',
  CHECKOUT_LINK: 'Link de pagamento',
}

export enum DeliveryType {
  DELIVERY = 'DELIVERY',
  WITHDRAWAL = 'WITHDRAWAL',
  UBER = 'UBER',
}

export const deliveryTypeOptions = [
  { label: 'Entrega', value: 'DELIVERY' },
  { label: 'Retirada', value: 'WITHDRAWAL' },
  { label: 'Entrega e Retirada', value: 'WITHDRAWAL_AND_DELIVERY' },
]

export const adsOptions = {
  FACEBOOK_PIXEL: 'FB_PIXEL_TAG',
  GOOGLE_ANALYTICS: 'ANALYTICS_TAG',
  GOOGLE_TAG_MANAGER: 'GTM_TAG',
}

export enum ModalContent {
  IMAGE = 'IMAGE',
  REVIEWS = 'REVIEWS',
}

export enum DeliveryMerchantType {
  DELIVERY = 'DELIVERY',
  WITHDRAWAL_AND_DELIVERY = 'WITHDRAWAL_AND_DELIVERY',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum WherePaymentType {
  APP = 'APP',
  WITHDRAWALORDELIVERY = 'WITHDRAWALORDELIVERY',
}

export enum StepType {
  ADDRESS = 'ADDRESS',
  SCHEDULING = 'SCHEDULING',
  DELIVERY_INSTRUCTIONS = 'DELIVERY_INSTRUCTIONS',
  FINISHED = 'FINISHED',
  NONE = 'NONE',
  UBER = 'UBER',
}

export enum StatusType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

const dias: Record<string, string> = {
  MON: 'Segunda-Feira',
  TUE: 'Terça-Feira',
  WED: 'Quarta-Feira',
  THU: 'Quinta-Feira',
  FRI: 'Sexta-Feira',
  SAT: 'Sabado',
  SUN: 'Domingo',
}

export enum Extensions {
  EXTENSAO_WHATSAPP = 'extensao_whatsapp',
  IMPRESSAO_AUTOMATICA = 'impressao_automatica',
  MESSAGE_WHATSAPP = 'service_whatsapp',
}

export enum CouponStatus {
  EXPIRED = 'EXPIRADO',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NO_LIMIT = 'Sem limite',
  TURNOFF = 'Desativar cupom',
  TURNON_COUPON = 'Ativar cupom',
}

export const plansType = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

export const couponDiscountType = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED: 'FIXED',
  DELIVERY_FREE: 'DELIVERY_FREE',
}

export enum CategoryStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
}

export enum CredentialStatus {
  ACTIVE = 'ACTIVED',
  INACTIVE = 'INACTIVED',
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
}

export const cards: Record<string, string> = {
  Mastercard: masterCard,
  Visa: visaCard,
  Elo: eloCard,
  Amex: amexCard,
  Diners: dinersCard,
  Hipercard: hiperCard,
  Discover: discoverCard,
  JCB: jcbCard,
  Aura: auraCard,
  Pix: pix,
}

export const statusOrders: Record<string, string> = {
  ALL: 'Todos',
  PAID: 'Pago',
  WAITING_PAYMENT: 'Aguardando pagamento',
  FAILED: 'Negado',
  NOT_AUTHORIZED: 'Não autorizado',
  AUTHORIZED_PENDING_CAPTURE: 'Autorizado, aguardando captura',
  CANCELED: 'Cancelados',
  REJECTED: 'Rejeitados',
}

export const cortesImages: Record<string, string> = {
  Carpaccio: Carpaccio,
  Hamburguer: Hamburguer,
  Strogonoff: Strogonoff2,
  'À passarinho': apassarinho2,
  Cubos: cubos,
  Desfiado: desfiado,
  Empanado: empanado,
  Escalope: escalope,
  Espeto: espeto,
  Espetinho: espeto,
  Feijoada: feijoada,
  Filé: file,
  Grelha: grelha,
  Isca: iscas,
  Linguiça: linguica,
  Medalhão: medalhao,
  Moído: moida2,
  'Moído 1x': moida2,
  'Moído 2x': moida2,
  Ostrinha: ostrinha,
  Peça: peca1,
  Picado: picado,
  Postas: postas,
  Temperado: temperado,
  Tirinha: tirinhasg,
  Tiras: tiras,
  Bife: bifemedio2,
  'Bife Fino': bifefino,
  'Manta Fina': bifefino,
  'Manta fina': bifefino,
  'Bife Grosso': bifegrosso,
  'Manta Grossa': bifegrosso,
  'Bife fino': bifefino,
  'Bife grosso': bifegrosso,
  'Manta grossa': bifegrosso,
  'Bife médio': bifemedio,
  'Manta média': bifemedio,
  Inteiro: inteira,
  Fatiado: fatiado,
  Moida: moida1,
  Moída: moida1,
}

export const paymentMethods = [
  {
    label: 'Todas',
    img: undefined,
    value: undefined,
  },
  {
    label: 'Cartão de crédito (Online)',
    img: undefined,
    value: 'CHECKOUT_CREDIT_CARD',
  },
  {
    label: 'PIX (Online)',
    img: undefined,
    value: 'CHECKOUT_PIX',
  },
  {
    label: 'PIX',
    img: undefined,
    value: 'PIX',
  },
  {
    label: 'Pix via whatsapp',
    img: undefined,
    value: 'PIX via Whatsapp',
  },
  {
    label: 'Cartão de crédito',
    img: undefined,
    value: 'Cartão de crédito',
  },
  {
    label: 'Cartão de débito',
    img: undefined,
    value: 'Cartão de débito',
  },
  {
    label: 'Dinheiro',
    img: undefined,
    value: 'Dinheiro',
  },
  {
    label: 'Vale alimentação',
    img: undefined,
    value: 'Vale alimentação',
  },
  {
    label: 'Vale refeição',
    img: undefined,
    value: 'Vale refeição',
  },
  {
    label: 'Boleto',
    img: undefined,
    value: 'Boleto',
  },
  {
    label: 'PicPay',
    img: undefined,
    value: 'PicPay',
  },
]

export function weekDayToHuman(day?: string) {
  if (!day) return ''
  return dias[day]
}

export enum ClubSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export enum LoyaltyRewardStatus {
  TO_RESCUE = 'TO_RESCUE',
  PENDING = 'PENDING',
  RESCUED = 'RESCUED',
}

export enum LoyaltyDiscountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  DELIVERY_FREE = 'DELIVERY_FREE',
}

export function loyaltyDiscountTypeToHuman(
  discount: LoyaltyProgramme['reward']['discount']
) {
  if (!discount) return ''
  return {
    AMOUNT: `Desconto de ${formatCurrency(
      discount.value || 0
    )} no próximo pedido`,
    PERCENTAGE: `Desconto de ${discount.value}% no próximo pedido`,
    DELIVERY_FREE: 'Frete grátis no próximo pedido',
  }[discount?.type]
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NO = 'NO',
}

export const genderOptions = [
  { label: 'Masculino', value: Gender.MALE },
  { label: 'Feminino', value: Gender.FEMALE },
  { label: 'Prefiro não dizer', value: Gender.NO },
]

export const ageOptions = [
  { label: 'Menor que 25', value: 'LESS_THAN_25' },
  { label: 'Entre 25 e 40', value: '25_TO_40' },
  { label: 'Entre 41 e 60', value: '41_TO_60' },
  { label: 'Acima de 61', value: 'ABOVE_61' },
]

export const LoyaltyProgramType = {
  CUSTOMER: 'Brinde para novos clientes',
  'ORDER|AMOUNT': 'Desconto em dinheiro',
  'ORDER|PERCENTAGE': 'Desconto em porcentagem',
  'ORDER|PRODUCT': 'Desconto em produto',
}

export const formatBirthday = (birthday?: string) => {
  if (!birthday || !birthday.includes('/')) return birthday
  const [day, mounth, year] = birthday.split('/')
  return `${year}-${mounth}-${day}`
}

export const parseBirthday = (birthday?: string) => {
  if (!birthday || !birthday.includes('-')) return birthday
  const [year, mounth, day] = birthday.split('-')
  return `${day}/${mounth}/${year}`
}

export enum DeliveryDetails {
  FREE = 1,
  DEAL = 2,
  FLAT = 3,
  DISTANCE = 4,
  DISTRICT = 5,
  UBER = 6,
  KM = 7,
}

export enum CutTypes {
  PACKAGE = 'PACKAGE',
  WITH_MIN_WEIGHT = 'WITH_MIN_WEIGHT',
  WITHOUT_MIN_WEIGHT = 'WITHOUT_MIN_WEIGHT',
}

export const cutTypeOptions = [
  {
    label: 'Pacote',
    value: CutTypes.PACKAGE,
    placeholder: 'Peso médio',
    helper:
      'O cliente só poderá adicionar multiplos de um valor fixo que você selecionar (ex.: pacotes de 300g, 600g, 900g etc.).',
  },
  {
    label: 'Peso livre',
    value: CutTypes.WITHOUT_MIN_WEIGHT,
    helper:
      'O cliente poderá escolher qualquer quantidade a partir de 100g e assim ir adicionando de 100 em 100g na sacola. (Ex: 100, 200, 300….)',
  },
  {
    label: 'Peso mínimo',
    value: CutTypes.WITH_MIN_WEIGHT,
    placeholder: 'Peso mínimo',
    helper:
      'O cliente ainda conseguirá adicionar os produtos de 100 em 100g na sacola, porém a partir de um valor mínimo que você pré-selecionar  (ex.: a partir de 300g, portanto: 300, 400, 500…).',
  },
]

export interface ViaCepResponse {
  cep: string
  logradouro: string
  complemento: string
  unidade: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

export enum FiltersType {
  CUTS = 'CUTS',
  TAGS = 'TAGS',
}

export interface FilterOption {
  id: string
  name: string
  image: string
  type: FiltersKeys
}

export interface Filter {
  description: string
  type: FiltersType
  content: FilterOption[]
}

export interface FilterPreference {
  id: string
  type: FiltersType
  order: number
  filter: {
    id: string
    name: string
    image: string
    type: FiltersKeys
  }
}

export interface Filters {
  CUTS: Filter
  PRODUCT_EVENT_TYPE: Filter
  PRODUCT_PREPARATION_MODE: Filter
  PRODUCT_STORAGE: Filter
}

export enum FiltersKeys {
  CUTS = 'CUTS',
  PRODUCT_EVENT_TYPE = 'PRODUCT_EVENT_TYPE',
  PRODUCT_PREPARATION_MODE = 'PRODUCT_PREPARATION_MODE',
  PRODUCT_STORAGE = 'PRODUCT_STORAGE',
}

export interface SelectedFilter {
  id: string
  name: string
  type: FiltersType
  key: keyof Filters
}

export const NPSColors: { [key: string]: string } = {
  '1': '#991b1b',
  '2': '#dc2626',
  '3': '#ea580c',
  '4': '#fb923c',
  '5': '#eab308',
  '6': '#fbbf24',
  '7': '#a3e635',
  '8': '#84cc16',
  '9': '#65a30d',
  '10': '#3f6212',
}

export enum RelatedListType {
  CART = 'CART',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
}
