import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Avatar } from '@material-tailwind/react';
import { GroupRadioButton, RadioButton } from '@onbeef/components/input';
import { Skeleton } from '@onbeef/components/skeleton';
import { Tab } from '@onbeef/components/tab';
import { ClubSubscriptionStatus, formatCurrency } from '@onbeefapp/constants';
import cx from 'classnames';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateClubSubscription } from '../../../queries/club';
import { Club } from '../../../queries/home/types';
import { useAuthStore } from '../../../stores/login';
import { useMerchantStore } from '../../../stores/merchant';
import { CancelClubSubscription } from './CancelClubSubscription';
import { CreateClubSubscription } from './CreateClubSubscription';
import { PendingClubSubscription } from './PendingClubSubscription';
import Tooltip from '@onbeef/components/tooltip';
import { ClubPaymentIntervalToHuman } from '../../../pages/Club';

enum TabTypes {
  SUBED = 1,
  UNSUBED = 2,
}

export const FormClub: React.FC = () => {
  const clubs = useMerchantStore((state) => state.clubs);
  const userClubs = useAuthStore((state) => state.user?.clubs);

  const [unsubedClubs, setUnsubedClubs] = React.useState<Club[]>([]);
  const [subedClubs, setSubedClubs] = React.useState<Club[]>([]);
  const methods = useForm<{ club_id: string }>();
  const { errors } = methods.formState;
  const { merchant } = useMerchantStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  const [imageBase64, setImageBase64] = React.useState('');

  React.useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!merchant) return;
        const response = await fetch(merchant.logo);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          setImageBase64(base64String);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Erro ao buscar a imagem:', error);
      }
    };

    fetchImage();
  }, [merchant]);

  const setSelectedClub = (club_id: string) => {
    methods.setValue('club_id', club_id);
  };

  React.useEffect(() => {
    const unsubed: Club[] = [];
    const subed: Club[] = [];
    clubs?.forEach((club) => {
      if (userClubs?.find((uc) => uc.club_id === club.id)) {
        subed.push(club);
      } else {
        unsubed.push(club);
      }
    });
    setUnsubedClubs(unsubed);
    setSubedClubs(subed);
  }, [clubs, userClubs]);

  React.useEffect(() => {
    if (location.state?.clubName) {
      const id = clubs?.find((c) => c.name === location.state.clubName)?.id;
      if (id) {
        methods.setValue('club_id', id);
      }
    }
  }, [userClubs, location.state?.clubName]);

  if (!clubs) {
    navigate(-1);
  }

  return (
    <div className="flex flex-col w-full space-y-6">
      <div className="w-full px-2">
        <span className="font-medium text-lg text-gray-800">
          Gerencie seus clubes de benefícios
        </span>
      </div>

      <FormProvider {...methods}>
        {subedClubs.length > 0 && (
          <div className="w-full flex flex-col gap-4 mb-6">
            <h2 className="font-medium text-lg text-gray-700">Meus clubes</h2>
            <GroupRadioButton
              alignment="vertical"
              className="w-full flex flex-col gap-3"
              errors={errors.club_id}
            >
              {subedClubs.map((club) => (
                <ClubCard
                  key={club.id}
                  club={club}
                  setSelectedClub={setSelectedClub}
                  logo={imageBase64}
                />
              ))}
            </GroupRadioButton>
          </div>
        )}

        {unsubedClubs.length > 0 && (
          <div className="w-full flex flex-col gap-4">
            <h2 className="font-medium text-lg text-gray-700">Disponíveis</h2>
            <GroupRadioButton
              alignment="vertical"
              className="w-full flex flex-col gap-3"
              errors={errors.club_id}
            >
              {unsubedClubs.map((club) => (
                <ClubCard
                  key={club.id}
                  club={club}
                  setSelectedClub={setSelectedClub}
                  logo={imageBase64}
                />
              ))}
            </GroupRadioButton>
          </div>
        )}
      </FormProvider>
    </div>
  );
};

const ClubCard: React.FC<{
  club: Club;
  setSelectedClub: (club_id: string) => void;
  logo?: string;
}> = ({ club, setSelectedClub, logo }) => {
  const { isLoading } = useCreateClubSubscription();
  const userClubs = useAuthStore((state) => state.user?.clubs);

  const subscription = userClubs?.find((uc) => uc.club_id === club.id);
  const pendingPayment =
    subscription && subscription.status === ClubSubscriptionStatus.PENDING;
  return (
    <div key={club.id} className="w-full">
      <div
        className={cx(
          `w-full bg-white rounded-lg border ${
            subscription ? 'border-green-300' : 'border-gray-200'
          } shadow-sm hover:shadow-md transition-all duration-200 flex flex-row justify-between ${
            isLoading ? 'animate-pulse' : ''
          }`,
        )}
        onClick={() => {
          setSelectedClub(club.id);
        }}
      >
        <Skeleton isLoading={isLoading} className="w-full rounded-lg">
          <div className="flex flex-row p-3 w-full space-x-3">
            <Avatar
              placeholder={'store logo'}
              src={logo}
              className="w-20 h-20 rounded-lg object-cover"
            />

            <div className="flex flex-col w-full justify-between py-1">
              <div className="flex flex-col space-y-2">
                <div className="flex flex-row items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <RadioButton
                      name="club_id"
                      id={club.slug}
                      value={club.id}
                      className="hidden ml-0"
                      label={club.name}
                      labelClass="font-semibold ml-0 w-full text-gray-800 text-lg"
                    />
                    {subscription &&
                      subscription.status === ClubSubscriptionStatus.ACTIVE && (
                        <span className="bg-green-100 text-green-700 text-xs font-medium px-2 py-0.5 rounded">
                          Ativo
                        </span>
                      )}
                  </div>
                  {club.messages && (
                    <Tooltip
                      text={
                        <div className="flex flex-col gap-1">
                          {club.messages?.map((msg, idx) => (
                            <span key={idx}>{msg}</span>
                          ))}
                        </div>
                      }
                    >
                      <InformationCircleIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
                    </Tooltip>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  {club.price_cents === 0 ? (
                    <span className="bg-green-50 text-green-700 px-2 py-1 rounded text-sm font-medium">
                      Gratuito
                    </span>
                  ) : (
                    <div className="flex items-baseline gap-1">
                      <span className="font-semibold text-gray-800">
                        {formatCurrency(club.price_cents / 100)}
                      </span>
                      <span className="text-gray-500 text-sm">
                        /{ClubPaymentIntervalToHuman[club.interval]}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-3 flex flex-col space-y-1">
                {club ? (
                  subscription ? (
                    pendingPayment ? (
                      <PendingClubSubscription subscription={subscription} />
                    ) : (
                      <CancelClubSubscription subscription={subscription} />
                    )
                  ) : (
                    <CreateClubSubscription
                      clubID={club.id}
                      isFree={club.price_cents === 0}
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};
