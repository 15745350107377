import ReactDOM from 'react-dom/client';
import Swal from 'sweetalert2';
import DeleteCutsList from '../components/Modals/DeleteCutsList';
import { ProductCartType } from '../stores/cart';

export const useDeleteCutsModal = () => {
  const openCutsModal = async (product: ProductCartType): Promise<void> => {
    await Swal.fire({
      html: '<div id="cuts-modal-content"></div>',
      showCancelButton: false,
      showConfirmButton: false,
      width: '36rem',
      padding: '1.25rem',
      customClass: {
        popup: 'rounded-lg shadow-md p-0',
        htmlContainer: 'mx-0 mt-0 !p-0 !m-0',
      },
      showCloseButton: false,
      didOpen: () => {
        const container = document.getElementById('cuts-modal-content');
        if (container) {
          const root = ReactDOM.createRoot(container);
          root.render(
            <DeleteCutsList product={product} onClose={() => Swal.close()} />,
          );
        }
      },
    });
  };

  return { openCutsModal };
};
