import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { Address } from './../../../../../packages/constants/src/utils/AddressUtils';
import {
  CreateOrderResponse,
  DraftPayload,
  FreightResponse,
  OrderDetailsResponse,
  OrderPayload,
  SchedulingsResponse,
  StatusPixResponse,
} from './types';

async function getStatusPix(ctx: QueryFunctionContext) {
  const [, id, code] = ctx.queryKey;
  if (!id || !code) return null;
  const response = await api.get<StatusPixResponse>(
    `/all/customers/orders/${id}/status/${code}`,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
    },
  );
  return response;
}

async function createOrder(payload: OrderPayload | DraftPayload) {
  const { data } = await api.post<CreateOrderResponse>(
    '/customers/orders',
    payload,
  );

  return data;
}

async function getFreight(ctx: QueryFunctionContext) {
  const [, id, amount, address] = ctx.queryKey;
  if (!id || !amount || !address) return null;
  const { data } = await api.post<FreightResponse>(
    '/customers/orders/freight/' + id,
    {
      amount: amount,
      address: address,
    },
  );
  return data;
}

async function getSchedulings(ctx: QueryFunctionContext) {
  const [, merchant_id] = ctx.queryKey;
  if (!merchant_id) return;
  const { data } = await api.get<SchedulingsResponse>(
    `/all/merchants/${merchant_id}/orders/schedulings`,
  );
  return data;
}

export function useCreateOrder() {
  return useMutation(['createOrder'], createOrder);
}

export function useGetFreight(
  amount: number,
  id = '',
  address: Address | undefined,
) {
  return useQuery(['getFreight', id, amount, address], getFreight, {
    refetchOnWindowFocus: false,
  });
}

async function getOrderDetails(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) return null;
  const { data } = await api.get<OrderDetailsResponse>(
    '/customers/orders/' + id,
  );
  return data;
}

export function useGetOrderDetails(id?: string) {
  return useQuery(['getOrderDetails', id], getOrderDetails, {
    refetchOnWindowFocus: true,
    staleTime: 2000000,
  });
}

export function useGetStatusPix(id: string, code?: string) {
  return useQuery(['getStatusPix', id, code], getStatusPix);
}

export function useGetSchedulings(merchant_id?: string) {
  return useQuery(['getSchedulings', merchant_id], getSchedulings, {
    refetchOnWindowFocus: false,
  });
}
