import { Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  useLoyaltyCheckPhone,
  useLoyaltyVerifyPhone,
} from '../../../queries/loyalty';
import { useMerchantStore } from '../../../stores/merchant';
import { useLoyaltyPhoneStore } from '../../../stores/loyalty';
import { PhoneCheck429Error } from '../../../../../fidelidade/src/types/types';

interface LoyaltyPhoneParams {
  phone?: string;
  code?: string;
}

export const useLoyaltyPhoneValidation = () => {
  const navigate = useNavigate();
  const merchant = useMerchantStore((state) => state.merchant);

  const {
    phone,
    verifyingToken,
    submitDisabled,
    submitReleasedAt,
    loyaltyObject,
    setPhone,
    setVerifyingToken,
    setSubmitDisabled,
    setSubmitReleasedAt,
    setLoyaltyObject,
    reset,
  } = useLoyaltyPhoneStore();

  const { mutateAsync: checkPhone, isLoading: checkPhoneLoading } =
    useLoyaltyCheckPhone();
  const { mutateAsync: verifyPhone, isLoading: verifyPhoneLoading } =
    useLoyaltyVerifyPhone();

  const clearVerifying = () => {
    setVerifyingToken('');
    setSubmitDisabled(false);
    setSubmitReleasedAt(undefined);
  };

  const runVerifyPhone = async (data: LoyaltyPhoneParams) => {
    if (!merchant || !phone || !data.code) return;
    try {
      const res = await verifyPhone({
        slug: merchant.slug,
        phone,
        code: data.code,
        token: verifyingToken,
      });
      const token = res?.data?.data?.customer?.token;

      clearVerifying();
      navigate(`/loyalty/register/${phone}/${token}`);
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const runCheckPhone = async (data: LoyaltyPhoneParams) => {
    if (!merchant || !data.phone) return;
    try {
      const formattedPhone = data.phone.replace(/\D/g, '');

      const res = await checkPhone({
        slug: merchant.slug,
        phone: formattedPhone,
      });

      const result = res.data.data;

      if (res.status === 201) {
        setPhone(formattedPhone);
        setVerifyingToken(result.token || '');
        return;
      }

      const complete = result.customer.has_complete_registration;
      if (complete) {
        Toast.success(
          'Seu cadastro já está completo, faça o login e aproveite seus benefícios!',
        );
        navigate('/login');
        return;
      }

      setLoyaltyObject(res.data);
      navigate(`/loyalty/register/${formattedPhone}/${result.customer.token}`);
    } catch (error) {
      const err = error as AxiosError<PhoneCheck429Error>;
      const msgs = err?.response?.data?.error;
      msgs?.forEach((msg) => Toast.error(msg));
      if (err?.response?.status === 429 && err?.response?.data?.released_at) {
        setSubmitReleasedAt(new Date(err.response?.data?.released_at));
        setSubmitDisabled(true);
      }
    }
  };

  const isLoading = checkPhoneLoading || verifyPhoneLoading;

  return {
    isLoading,
    phone,
    runCheckPhone,
    runVerifyPhone,
    verifyingToken,
    submitDisabled,
    setSubmitDisabled,
    submitReleasedAt,
    clearVerifying,
    reset,
    loyaltyObject,
  };
};
