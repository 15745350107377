import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const customerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  phone: Yup.string().required('Celular é obrigatório'),
  email: Yup.string().when(['hide_email'], {
    is: (hide_email: boolean) => !hide_email,
    then: (schema) =>
      schema.email('Email inválido').required('Email é obrigatório'),
  }),
  taxpayer_id_number: Yup.string().when(['hide_cpf'], {
    is: (hide_cpf: boolean) => !hide_cpf,
    then: (schema) =>
      schema
        .required('CPF é obrigatório')
        .min(14, 'Informe um CPF válido')
        .test('taxpayer_id_number', 'CPF/CNPJ inválido', (value) =>
          validateCPForCNPJ(value),
        ),
  }),
  birthday: Yup.string().when(['hide_birthday'], {
    is: (hide_birthday: boolean) => !hide_birthday,
    then: (schema) => schema.required('Data de nascimento obrigatória'),
  }),
  gender: Yup.string().required('Sexo é obrigatório'),
  password: Yup.string().when(['with_password'], {
    is: true,
    then: (schema) => schema.required('Senha é obrigatória'),
  }),
  password_confirmation: Yup.string().when(
    ['with_password', 'no_password_confirmation'],
    {
      is: (with_pass: boolean, no_pass_confirmation: boolean) =>
        with_pass && !no_pass_confirmation,
      then: (schema) => schema.required('Confirmação de senha é obrigatória'),
    },
  ),
});

function validateCPForCNPJ(value?: string | null | undefined): boolean {
  if (!value) return true;
  return cpf.isValid(value) || cnpj.isValid(value);
}
