import {
  BlockIcon,
  FiltersType,
  LoadingIcon,
  Product,
} from '@onbeefapp/constants';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import HeinekenBanner from '../../assets/heineken_banner.png';
import HeinekenBannerXL from '../../assets/heineken_banner_xl.webp';
import CartButton from '../../components/CartButton';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import HeaderBar from '../../components/Headers/HeaderBar';
import ListProduct from '../../components/Lists/ListProduct';
import RightSide from '../../components/RightSide/RightSide';
import SearchBar from '../../components/Search/SearchBar';
import { useGetCategoryProducts } from '../../queries/categories-products';
import { useCartStore } from '../../stores/cart';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Sidebar from '../Sidebar/Sidebar';

const Category: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const [products, setProducts] = React.useState<Product[]>([]);
  const {
    categories,
    highlights,
    setCategoryProducts,
    filters,
    categoriesAndProducts,
    search,
  } = useProductsStore((state) => ({
    categories: state.categories,
    highlights: state.highlights,
    setCategoryProducts: state.setCategoryProducts,
    filters: state.filters,
    categoriesAndProducts: state.categoriesAndProducts,
    search: state.search,
  }));

  const [categoryId, setCategoryId] = React.useState<string>();

  const categoryName =
    params.name === 'destaques'
      ? 'Destaques'
      : categories.find((category) => category.slug === params.name)?.name;

  const { productsCountIndividual } = useCartStore((state) => ({
    productsCountIndividual: state.productsCountIndividual,
  }));
  const showCart = productsCountIndividual > 0;

  const { mutateAsync: getProductsData, isLoading } = useGetCategoryProducts();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      return navigate(-1);
    }
    return navigate('/');
  };

  React.useEffect(() => {
    const run = async () => {
      if (categoryId) {
        const productsData = await getProductsData({
          merchant_id: merchant?.id,
          category_id: categoryId,
          params: {
            search: search,
            cuts: filters
              .filter((f) => f.type === FiltersType.CUTS)
              .map((f) => f.id),
            tags: filters
              .filter((f) => f.type === FiltersType.TAGS)
              .map((f) => f.id),
          },
        });
        const prepProducts = setCategoryProducts(
          categoryId,
          productsData || [],
        );
        setProducts(prepProducts);
      }
    };
    run();
  }, [categoryId, search, filters]);

  React.useEffect(() => {
    if (categories?.length > 0) {
      const category = categories.find(
        (category) => category.slug === params.name,
      );
      setCategoryId(category?.id || '');
    }
  }, [params.name, categories]);

  const isHeineken = params.name === 'se-tem-churras-tem-heineken';

  return (
    <div className="w-full">
      <Helmet>
        <meta
          name="title"
          content={`${merchant?.name} categoria ${categoryName}`}
        />
        <meta
          name="description"
          content={`Produtos da categoria ${categoryName}`}
        />
        <meta
          name="keywords"
          content={[categoryName, 'produtos']
            .filter((v) => v != undefined)
            .join(', ')}
        />
      </Helmet>
      {/* Sidebar for desktop view */}
      <Sidebar nameSelected={params.name} />
      <RightSide>
        <div className="pb-28">
          <div className="p-2 fixed top-0 z-40 pt-3 pr-[17px] bg-[#f5f5f5] pb-px transition-all lg:w-[calc(100%-380px)] duration-300 ease-in-out w-full">
            {/* HeaderBar */}
            <HeaderBar onClickBack={handleGoBack}>
              <div className="w-full text-sm font-normal text-center">
                {categoryName}
              </div>
            </HeaderBar>

            {/* SearchInput */}
            {params.name !== 'destaques' && (
              <SearchBar cart={true} nameSelected={params.name} />
            )}
          </div>

          {/* Produtos da categoria */}
          <div
            className={`${
              params.name !== 'destaques' ? 'mt-[180px]' : 'mt-[50px]'
            }`}
          >
            {isHeineken && (
              <div className="w-full items-center justify-center flex flex-col gap-2 mb-2">
                <img
                  className="w-full 2xl:hidden"
                  src={HeinekenBanner}
                  alt="Heineken"
                />
                <img
                  className="hidden 2xl:block"
                  src={HeinekenBannerXL}
                  alt="Heineken"
                />
              </div>
            )}

            <div className="w-full flex flex-col gap-2 p-2">
              {params.name === 'destaques' ? (
                <ListProduct products={highlights} />
              ) : (
                categoryId && (
                  <ListProduct
                    products={
                      search ? categoriesAndProducts[categoryId]! : products
                    }
                  />
                )
              )}

              {params.name !== 'destaques' && !search && categoryId === '' && (
                <div className="flex flex-col pt-[50px]">
                  <center>
                    <div className="prose prose-sm text-[#bfbfbf] mt-6">
                      Categoria não encontrada
                    </div>
                  </center>
                </div>
              )}

              {search && Object.values(categoriesAndProducts).length === 0 && (
                <div className="flex flex-col pt-[50px]">
                  <center>
                    <BlockIcon className="min-w-16 min-h-16 text-[#bfbfbf]" />

                    <div className="prose prose-sm text-[#bfbfbf] mt-6">
                      Nenhum produto encontrado
                    </div>
                  </center>
                </div>
              )}

              {isLoading && (
                <div className="flex flex-col pt-[50px]">
                  <center>
                    <LoadingIcon className="min-w-16 min-h-16 text-[#bfbfbf]" />

                    <div className="prose prose-sm text-[#bfbfbf] mt-6">
                      Carregando...
                    </div>
                  </center>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Drawer com as especifições da gramatura do produto */}
        <DrawerGrams />

        {/* Cart */}
        {showCart && <CartButton />}
      </RightSide>
    </div>
  );
};

export default Category;
