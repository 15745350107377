import { LoyaltyRewardStatus } from '@onbeefapp/constants';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useMerchantCache } from '../../queries/home';
import { useSlug } from '../../hooks/useSlug';
import { Skeleton } from '@onbeef/components/skeleton';
import Star from '../../assets/images/star.png';
import StarList from '../../assets/images/favorite.png';
import Confetti from '../../assets/confetti.gif';
import Stars from '../../../../../packages/assets/images/stars.png';
import WallPaper from '../../../../../packages/assets/images/wallpaper.png';
import { useRefreshLoyaltyData } from '../../queries/loyalty';

export const LoyaltyDetail: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const merchantLoyaltyProgramme = useMerchantStore(
    (state) => state.loyalty_programme,
  );
  const user = useAuthStore((state) => state.user);
  const { mutateAsync: refreshLoyaltyData } = useRefreshLoyaltyData();
  const navigate = useNavigate();
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);

  const userLoyalty = user?.loyalty_programmes && user.loyalty_programmes[0];
  const userProgress = userLoyalty?.reward?.redemption?.percentage ?? 0;

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    if (data) {
      updateLoyalty(data);
    }
  };

  useEffect(() => {
    handleRefreshLoyalty();
  }, []);

  const giftAvailable =
    userLoyalty?.gift &&
    userLoyalty.gift?.redemption?.status !== LoyaltyRewardStatus.RESCUED;

  const alreadySubed = userLoyalty != null;

  const giftSubtitle = userLoyalty?.gift?.message || '';

  React.useEffect(() => {
    if (!merchantLoyaltyProgramme || !alreadySubed) {
      navigate('/loyalty', { replace: true });
    }
  }, [merchantLoyaltyProgramme, alreadySubed]);

  return (
    alreadySubed && (
      <div className="w-full h-full">
        <div className="bg-primary z-[-9] absolute w-full h-60 opacity-50" />

        <img
          src={Stars}
          alt="Imagem do clube"
          className="w-full h-60 z-[-8] object-cover absolute top-0 grayscale"
        />
        <img
          src={WallPaper}
          alt="Imagem do clube"
          className="w-full h-60 z-[-10] object-cover absolute top-0 grayscale"
        />

        <div className="w-full h-full space-y-2">
          <HeaderBar className="p-4 !mb-16" colorButton="bg-white"></HeaderBar>
          <div className="flex flex-col space-y-4 items-center">
            <div className="absolute top-5 flex flex-col space-y-2">
              <img
                src={Star}
                alt="Imagem do clube"
                className="h-14 w-14 z-[-8] self-center"
              />
              <div className="text-lg font-medium text-contrastText">
                Clube de fidelidade
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col p-5 space-y-6 bg-white rounded-t-3xl">
          {(userLoyalty.gift?.redemption || userLoyalty.reward?.redemption) &&
            userProgress <= 100 &&
            userProgress > 0 && (
              <div className="flex flex-col gap-6">
                <div className="flex items-center gap-3 border-b border-gray-100 pb-4">
                  <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                    <span className="text-xl">👋</span>
                  </div>
                  <h2 className="text-xl font-semibold">Olá, {user?.name}!</h2>
                </div>

                <div className="space-y-4">
                  {userLoyalty.gift?.redemption?.percentage <= 100 &&
                    userLoyalty.gift?.redemption?.percentage > 0 &&
                    (!userLoyalty.gift?.redemption ||
                      userLoyalty.gift?.redemption?.status !=
                        LoyaltyRewardStatus.RESCUED) && (
                      <div className="bg-gray-50 p-4 rounded-xl">
                        <div className="flex items-start gap-3 mb-4">
                          <img
                            src={StarList}
                            className="h-5 w-5 mt-1"
                            alt="Estrela"
                          />
                          <p className="font-medium text-gray-800">
                            {userLoyalty.gift?.redemption?.message ?? ''}
                          </p>
                        </div>
                        <Link
                          className="block w-full text-center bg-primary hover:bg-primary/90 transition-colors text-white p-3 rounded-xl font-semibold"
                          to="/"
                        >
                          {userLoyalty.gift?.redemption?.status ===
                          LoyaltyRewardStatus.TO_RESCUE
                            ? 'Resgatar meu prêmio'
                            : 'Fazer pedido'}
                        </Link>
                      </div>
                    )}

                  {userLoyalty.reward?.redemption?.percentage <= 100 &&
                    userLoyalty.reward?.redemption?.percentage > 0 &&
                    (!userLoyalty.reward?.redemption ||
                      userLoyalty.reward?.redemption?.status !=
                        LoyaltyRewardStatus.RESCUED) && (
                      <div className="bg-gray-50 p-4 rounded-xl">
                        <div className="flex items-start gap-3 mb-4">
                          <img
                            src={StarList}
                            className="h-5 w-5 mt-1"
                            alt="Estrela"
                          />
                          <p className="font-medium text-gray-800">
                            {userLoyalty.reward?.redemption?.message ?? ''}
                          </p>
                        </div>
                        <Link
                          className="block w-full text-center bg-primary hover:bg-primary/90 transition-colors text-white p-3 rounded-xl font-semibold"
                          to="/"
                        >
                          {userLoyalty.reward?.redemption?.status ===
                          LoyaltyRewardStatus.TO_RESCUE
                            ? 'Resgatar meu prêmio'
                            : 'Fazer pedido'}
                        </Link>
                      </div>
                    )}
                </div>
              </div>
            )}

          {(userLoyalty?.reward?.redemption?.percentage === 0 ||
            userLoyalty?.gift?.redemption?.percentage === 0) &&
            (userLoyalty.gift?.redemption || userLoyalty.reward?.redemption) &&
            (userLoyalty.gift?.redemption?.status !=
              LoyaltyRewardStatus.RESCUED ||
              userLoyalty.reward?.redemption?.status !=
                LoyaltyRewardStatus.RESCUED) && (
              <Skeleton
                isLoading={isLoading}
                repeatQuantity={3}
                className="w-full h-10 rounded-xl"
              >
                <div className="space-y-6">
                  <div className="flex items-center gap-3 border-b border-gray-100 pb-4">
                    <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                      <span className="text-xl">🎉</span>
                    </div>
                    <h2 className="text-xl font-semibold">
                      Bem-vindo(a), {user?.name}!
                    </h2>
                  </div>

                  <div className="space-y-4">
                    <p className="text-gray-600">
                      Agora você faz parte do nosso clube de fidelidade! Confira
                      seus benefícios exclusivos:
                    </p>
                    <div className="bg-gray-50 p-4 rounded-xl space-y-3 divide-y divide-gray-200">
                      {merchantLoyaltyProgramme?.messages.map((message, i) => (
                        <div
                          className="flex items-start gap-3 pt-3 first:pt-0"
                          key={`message-${i}`}
                        >
                          <img
                            src={StarList}
                            className="h-5 w-5 mt-1"
                            alt="Estrela"
                          />
                          <p className="font-medium text-gray-800">{message}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4 pt-2">
                    <p className="text-gray-600 text-center">
                      Faça seu primeiro pedido e comece a ganhar pontos!
                    </p>
                    <Link
                      className="block w-full text-center bg-primary hover:bg-primary/90 transition-colors text-white p-3 rounded-xl font-semibold"
                      to="/"
                    >
                      Fazer um pedido
                    </Link>
                  </div>
                </div>
              </Skeleton>
            )}

          {userProgress === 100 && (
            <div className="flex flex-col items-center gap-6 py-4">
              {userLoyalty.gift?.redemption?.status ===
              LoyaltyRewardStatus.RESCUED ? (
                <div className="bg-gray-50 p-6 rounded-xl text-center w-full shadow-sm">
                  <p className="font-medium text-gray-800">
                    {userLoyalty.gift?.redemption?.message ?? ''}
                  </p>
                </div>
              ) : (
                <>
                  <img src={Confetti} className="w-32 h-32" alt="Confetti" />
                  {giftAvailable && (
                    <div className="bg-gray-50 p-6 rounded-xl text-center w-full shadow-sm">
                      <p className="font-medium text-gray-800">
                        {giftSubtitle}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};
