import React from 'react';
import { CloseIcon, DeleteIcon, Toast } from '@onbeefapp/constants';
import { ProductCartType, useCartStore } from '../../stores/cart';
import { getSelectedCutDetail } from '../../utils/CutsUtils';
import { formatPackageQuantityComments } from '@onbeefapp/constants';

interface DeleteCutsListProps {
  product: ProductCartType;
  onClose: () => void;
}

const DeleteCutsList: React.FC<DeleteCutsListProps> = ({
  product,
  onClose,
}) => {
  const { removeCut, deleteProduct } = useCartStore((state) => ({
    removeCut: state.removeCut,
    deleteProduct: state.deleteProduct,
  }));

  const handleRemoveCut = (cutID: string) => {
    const productDeleted = removeCut(product.id, cutID);
    if (productDeleted) {
      onClose();
      Toast.success('Produto removido do carrinho!');
    } else {
      Toast.success('Corte removido do produto!');
    }
  };

  const handleRemoveProduct = () => {
    deleteProduct(product.id);
    Toast.success('Produto removido do carrinho!');
    onClose();
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="text-center flex flex-col gap-1 relative">
        <h3 className="text-xl font-bold text-gray-800">Remover cortes</h3>
        <p className="text-sm text-gray-600 max-w-md mx-auto">
          Selecione o corte que deseja remover ou remova o produto inteiro
        </p>
        <button
          type="button"
          onClick={onClose}
          className="absolute top-0 right-0"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="flex flex-col gap-2.5 max-h-64 overflow-y-auto custom-scrollbar">
        {product.selectedCuts.map((cut) => {
          const cutDetail = getSelectedCutDetail(product.id, cut.id);
          if (!cutDetail) return null;
          return (
            <div
              key={cut.id}
              className="flex justify-between items-center p-3 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-sm transition-all duration-200 bg-white"
            >
              <div className="flex flex-col items-start">
                <span className="font-semibold text-gray-800">
                  {cutDetail?.name}
                </span>
                <span className="text-sm text-gray-600">
                  {cutDetail?.quantity}g
                </span>
                {(cutDetail?.comments || cutDetail?.package_quantity) && (
                  <span className="text-xs text-gray-500 italic">
                    {formatPackageQuantityComments(
                      cutDetail?.comments,
                      cutDetail?.package_quantity,
                    )}
                  </span>
                )}
              </div>
              <button
                type="button"
                onClick={() => handleRemoveCut(cut.id)}
                className="h-8 w-8 flex items-center justify-center rounded-full bg-red-50 text-red-600 hover:bg-red-100 hover:text-red-700 hover:scale-105 active:scale-95 transition-all duration-150"
                aria-label={`Remover ${cutDetail?.name}`}
              >
                <DeleteIcon />
              </button>
            </div>
          );
        })}
      </div>

      <div className="mt-2 pt-3 border-t border-gray-200">
        <div className="flex flex-col md:flex-row gap-3">
          <button
            type="button"
            onClick={handleRemoveProduct}
            className="w-full py-3 bg-white border border-red-600 text-red-600 rounded-lg font-medium hover:bg-red-50 active:scale-98 transition-all duration-200 flex items-center justify-center gap-2"
          >
            <DeleteIcon className="w-4 h-4" />
            <span>Remover todos cortes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCutsList;
