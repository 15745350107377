import { Skeleton } from '@onbeef/components/skeleton';
import { BlockIcon } from '@onbeefapp/constants';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Content from '../../components/Content';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import HeaderBar from '../../components/Headers/HeaderBar';
import RightSide from '../../components/RightSide/RightSide';
import SearchBar from '../../components/Search/SearchBar';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useProductsStore } from '../../stores/products';
import Sidebar from '../Sidebar/Sidebar';

export const FilterPage: React.FC = () => {
  const params = useSlug();
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading } = useMerchantCache(params.slug);

  const { categoriesAndProducts } = useProductsStore((state) => ({
    categoriesAndProducts: state.categoriesAndProducts,
  }));

  React.useEffect(() => {
    if (location.state?.fromHome) {
      window.scrollTo(0, 0);
    }
    if (location.state?.autoFocus) {
      const searchInput = document.querySelector('input');
      if (searchInput) {
        searchInput.focus();
      }
      navigate('/search', { replace: true });
    }
  }, [location]);

  return (
    <div className="w-full">
      <Sidebar />
      <RightSide>
        <div className="pb-28">
          <div className="p-2 fixed top-0 z-40 pt-3 bg-[#f5f5f5] pb-px transition-all lg:w-[calc(100%-380px)] duration-300 ease-in-out w-full">
            <HeaderBar backToMainPage>
              <div className="w-full text-sm font-normal text-center">
                Busca
              </div>
            </HeaderBar>

            <SearchBar cart />
          </div>

          <div className="mt-[180px] lg:mt-[120px]">
            <Skeleton isLoading={isLoading}>
              <Content />

              {Object.values(categoriesAndProducts).length === 0 && (
                <div className="flex flex-col pt-[50px]">
                  <center>
                    <BlockIcon className="min-w-16 min-h-16 text-[#bfbfbf]" />

                    <div className="prose prose-sm text-[#bfbfbf] mt-6">
                      Nenhum produto encontrado
                    </div>
                  </center>
                </div>
              )}
            </Skeleton>

            <DrawerGrams />
          </div>
        </div>
      </RightSide>
    </div>
  );
};
